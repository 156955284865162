import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import classNames from 'classnames';
import {
  Drawer,
  Grid,
  Hidden,
} from '@material-ui/core';

import MenuBar from './MenuBar/MenuBar.jsx';
import mainLogo from '../../assets/fmi_logo.png'
import SidebarAvatar from '../Avatars/SidebarAvatar.jsx';
import { connect } from 'react-redux';

class SideBar extends Component{

  render(){
    const {classes, history, routes, user, open, onChange, onClose} = this.props;

    let level_type = ''
    if(user){
      switch (user.user_type_id) {
        case 1:
          level_type = 'Administrador'
          break;
        case 2:
          level_type = 'Roche'
          break;
        case 3:
          level_type = 'Registrador'
          break;
        case 4:
          level_type = 'Visualizador'
          break;
        default:
          break;
      }
    }

    let mainContent = null

    mainContent = (
      <div className={classes.root}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container justify='center'spacing={0}>
              <Grid item>
                <div className={classes.mainLogoContainer}>
                  <img src={mainLogo} alt='' className={classes.mainlogo}/>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item xs={12}>
                <SidebarAvatar 
                  image={user ? user.image : null}
                  name={user ? user.first_name : ''}
                  user_type={level_type}

                />
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12} style={{paddingBottom:0}}>
            <MenuBar 
              routes={routes} 
              onClick={onChange}
            />
            
          </Grid>
          
        </Grid>
      </div>
    )

    let drawerDesktop = (
      <Hidden smDown>
        <Drawer
          variant="persistent"
          open={true}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >
          {mainContent}
          
        </Drawer>
      </Hidden>
    )

    let drawerMobile = (
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >
          {mainContent}
          
        </Drawer>
      </Hidden>
    )
    return(
      <div>
        {drawerDesktop}
        {drawerMobile}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return{
    user: state.user
  }
}


export default connect(mapStateToProps)(withStyles(styles)(SideBar))
