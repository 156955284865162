import moment from 'moment-timezone'


const timezones = moment.tz.names().map(item => ({value:item, label:item}))

export const generalCatalogs = {
  timezones:[...timezones],
  user_types:[{"id":1,"value":1,"label":"Administrador"},{"id":2,"value":2,"label":"Roche"},{"id":3,"value":3,"label":"Registrador"},{"id":4,"value":4,"label":"Visualizador"},],    
  user_genders:[{"id":1,"value":1,"label":"Hombre"},{"id":2,"value":2,"label":"Mujer"},{"id":3,"value":3,"label":"Otro"},],
  credit_ranges:[{"id":1,"value":1,"label":"Menos de $15,000"},{"id":2,"value":2,"label":"$15,000 a $50,000"},{"id":3,"value":3,"label":"$50,000 y más"},],
  log_events:[{"id":1, 'value':1, "label": "Inicio de sesión"}, {"id":2, 'value':2, "label": "Creación de usuario"}, {"id":3, 'value':3, "label": "Edición de usuario"}, {"id":4, 'value':4, "label": "Borrado de usuario"}, {"id":5, 'value':5, "label": "Carga de imagen de usuario"},
              {"id":6,"value":6, "label":'Borrado de imagen de usuario'},{"id":7, "value":7, "label":"Creación de beneficiario"}, {"id":8, "value":8, "label":"Edición de beneficiario"}, {"id":9, "value":9, "label":"Borrado de beneficiario"}, {"id":10, "value":10, "label":"Carga de documento de beneficiario"}, 
              {"id":11, "value":11, "label":"Borrado de documento de beneficiario"}, {"id":12, "value":12, "label":"Cálculo del nivel de beneficio de un beneficiario"},{"id":13, "value":13, "label":"Envío de beneficiario para aprobación"},{"id":14, "value":14, "label":"Modificación del estatus de un beneficiario"},
              {"id":15, "value":15, "label":"Creación de contacto de aprobación"},{"id":16, "value":16, "label":"Edición de contacto de aprobación"}, {"id":17, "value":17, "label":"Borrado de contacto de aprobación"}, {"id":18, "value":18, "label":"Creación de tarjeta de crédito"}, 
              {"id":19, "value":19, "label":"Edición de tarjeta de crédito"}, {"id":20, "value":20, "label":"Borrado de tarjeta de crédito"}, {"id":21, "value":21, "label":"Creación de carro"}, {"id":22, "value":22, "label":"Edición de carro"},{"id":23, "value":23, "label":"Borrado de carro"}],
  car_ranges:[{"id":1,"value":1,"label":"$150,000 a $300,000"},{"id":2,"value":2,"label":"$300,000 a $450,000"},{"id":3,"value":3,"label":"Más de $450,000"},],
  patient_states:[{"id":1,"value":1,"label":"Aguascalientes"},{"id":2,"value":2,"label":"Baja California Norte"},{"id":3,"value":3,"label":"Baja California Sur"},{"id":4,"value":4,"label":"Campeche"},{"id":5,"value":5,"label":"Chiapas"},{"id":6,"value":6,"label":"Chihuahua"},{"id":7,"value":7,"label":"Ciudad de México"},{"id":8,"value":8,"label":"Coahuila de Zaragoza"},{"id":9,"value":9,"label":"Colima"},{"id":10,"value":10,"label":"Durango"},{"id":11,"value":11,"label":"Estado de México"},{"id":12,"value":12,"label":"Guanajuato"},{"id":13,"value":13,"label":"Guerrero"},{"id":14,"value":14,"label":"Hidalgo"},{"id":15,"value":15,"label":"Jalisco"},{"id":16,"value":16,"label":"Michoacán de Ocampo"},{"id":17,"value":17,"label":"Morelos"},{"id":18,"value":18,"label":"Nayarit"},{"id":19,"value":19,"label":"Nuevo León"},{"id":20,"value":20,"label":"Oaxaca"},{"id":21,"value":21,"label":"Puebla"},{"id":22,"value":22,"label":"Querétaro"},{"id":23,"value":23,"label":"Quintana Roo"},{"id":24,"value":24,"label":"San Luis Potosí"},{"id":25,"value":25,"label":"Sinaloa"},{"id":26,"value":26,"label":"Sonora"},{"id":27,"value":27,"label":"Tabasco"},{"id":28,"value":28,"label":"Tamaulipas"},{"id":29,"value":29,"label":"Tlaxcala"},{"id":30,"value":30,"label":"Veracruz de Ignacio de la Llave"},{"id":31,"value":31,"label":"Yucatán"},{"id":32,"value":32,"label":"Zacatecas"},],
  car_types:[{"id":1,"value":1,"label":"Propio"},{"id":2,"value":2,"label":"Crédito"},],
  patient_statuses:[{"id":1,"value":1,"label":"En proceso"},{"id":2,"value":2,"label":"Pre-Autorizado"},{"id":3,"value":3,"label":"Autorizado"},{"id":4,"value":4,"label":"Rechazado"},{"id":5,"value":5,"label":"Adquirido"},{"id":6,"value":6,"label":"Reportado"},],
  test_types:[{"id":1,"value":1,"label":"FoundationOne CDx®"},{"id":2,"value":2,"label":"FoundationOne Heme®"},{"id":3,"value":3,"label":"FoundationOne Liquid CDx®"},{"id":4,"value":4,"label":"Otro"},],  
  benefit_levels: [{"id": 1, 'value':1, "label": "1", "discount": 10}, {"id": 2, 'value':2, "label": "2", "discount": 20}, {"id": 3, 'value':3, "label": "3", "discount": 30}, {"id": 4, 'value':4, "label": "4", "discount": 40}, {"id": 5, 'value':5, "label": "5", "discount": 50}],
  discounts: [{"id": 1, "value": "10", "label": "10"}, {"id": 2, "value": "20", "label": '20'}, {"id": 3, 'value':"30", "label": "30"}, {"id": 4, 'value':"40", "label": "40"}, {"id": 5, 'value':"50", "label": "50"}],
  boolean_states:[{"id":1,"value":1,"label":"Sí"},{"id":0,"value":0,"label":"No"}],
  user_statuses:[{"id":1,"value":1,"label":"Activo"},{"id":0,"value":0,"label":"Inactivo"}],
  operators:[{"id":1,"value":1,"label":"Zogen"},{"id":2,"value":2,"label":"Centro Oncológico Internacional"},{"id":3,"value":3,"label":"Opción Oncología"},],
}
  