import { Avatar, makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    avatar:{
        background:theme.palette.primary.main,
        color:'white',
        fontFamily:theme.typography.fontFamily,
        width:32,
        height:32,
        fontSize:14
    },
    avatar_xl:{
        width:120,
        height:120,
        fontSize:48
    }
}))

const NumberAvatar = props => {

    const classes = useStyles()
    const {size} = props

    return(
        <Avatar className={clsx({
            [classes.avatar]:true,
            [classes.avatar_xl]:size === 'xl'
        })}  >
            {props.children}
        </Avatar>
    )
}

export default NumberAvatar