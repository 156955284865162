import { Avatar, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../Texts/DisplayText'
import nouser from '../../assets/nouser.png'
import { isValidDocument } from '../../shared/utility'
import { imgServerS3 } from '../../variables/config'



const useStyles = makeStyles(theme => ({
    avatar:{

    }
}))

const UserAvatarName = props => {

    const classes = useStyles()
    const {image, name} = props
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${imgServerS3}${image}` : nouser

    return(
        <div>
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                <Grid item>
                    <Avatar src={selectedImage} alt='' className={classes.avatar}/>
                </Grid>
                <Grid item>
                    <DisplayText>{name}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserAvatarName