export const formData = {
  password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
      label:'Contraseña nueva',
      placeholder:'Ingrese una contraseña'
    },
    rules:{type:'distance'}
  },
  passwordConfirmation:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'passwordConfirmation',
      type:'password',
      fullWidth: true,
      label:'Confirmación',
      placeholder:'Repita la contraseña',
      helper:'La contraseña no coincide'
    },
    rules:{type:'equals'}
  },
}