import React from 'react'
import SimpleDialog from '../../../../components/Modals/SimpleDialog'
import { Grid, CircularProgress } from '@material-ui/core'
import Subtitle from '../../../../components/Texts/Subtitle'

const LoadingModal = () => {

    return(
        <SimpleDialog 
            data={{}} 
            hideSubmitBtn 
            hideCancelBtn
            maxWidth='xs'>
            <div style={{height:200}}>
                <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
                    <Grid item><CircularProgress size={120}/></Grid>
                    <Grid item><Subtitle text='Generando...' medium variant='bold'/></Grid>
                </Grid>
            </div>

        </SimpleDialog>
    )
}

export default LoadingModal