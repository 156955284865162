import React, {Component} from 'react';
import styles from './styles.jsx';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Grid,
  Icon,
  IconButton,
  Hidden
} from '@material-ui/core';
import UserButton from '../../components/Buttons/UserButton/UserButton.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class Topbar extends Component{

  state = {
    //open: true,
    user_anchorEl: null,
    user_open: false,
  }

  handleClick = () => {
    this.setState({
      user_open: false
    });
  };
 

  handleOpenUserPopper = event => {
    const { currentTarget } = event;
    this.setState({
      user_anchorEl: currentTarget,
      user_open: true
    });
  }
  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }

  onMenuSelected = (url) => {
    this.props.history.push(url);
  }
  
  
  
  render(){
    const {classes, isdraweropen, open, user}=this.props;

    let mainContent = (
      <Toolbar>
        <Grid container direction='row' justify='flex-start' spacing={4}>  
          <Grid item>
            <IconButton onClick={isdraweropen}>
              <Icon className={classes.menu}>menu</Icon>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction='row' justify="flex-end">
          <Grid item>
          <UserButton 
                user={user} 
                onLogout={this.handleLogout} 
                onMenuSelected={this.onMenuSelected}
              />
          </Grid>
        </Grid>
      </Toolbar>
    )

    let appbarDesktop = (
      <Hidden smDown>
        <AppBar
          position="fixed"
          className={cx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          {mainContent}
        </AppBar>   
      </Hidden>
    )

    let appbarMobile = (
      <Hidden mdUp>
        <AppBar
          position="fixed"
          className={classes.appBar}
        >
          {mainContent}
        </AppBar>   
      </Hidden>
    )

    return(
      
      <div className={classes.root}> 
        {appbarDesktop}
        {appbarMobile}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return{
    user: state.user
  }
}


export default withRouter(connect(mapStateToProps)(withStyles(styles)(Topbar)));
