import React from 'react'
import { Checkbox, Grid, makeStyles } from '@material-ui/core'
import DisplayText from '../Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    root:{}
}))

const RegisterCheckbox = props => {

    const classes = useStyles()
    const {id, label, status, onChange} = props

    const onInnerChange = (event) => {
        console.log(event.target.checked)
    }

    return(
        <div className={classes.root}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={6}>
                    <DisplayText>{label}</DisplayText>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justify='center'>
                        <Grid item>
                            <Checkbox id={id} checked={status} onChange={onChange}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default RegisterCheckbox