import { amber, blue, cyan, deepPurple, green, grey, indigo, lightBlue, orange, pink, purple, red, teal } from '@material-ui/core/colors'

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           LIVING PLACES
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanLivingPlacesStats = (data) => {
    let table = []
    let chartTable = []
    const colors = [indigo[700], blue[700],green[700],pink[700]]

    

    if(data){
      table = [
        [colors[0],'Rentada',data.rented,(data.rented/data.total)*100],
        [colors[1],'Prestada',data.borrowed,(data.borrowed/data.total)*100],
        [colors[2],'Propia',data.owned,(data.owned/data.total)*100],
        [colors[3],'Hipotecada',data.mortgaged,(data.mortgaged/data.total)*100],
        [null,'Totales',data.total,null],
      ]
  
      chartTable = [
        { name: 'Rentada', value: parseInt(data.rented) },
        { name: 'Prestada', value: parseInt(data.borrowed)},
        { name: 'Propia', value: parseInt(data.owned) },
        { name: 'Hipotecada', value: parseInt(data.mortgaged) },
      ]
    }
    return {table:[...table], chartTable: [...chartTable], colors:[...colors]}
}

export const cleanLivingPlacesStatsExcel = (data) => {
  let table = [['Nombre','Cantidad','Porcentaje']]
  if(data){
    table = [
      ['Nombre','Cantidad','Porcentaje'],
      ['Rentada',data.rented,(data.rented/data.total)*100],
      ['Prestada',data.borrowed,(data.borrowed/data.total)*100],
      ['Propia',data.owned,(data.owned/data.total)*100],
      ['Hipotecada',data.mortgaged,(data.mortgaged/data.total)*100],
      ['Totales',data.total,1.0],
    ]
  }
  return table
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           INCOMES
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanIncomeStats = (data) => {
    let table = []
    let chartTable = []
    const colors = [red[700], purple[700],indigo[700],blue[700],
    lightBlue[700], cyan[700],teal[700],green[700]]

    if(data){
        const _15 = parseInt(data['less_than_15'])
        const _25 = parseInt(data['15_to_25'])
        const _35 = parseInt(data['25_to_35'])
        const _45 = parseInt(data['35_to_45'])
        const _55 = parseInt(data['45_to_55'])
        const _65 = parseInt(data['55_to_65'])
        const _75 = parseInt(data['65_to_75'])
        const _85 = parseInt(data['more_than_75'])

        const total = _15 + _25 + _35 + _45 + _55 +_65 +_75 +_85
      table = [
        [colors[0],'Menos de $15,000',_15,(_15/total)*100],
        [colors[1],'$15,000 - $25,000',_25,(_25/total)*100],
        [colors[2],'$25,001 - $35,000',_35,(_35/total)*100],
        [colors[3],'$35,001 - $45,000',_45,(_45/total)*100],
        [colors[4],'$45,001 - $55,000',_55,(_55/total)*100],
        [colors[5],'$55,001 - $65,000',_65,(_65/total)*100],
        [colors[6],'$65,001 - $75,000',_75,(_75/total)*100],
        [colors[7],'$75,001 y más',_85,(_85/total)*100],
        [null,'Ingreso promedio',`$ ${data.average}`,null],
      ]
  
      chartTable = [
        { name: 'Menos de $15,000', value: _15},
        { name: '$15,000-$25,000', value:_25},
        { name: '$25,001-$35,000', value:_35},
        { name: '$35,001-$45,000', value: _45},
        { name: '$45,001-$55,000', value:_55},
        { name: '$55,001-$65,000', value:_65},
        { name: '$65,001-$75,000', value:_75},
        { name: '$75,001 y más', value:_85},
      ]
    }
    return {table:[...table], chartTable: [...chartTable], colors:[...colors]}
}

export const cleanIncomeStatsExcel = (data) => {
  let table = [['Nombre','Cantidad','Porcentaje']]
  if(data){
    const _15 = parseInt(data['less_than_15'])
    const _25 = parseInt(data['15_to_25'])
    const _35 = parseInt(data['25_to_35'])
    const _45 = parseInt(data['35_to_45'])
    const _55 = parseInt(data['45_to_55'])
    const _65 = parseInt(data['55_to_65'])
    const _75 = parseInt(data['65_to_75'])
    const _85 = parseInt(data['more_than_75'])

    const total = _15 + _25 + _35 + _45 + _55 +_65 +_75 +_85
    table = [
      ['Nombre','Cantidad','Porcentaje'],
      ['Menos de $15,000',_15,(_15/total)*100],
      ['$15,000 - $25,000',_25,(_25/total)*100],
      ['$25,001 - $35,000',_35,(_35/total)*100],
      ['$35,001 - $45,000',_45,(_45/total)*100],
      ['$45,001 - $55,000',_55,(_55/total)*100],
      ['$55,001 - $65,000',_65,(_65/total)*100],
      ['$65,001 - $75,000',_75,(_75/total)*100],
      ['$75,001 y más',_85,(_85/total)*100],
      ['Ingreso promedio',data.average,1.0],
    ]
  }
  return table
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           CHILDREN
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanChildrenStats = (data) => {
    let table = []
    const colors = [red[700], purple[700],indigo[700],blue[700],
    lightBlue[700], cyan[700],teal[700],green[700]]

    

    if(data){

    const {private_elementary_n, private_elementary_q,
        public_elementary_n, public_elementary_q,
        private_secondary_n, private_secondary_q,
        public_secondary_n, public_secondary_q,
        private_high_n, private_high_q,
        public_high_n, public_high_q,
        private_university_n, private_university_q,
        public_university_n, public_university_q} = data

      table = [
        [null, '', 'n', '#'],
        [colors[0],'Primaria pública',public_elementary_n,public_elementary_q],
        [colors[1],'Primaria privada',private_elementary_n,private_elementary_q],
        [colors[2],'Secundaria pública',public_secondary_n,public_secondary_q],
        [colors[3],'Secundaria privada',private_secondary_n,private_secondary_q],
        [colors[4],'Preparatoria pública',public_high_n,public_high_q],
        [colors[5],'Preparatoria privada',private_high_n,private_high_q],
        [colors[6],'Superior pública',public_university_n,public_university_q],
        [colors[7],'Superior privada',private_university_n,private_university_q],
      ]
  
    }
    return {table:[...table]}
}

export const cleanChildrenStatsExcel = (data) => {
  let table = [['Categoría','n','#']]
  if(data){
    const {private_elementary_n, private_elementary_q,
      public_elementary_n, public_elementary_q,
      private_secondary_n, private_secondary_q,
      public_secondary_n, public_secondary_q,
      private_high_n, private_high_q,
      public_high_n, public_high_q,
      private_university_n, private_university_q,
      public_university_n, public_university_q} = data

    table = [
      ['Categoría','n','#'],
      ['Primaria pública',public_elementary_n,public_elementary_q],
      ['Primaria privada',private_elementary_n,private_elementary_q],
      ['Secundaria pública',public_secondary_n,public_secondary_q],
      ['Secundaria privada',private_secondary_n,private_secondary_q],
      ['Preparatoria pública',public_high_n,public_high_q],
      ['Preparatoria privada',private_high_n,private_high_q],
      ['Superior pública',public_university_n,public_university_q],
      ['Superior privada',private_university_n,private_university_q],
    ]
  }
  return table
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           DEPENDANTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanDependantsStats = (data) => {
    let table = []
    const colors = [red[700], purple[700],indigo[700],blue[700],
    lightBlue[700], cyan[700],teal[700],green[700]]

    

    if(data){

    const {wives_n, wives_q,
        children_n, children_q,
        inlaws_n, inlaws_q,
        parents_n, parents_q,
        others_n, others_q}= data
        
      table = [
        [null, '', 'n', '#'],
        [colors[0],'Cónyuge o pareja',wives_n,wives_q],
        [colors[1],'Hijos',children_n,children_q],
        [colors[2],'Suegros',inlaws_n,inlaws_q],
        [colors[3],'Padres',parents_n,parents_q],
        [colors[4],'Otros',others_n,others_q],
      ]
  
    }
    return {table:[...table]}
}

export const cleanDependantsStatsExcel = (data) => {
  let table = [['Categoría','n','#']]
  if(data){
    const {wives_n, wives_q,
      children_n, children_q,
      inlaws_n, inlaws_q,
      parents_n, parents_q,
      others_n, others_q}= data
      
    table = [...table,
      ['Cónyuge o pareja',wives_n,wives_q],
      ['Hijos',children_n,children_q],
      ['Suegros',inlaws_n,inlaws_q],
      ['Padres',parents_n,parents_q],
      ['Otros',others_n,others_q],
    ]

  }
  return table
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           CREDIT CARDS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanCreditCardsStats = (data) => {
    let table = []
    if(data){
        
        table = [
            [null, '', 'n', '#'],
            [null, 'Menos de $15,000',data['less_than_15_n'],data['less_than_15_q']],
            [null, '$15,000 a $50,000',data['15_to_50_n'],data['15_to_50_q']],
            [null, '$50,000 y más',data['50_and_more_n'],data['50_and_more_q']],
        ]
  
    }
    return {table:[...table]}
}

export const cleanCreditCardsStatsExcel = (data) => {
  let table = [['Categoría','n','#']]
  if(data){
    table = [...table,
      ['Categoría', 'n', '#'],
      ['Menos de $15,000',data['less_than_15_n'],data['less_than_15_q']],
      ['$15,000 a $50,000',data['15_to_50_n'],data['15_to_50_q']],
      ['$50,000 y más',data['50_and_more_n'],data['50_and_more_q']],
  ]

  }
  return table
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           CARS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanCarsStats = (data) => {
  let table = []
  let chartTable = []
  let bars = []
  if(data){

      chartTable = [
          {name:'Propio', "$150,000-$300,000":data['owned_150_to_300'], "$300,001-$4500,000":data['owned_300_to_450'], "Mas de $450,000.00":data['owned_more_than_450'], "Sin auto":0},
          {name:'Crédito/Leasing', "$150,000-$300,000":data['credit_150_to_300'], "$300,001-$4500,000":data['credit_300_to_450'], "Mas de $450,000.00":data['credit_more_than_450'], "Sin auto":data['']},
          {name:'Sin Auto', "$150,000-$300,000":0, "$300,001-$4500,000":0, "Mas de $450,000.00":0, "Sin auto":data['no_car']},
      ]


      const colors = [lightBlue[700], teal[700],green[700],red[700],
      indigo[700],amber[700],deepPurple[700], orange[700]]
    
      bars = Object.keys(chartTable[0]).map((item,key)=>{
        if(item !== 'name'){
          return {datakey: item, fill:colors[key-1]}
        }
        return
      })
      bars = bars.splice(1)

    }

    
    
  
  return {table:[...table], chart: {table:[...chartTable], bars:[...bars]}}
}

export const cleanCarsStatsExcel = (data) => {
  let table = [['Rango','Propio','Crédito/Leasing','Sin Auto']]
  
  if(data){

    table = [...table,
      ["$150,000-$300,000", data['owned_150_to_300'], data['credit_150_to_300'], 0],
      ["$300,001-$4500,000", data['owned_300_to_450'], data['credit_300_to_450'], 0],
      ["Mas de $450,000.00", data['owned_more_than_450'], data['credit_more_than_450'], 0],
      ["Sin auto", 0, 0, data['no_car']],
    ]
    
  }
  return table
}
  