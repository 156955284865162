import React, {Component} from 'react'
import { Grid} from '@material-ui/core'

import BasicView from '../../../components/Layouts/BasicView/BasicView'

import RegisterCard from '../../../components/Layouts/RegisterCard/RegisterCard';
import FormStepA from './FormStepA/FormStepA';
import FormStepB from './FormStepB/FormStepB';
import { formData } from './data';
import FormStepC from './FormStepC/FormStepC';
import FormStepD from './FormStepD/FormStepD';
import SimpleLoader from '../../../components/Loaders/SimpleLoader';
import { ErrorMessage } from '../../../components/DisplayData/DisplayData';
import { RoundedButton } from '../../../components/Buttons/GeneralButtons';

import { getUserType } from '../../../shared/utility'
import { USER_TYPE } from '../../../variables/config'
import LoadingModal from './modals/LoadingModal'
import ErrorProcessModal from './modals/ErrorProcessModal'
import { request_prebenefit, request_add_credit_card, request_add_vehicle, request_create_patient } from './requests'
import SuccessModal from './modals/SuccessModal'
import GeneralErrorModal from './modals/GeneralErrorModal';

const views = [
  {id:1, name:'Datos Principales'},
  {id:2, name:'Ingresos'},
  {id:3, name:'Propiedades'},
  {id:4, name:'Propuesta'},
]

const layout = {
  title:'Agregar beneficiario',
  navigation:[
    {name:'Beneficiarios',path:'/patients'},
    {name:'Agregar',path:'/patients/add'}
  ],
}


class AddApplicant extends Component{

  state = {
    progress:0,
    selected:1,
    formData:JSON.parse(JSON.stringify(formData)),
    error:null,

    isCreating:false,
    isSuccessful:false,
    first_time:false,
    has_insurance:false,
    has_vehicles:false,
    
    benefit:null,
    
    credit_cards:[],
    vehicles:[],
    error_process_message:[],
    generalError:[],
    properties:[
      {id:'rented_property', label:'Rentada', status:true},
      {id:'borrowed_property', label:'Prestada', status:false},
      {id:'owned_property', label:'Propia', status:false},
      {id:'mortgaged_property', label:'Hipotecada', status:false},
    ],

  }

  render(){


    const {history} = this.props
    const {issending, error, isloading, selected, has_insurance, first_time, credit_cards, 
      formData, patient_status, progress, isCreating, error_process_message, generalError, patient_id
    } = this.state


    const {properties, has_vehicles, vehicles, benefit, isSuccessful} = this.state    

    // Requested catalogs reassembly
    const userType = getUserType()

    let formContent = null

    let updated_form = {...formData}

    // Update catalogs


    let creatingModal = null
    if(isCreating) creatingModal = <LoadingModal />
    if(isSuccessful) creatingModal = <SuccessModal onFinished={() => history.push('/patients')} />

    let errorModal = null
    let generalErrorModal = null
    if(error_process_message && error_process_message.length > 0){
      errorModal = (
        <ErrorProcessModal 
          history={history} idpatient={patient_id}
          data={error_process_message}
          onClose={() => console.log('No se puede cerrar')}
          onFinished={() => history.push('/patients')} 
        />
      )
    }

    if(generalError && generalError.length > 0){
      errorModal = (
        <GeneralErrorModal 
          history={history} idpatient={patient_id}
          data={generalError}
          onClose={() => console.log('No se puede cerrar')}
          onFinished={() => this.setState({generalError:[]})} 
        />
      )
    }

    if(!isloading){
      switch (selected) {
        case 1:
          formContent = (
            <FormStepA 
              input_data={updated_form}
              first_time={first_time}
              has_insurance={has_insurance}
              onChangeStep={this.onChangeStep}
              onInputChange={this.onInputChange}
              onChangeCheckbox={this.onChangeCheckbox}/>
          )
          break;
        case 2:
          formContent = (
            <FormStepB 
              input_data={updated_form}
              credit_cards={credit_cards}
              onUpdateCollection={this.onUpdateCollection}
              onInputChange={this.onInputChange}/>
          )
          break;
        case 3:
          formContent = (
            <FormStepC
              vehicles={vehicles}
              properties={properties}
              has_vehicles={has_vehicles}
              onChangeCheckbox={this.onChangeCheckbox}
              onUpdateCollection={this.onUpdateCollection}
              onUpdateProperties={(data) => this.setState({properties:data})}/>
          )
          break;
        case 4:
            formContent = (
              <FormStepD formData={formData} has_insurance={has_insurance} has_vehicles={has_vehicles} first_time={first_time}
              vehicles={vehicles} properties={properties} credit_cards={credit_cards} benefit={benefit} />
            )
            break;
      
        default:
          break;
      }
    }    

    //////// HANDLE NAVIGATION BUTTONS //////// 

    let nextButton = null
    let backButton = null

    if(selected < 5){
      nextButton = (
        <Grid item>
          <RoundedButton 
            label={selected === 4 ? (patient_status ? 'Enviar a aprobación' : 
            (userType === USER_TYPE.HOSPITAL ? 'Crear solicitante' : 'Crear beneficiario')) :'Siguiente'}
            onClick={this.onNextStep}
            color='secondary'
          />
        </Grid>
      )
    }

    if(selected > 1){
      backButton = (
        <Grid item>
          <RoundedButton 
            label='Regresar'
            onClick={this.onPreviousStep}
            color='transparent'
          />
        </Grid>
      )
    }


    //////// HANDLE INTERNAL ERRORS AND WAITINGS STATES //////// 
    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }
    
    
    return(
      <BasicView
        history={history}
        content={layout}
        isloading={isloading}
        onCloseSnackbar={this.onCloseSnackbar} 
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {generalErrorModal}
        {errorModal}
        {creatingModal}
        <Grid container>
          <Grid item xs={12}>
            <RegisterCard
              userType={userType}
              progress={progress}
              menu={views}
              selected={selected}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {formContent}     
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} direction='row' justify='flex-end' alignItems='center'>
                    <Grid item>
                      {messageContent}
                    </Grid>
                    {backButton}
                    {nextButton}
                  </Grid>
                </Grid>
              </Grid>

            </RegisterCard>
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  }

  onNextStep = async() => {
    const {selected, formData} = this.state
    const userType = getUserType()
    let nextStep = selected

    if(selected === 2){
      if(!formData.income.value || !formData.income.isValid){
        this.setState({error:'Falta llenar el campo de Rango de Ingresos o es inválido'})
        return
      }
    }

    if(selected === 3){ // The prelast number
      /// CALCULATE PREBENEFIT
      try {
        const data2send = this.onGetData2PrebenefitRequest()
        const response = await request_prebenefit(data2send)
        this.setState({benefit:response})
      } catch (error) {
        this.setState({error:'Hubo un error al calcular el beneficio, revise los ingresos y propiedades'})
        return
      }
      
    } 

    if(selected === 4){ // Post data
      this.onCreateFMIPatient()
    }
    
    if(selected < 4) nextStep++
    this.setState({selected:nextStep, error:null})

  }

  onPreviousStep = () => {
    const {selected} = this.state
    if(selected > 1){
      this.setState({selected:selected-1})
    }
  }

   /////////////////////////////////////////////////////////////////
  //                PREPARE DATA TO CALCULATE BENEFIT
  /////////////////////////////////////////////////////////////////

  onGetData2PrebenefitRequest = () => {
    const { income, private_elementary_school_children,
      private_secondary_school_children, private_high_school_children, private_university_children,
      dependant_wives, dependant_children, dependant_parents, dependant_inlaws, dependant_other
    } = this.state.formData

    const {properties, has_vehicles, credit_cards } = this.state

    const dependant_count = 
    (dependant_wives.value ? parseInt(dependant_wives.value) : 0) + 
    (dependant_children.value ? parseInt(dependant_children.value) : 0) + 
    (dependant_parents.value ? parseInt(dependant_parents.value) : 0) + 
    (dependant_inlaws.value ? parseInt(dependant_inlaws.value) : 0) + 
    (dependant_other.value ? parseInt(dependant_other.value)  : 0)
    

    let data2send = {
      income: income.value,
      dependant_count: dependant_count,
      has_private_elementary_school_children: private_elementary_school_children.value ? 1 : 0,
      has_private_secondary_school_children: private_secondary_school_children.value ? 1 : 0,
      has_private_high_school_children: private_high_school_children .value? 1 : 0,
      has_private_university_children: private_university_children.value ? 1 : 0,
      has_car: has_vehicles ? 1 : 0,
      has_credit_card: credit_cards.length ? 1 : 0, 
    }

    properties.forEach(item => {
      //console.log(item)
      data2send = {...data2send, [item.id]: item.status ? 1 : 0 } 
    })

    return data2send

  }

  /////////////////////////////////////////////////////////////////
  //                CREATE PATIENT
  /////////////////////////////////////////////////////////////////

  onCreateFMIPatient = async() => {
    const {formData, properties, has_vehicles, has_insurance, credit_cards, vehicles, benefit } = this.state
    let generalError = []
    this.setState({isCreating:true})
    

    let data2send = {}
    Object.keys(formData).forEach(item => {
        if(formData[item].isValid){
          data2send = {...data2send, [item]:formData[item].value}
        }
    })

    if(data2send.phone){
      data2send.phone = data2send.phone.split(' ').join('')
    }

    if(!has_insurance){
      data2send = {...data2send, insurance_carrier:undefined}
    }

    properties.forEach(item => {
      //console.log(item)
      data2send = {...data2send, [item.id]: item.status ? 1 : 0 } 
    })
    data2send = { ...data2send,
      has_car: has_vehicles ? 1 : 0,
      has_credit_card: credit_cards.length ? 1 : 0, 
      has_insurance: has_insurance ? 1 : 0, 
      benefit_level_id: benefit,
    }

    let patient_id
    /////////////////////////////////////////////////////////////////
    //                CREATE PATIENT
    /////////////////////////////////////////////////////////////////
    try {
      patient_id = await request_create_patient(data2send)
    } catch (error) {
      generalError = [...generalError, 
        {title:'Creación de beneficiario', message:'No se pudo crear el registro, revise que toda la información este correcta'}]
        this.setState({isCreating:false})
        this.setState({generalError:generalError})
      return
    }
    
    
    /////////////////////////////////////////////////////////////////
    //                CREATE CREDIT CARDS
    /////////////////////////////////////////////////////////////////
    let temp
    try {
      if(credit_cards.length){
        for(let i=0; i<credit_cards.length; i++){
          temp = {
            patient_id:patient_id, 
            name:credit_cards[i].name,
            credit_range_id:credit_cards[i].credit_range_id,
          }
          await request_add_credit_card(temp)
        }
      }
    } catch (error) {
      generalError = [...generalError, 
        {title:'Tarjetas de crédito', message:'No se pudo crear alguna de las tarjetas de crédito en lista'}]
    }
    

    ////////////////////////////////////////////////////////////////
    //                CREATE VEHICLES
    /////////////////////////////////////////////////////////////////
    try {
      if(has_vehicles && vehicles){
        for(let i=0; i<vehicles.length; i++){
          temp = {
            patient_id:patient_id, 
            name:vehicles[i].name,
            car_type_id:vehicles[i].car_type_id,
            car_range_id:vehicles[i].car_range_id,

          }
          await request_add_vehicle(temp)
        }
      }
    } catch (error) {
      generalError = [...generalError, 
        {title:'Vehículos', message:'No se pudo crear alguno de los vehículos en lista'}]
    }
    this.setState({isCreating:false})

    if(generalError.length === 0){
      this.setState({isSuccessful:true})
      this.props.history.push(`/patients/${patient_id}`)
    }else{
      this.setState({error_process_message:generalError})
    }
  }




  onUpdateCollection = (key, value) => {
    this.setState({[key]:value})
  }

  //////////////////////// DOCUMET MANIPULATION //////////////////////////////
  onDocumentAttached = (id, files) => {
    this.setState({[id]:files[0]})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onChangeCheckbox = (id,status) => {
    switch(id){
      case 'has_insurance':
        this.onCleanItem('insurance_carrier')
        this.setState({[id]:status})
        break
      default:
        this.setState({[id]:status, policy_value:null})
        break
    }
  }


  onSetItem = (id,value) => {
    const {formData} = this.state
    let temp = {...formData}
    temp[id].value = value
    temp[id].isValid = true
    temp[id].isVisited = true
    this.setState({formData:{...temp}})
  }

  onCleanItem = (id) => {
    const {formData} = this.state
    let temp = {...formData}
    temp[id].value = null
    temp[id].isValid = false
    temp[id].isVisited = false
    this.setState({formData:{...temp}})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onUpdateData = (data) => {
    this.setState({...data})
  }

}

export default AddApplicant