import React, {useState} from 'react'
import { Grid } from '@material-ui/core'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import CircularButton from '../../../../../../components/Buttons/CircularButton'
import CreditCardTable from '../CreditCardTable/CreditCardTable'
import AddRegisterModal from '../../modals/AddRegisterModal'


const  CreditCards = props => {

    const {credit_cards, onUpdateCollection} = props
    const [registerModal, setRegisterModal] = useState(false)


    const getRandomInt = (max) => {
        return Math.floor(Math.random() * Math.floor(max));
    }

    const onAddItem = (form) => {
            let temp = [...credit_cards]
            let idx
            let isRepeated = true
            while(isRepeated){
                idx = getRandomInt(100)
                const filterItem = temp.filter(el => el.id === idx)
                if(filterItem.length === 0) isRepeated = false 
            }
            const data2send = {id:idx, name: form.name.value, credit_range_id: form.credit_range_id.value}
            console.log(data2send)
            temp.push(data2send)
            onUpdateCollection('credit_cards', temp)
            setRegisterModal(false)
    }

    const onRemoveItem = (item) => {
        console.log(item)
        let newset = credit_cards.filter(e => e.id !== item.id)
        onUpdateCollection('credit_cards', newset)
    }

    return(
        <div>
            <AddRegisterModal open={registerModal} onAddItem={onAddItem}
            onClose={() => setRegisterModal(false)}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DisplayText variant='subtitle1'>Tarjetas de crédito</DisplayText>
                        </Grid>
                        <Grid item>
                            <CircularButton icon='add' size='small' onClick={() => setRegisterModal(true)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <CreditCardTable data={credit_cards} onRemoveItem={onRemoveItem}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreditCards