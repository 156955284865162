import React, {useEffect, useState} from 'react'
import {contentData} from './content'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {request_users} from './requests'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import AddAgentModal from './modals/AddAgentModal/AddAgentModal'


const Agents = props => {
  const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [totalUsers, setTotalUsers] = useState(10)
    const [addModal, setAddModal] = useState(false)
    const [filter, setFilter] = useState({
      status:-1,
      user_type_id:-1,
      search_by:'first_name',
      query:''
    })
    const [tableFilter, setTableFilter] = useState({
      limit:10,
      offset:0,
      order:0,
      order_by:'id_user',
    })

    useEffect(() => {
      const onRefreshData = async() => {
        const params = onGetParamsFromState()
        const response = await request_users(params)
        setUsers(response.users)
        setTotalUsers(response.count)
        onUpdateURLArgs()
      }
      onRefreshData()
      
    }, [filter, tableFilter])


    useEffect(() => {
      const initModule = async() => {
        setLoading(true)
        let params = ''

        if(location.search){
          //Get params from url
          params = onUpdateStateFromURL()
        }else{
          // Get params from initial state
          params = onGetParamsFromState()
          onUpdateURLArgs()
        }
        try {
          const response = await request_users(params)
          setUsers(response.users)
          setTotalUsers(response.count)
        } catch (error) {
          
        }
        setLoading(false)

        
      }
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data) initModule();
    },[])

    const onRequestUsers = async() => {
      try {
        let params = onGetParamsFromState()
        const response = await request_users(params)
        setUsers(response.users)
        setTotalUsers(response.count)
        setAddModal(false)
      } catch (error) {
        console.log('Hubo un error')
      }
    }

    const onSelectedItem = (item) => {
      const {id_user} = item
      history.push(`/agents/${id_user}`)
    }

    const onChangeFilter = (tag, value) => { // Change generic filter
      let newFilter = onUpdateFilter(tag, value)
      setFilter(newFilter)
    }

    const onUpdateStateFromURL = () => {
      let params = ''
      const args = queryString.parse(location.search)
      const {limit, offset, order, order_by, status, user_type_id , search_by, query} = args
      let newFilter = {...filter}
      let newTableFilter = {...tableFilter}
      let filterArgs = []

      if(limit !== undefined) newTableFilter.limit = parseInt(limit)
      if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
      if(order !== undefined) newTableFilter.order = parseInt(order)
      if(order_by !== undefined) newTableFilter.order_by = order_by


      if(status !== undefined){
        newFilter.status = parseInt(status)
        filterArgs.push({"field":"status","operator":"LIKE","value":status})
      }
      if(user_type_id !== undefined){
        newFilter.user_type_id = parseInt(user_type_id)
        filterArgs.push({"field":"user_type_id","operator":"LIKE","value":user_type_id})
      }

      newFilter.search_by = search_by
      if(query && query !==''){
        newFilter.query = query
        filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})
      }

      params = {...newTableFilter}
      params = `?${queryString.stringify(params)}`
      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `${params}&filter=${filterArgs}`
      }

      setFilter(newFilter)
      setTableFilter(newTableFilter)

      return params

    }

    const onGetParamsFromState = () => {
      let params2send = {...tableFilter}
      params2send.offset = params2send.offset*params2send.limit
      const {status, user_type_id, search_by, query} = filter

      

      let filterArgs = []
      
      if(status !== -1) filterArgs.push({"field":"status","operator":"LIKE","value":status})
      if(user_type_id !== -1) filterArgs.push({"field":"user_type_id","operator":"LIKE","value":user_type_id})
      if(query && query !=='') filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})

      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        return `?${queryString.stringify(params2send)}&filter=${filterArgs}`
      }else{
        return `?${queryString.stringify(params2send)}`
      }
      
    }

    const onUpdateURLArgs = () => {
      let params = {...tableFilter}
      const {status, user_type_id, search_by, query} = filter
      params.offset = params.offset*params.limit
      if(status !== -1) params = {...params, status:status}
      if(user_type_id !== -1) params = {...params, user_type_id:user_type_id}
      params = {...params,
        search_by:search_by,
        query:query
      }
      const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
      window.history.replaceState(null, null, urlwithargs);
    }

    

    const onUpdateFilter = (id,value) => {
      let temp = {...filter}
      temp[id] = value
      return temp
    }

    const content = contentData['spanish']
    //console.log(users)
    
    return(
      <BasicView content={{
          title:'Agentes',
          navigation:[
            {name:'Agentes',path:'/agents'}
          ],
      }} isloading={loading}>
        <AddAgentModal open={addModal} onClose={() => setAddModal(false)}
        onRequestUsers={onRequestUsers}/>
        <SimpleTable 
          data={users} 
          filter={filter}
          tableFilter={tableFilter}
          total={totalUsers}
          header={content.table_header} 
          onAddRegister={() => setAddModal(true)}
          onChangeFilter={onChangeFilter}
          onUpdateTableFilter={(data) => setTableFilter(data)}
          onSelectedItem={onSelectedItem}/>
      </BasicView>
    )
}

export default Agents