import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, LinearProgress } from '@material-ui/core'
import { grey} from '@material-ui/core/colors'

import CardWrapper from '../../Cards/CardWrapper/CardWrapper';
import { Title } from '../../DisplayData/DisplayData';
import ContentText from '../../Texts/ContentText';
import Subtitle from '../../Texts/Subtitle';
import { USER_TYPE } from '../../../variables/config';

const styles = (theme) => ({
  root:{
    //background:'red'
  },
  header:{
    padding:40
  },
  loader:{
    color:grey[700],
    fontWeight:'400'
  },
  loaderContainer:{
    padding:'40px'
  },
  toobalCell:{
    padding:16,
    background:grey[100],
  },
  pillContainer:{
    width:'100%',
    padding:8,
    paddingTop:20,
    paddingBottom:20,
    zIndex:1,
    top:-4,
    left:-8,
    borderRadius:8,
    position:'absolute',
    background:theme.palette.secondary.main
  },
  mainContainer:{
    padding:32
  }
})

class RegisterCard extends Component{

  componentDidMount(){

  }

  render(){

    const {classes, selected, menu, progress, userType} = this.props;

    const toolbarContent = menu.map((item,key)=>{
      let pillContent = null
      if(item.id===selected){
        pillContent = (
          <div className={classes.pillContainer}>
            <Subtitle color='white' text={item.name} align='center' medium/>
          </div>
        )
      }

      return(
        <Grid item xs={12} md key={key.toString()}>
          <div style={{position:'relative'}}>
            {pillContent}
            <div className={classes.toobalCell}>
              <ContentText text={item.name} align='center' variant='light'/>
            </div>
          </div>
          
        </Grid>
      )
    })

    let progressContent = null

    if(progress < 100){
      progressContent = (
        <div style={{width:40,marginTop:-4}}>
          <ContentText text={`${progress} %`} align='right'/>
        </div>
        
      )
    }
    
    return(
      <div className={classes.root}>
        <CardWrapper>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.header}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title data={userType === USER_TYPE.HOSPITAL ? 'Creación del solicitante' : 'Creación del beneficiario'} align='center' color={grey[600]}/>
                  </Grid>
                  <Grid item xs={12}>
                    <ContentText 
                      align='center'
                      text='Llene cada uno de los apartados para poder generar la oferta personalizada' 
                      variant='light'
                      medium
                    />
                  </Grid>
                  {/*<Grid item xs={12} alignItems='center'>
                    <Grid container wrap='nowrap'>
                      <Grid item xs>
                        <BorderLinearProgress variant="determinate" value={progress}/>
                      </Grid>
                      <Grid item>
                        {progressContent}
                      </Grid>
                    </Grid>
                    
    </Grid>*/}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {toolbarContent}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.mainContainer}>
                {this.props.children}
              </div>
            </Grid>
          </Grid>
          

        </CardWrapper>
      </div>
    )
  }
}

export default withStyles(styles)(RegisterCard)

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);