import React, {useEffect, useState} from 'react'
import {contentData} from './content'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {request_patients} from './requests'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import {getUserToken} from '../../../shared/utility'
import { USER_TYPE } from '../../../variables/config'


const Applicants = props => {
  const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    const [totalPatients, setTotalPatients] = useState(10)
    const [filter, setFilter] = useState({
      status:-1,
      response:-1,
      benefit:-1,
      search_by:'id_patient',
      query:''
    })
    const [tableFilter, setTableFilter] = useState({
      limit:10,
      offset:0,
      order:0,
      order_by:'id_patient',
    })

    useEffect(() => {
      const onRefreshData = async() => {
        const params = onGetParamsFromState()
        const response = await request_patients(params)
        setPatients(response.patients)
        setTotalPatients(response.count)
        onUpdateURLArgs()
      }

      const data = JSON.parse(localStorage.getItem('data'))
      if(data && data.token) onRefreshData()
      
    }, [filter, tableFilter])


    useEffect(() => {
      const initModule = async() => {
        setLoading(true)
        let params = ''

        if(location.search){
          //Get params from url
          params = onUpdateStateFromURL()
        }else{
          // Get params from initial state
          params = onGetParamsFromState()
          onUpdateURLArgs()
        }

        const response = await request_patients(params)
        setPatients(response.patients)
        setTotalPatients(response.count)
        setLoading(false)
      }
      const data = JSON.parse(localStorage.getItem('data'))
      if(data && data.token) initModule();
    },[])

    const onSelectedItem = (item) => {
      const {id_patient} = item
      history.push(`/patients/${id_patient}`)
    }

    const onChangeFilter = (tag, value) => { // Change generic filter
      let newFilter = onUpdateFilter(tag, value)
      setTableFilter({...tableFilter, offset:0})
      setFilter(newFilter)
    }

    const onUpdateStateFromURL = () => {
      let params = ''
      const args = queryString.parse(location.search)
      const {limit, offset, order, order_by, status, response, benefit , search_by, query} = args
      let newFilter = {...filter}
      let newTableFilter = {...tableFilter}
      let filterArgs = []

      if(limit !== undefined) newTableFilter.limit = parseInt(limit)
      if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
      if(order !== undefined) newTableFilter.order = parseInt(order)
      if(order_by !== undefined) newTableFilter.order_by = order_by

      const user_token = getUserToken()
      if(user_token && user_token.user_type_id === USER_TYPE.REGISTRANT) filterArgs.push({"field":"created_by","operator":"=","value":user_token.user_id})
      
      if(status !== undefined){
        newFilter.status = parseInt(status)
        filterArgs.push({"field":"patient_status_id","operator":"LIKE","value":status})
      }
      if(benefit !== undefined){
        newFilter.benefit = parseInt(benefit)
        filterArgs.push({"field":"patient_status_id","operator":"LIKE","value":benefit})
      }
      if(response !== undefined){
        newFilter.response = parseInt(response)
        filterArgs.push({"field":"patient_status_id","operator":"LIKE","value":response})
      }

      newFilter.search_by = search_by
      if(query && query !==''){
        newFilter.query = query
        filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})
      }

      params = {...newTableFilter}
      params = `?${queryString.stringify(params)}`
      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `${params}&filter=${filterArgs}`
      }

      setFilter(newFilter)
      setTableFilter(newTableFilter)

      return params

    }

    const onGetParamsFromState = () => {
      let params2send = {...tableFilter}
      params2send.offset = params2send.offset*params2send.limit
      const {status, response, benefit, search_by, query} = filter

      

      let filterArgs = []
      const user_token = getUserToken()
      if(user_token && user_token.user_type_id === USER_TYPE.REGISTRANT) filterArgs.push({"field":"created_by","operator":"=","value":user_token.user_id})
      //if(status) filterArgs.push({"field":"patient_status_id","operator":"LIKE","value":status})
      if(status !== -1) filterArgs.push({"field":"patient_status_id","operator":"=","value":status})
      if(response !== -1) filterArgs.push({"field":"test_result","operator":"LIKE","value":response})
      if(benefit !== -1) filterArgs.push({"field":"benefit_level_id","operator":"=","value":benefit})
      if(query && query !==''){
        let _query = query
        if(search_by === 'id_patient'){
          _query =  _query.toUpperCase().replace('FMI','')
          _query = parseInt(_query) ? parseInt(_query) : ''
        }
        filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${_query}%`})
      }

      if(filterArgs.length){
        
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        return `?${queryString.stringify(params2send)}&filter=${filterArgs}`
      }else{
        return `?${queryString.stringify(params2send)}`
      }
      
    }

    const onUpdateURLArgs = () => {
      let params = {...tableFilter}
      const {status, response, benefit, search_by, query} = filter
      params.offset = params.offset*params.limit
      if(status !== -1) params = {...params, status:status}
      if(response !== -1) params = {...params, response:response}
      if(benefit !== -1) params = {...params, benefit:benefit}
      params = {...params,
        search_by:search_by,
        query:query
      }
      const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
      window.history.replaceState(null, null, urlwithargs);
    }

    

    const onUpdateFilter = (id,value) => {
      let temp = {...filter}
      temp[id] = value
      return temp
    }

    const content = contentData['spanish']
    //console.log(patients)
    
    return(
      <BasicView content={content.layout} isloading={loading}>
        <SimpleTable 
        data={patients} 
        filter={filter}
        tableFilter={tableFilter}
        total={totalPatients}
        header={content.table_header} 
        onAddRegister={() => props.history.push('/patients/add')}
        onChangeFilter={onChangeFilter}
        onUpdateTableFilter={(data) => setTableFilter(data)}
        onSelectedItem={onSelectedItem}
        />
      </BasicView>
    )
}

export default Applicants