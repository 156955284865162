import {mainServer} from '../../../variables/config'

export const request_logs = async (urlArgs) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/log/all${urlArgs}`)
  return response.data.data
}

export const request_patient = async (patient_id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/patient/${patient_id}`)
  return response.data.data.patient
}

