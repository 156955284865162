import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import nouser from '../../../../assets/nouser.png'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import HorizontalDataWrapper from '../../../../components/Texts/HorizontalDataWrapper'
import { nodata } from '../../../../variables/config'
import {constrainst_basic_information as constrainst} from  '../constraints'
import DisplayText from '../../../../components/Texts/DisplayText'
import { blueGrey, grey } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons'
import moment from 'moment'
import { numberWithCommas } from '../../../../shared/utility'


const useStyles = makeStyles(theme => ({
    image:{
        width:160,
    },
    container:{
        padding:32,
        
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    subtitle:{
        color:blueGrey[500]
    }
}))

const IncomeContainer = props => {

    const classes = useStyles()
    const {data, onEdit} = props


    let income_value 
    if(data){
        if(data.income) income_value = `$ ${numberWithCommas(data.income)}`
    }

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Ingresos</DisplayText>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.container}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <ContraintLayout constrainst={constrainst.name}>
                                    <HorizontalDataWrapper 
                                        label='Rango de ingresos' text={income_value}/>
                                </ContraintLayout>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='subtitle1' className={classes.subtitle}>Dependientes económicos</DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <ContraintLayout constrainst={constrainst.dependant_wives}>
                                            <HorizontalDataWrapper 
                                                label='Cónyuge o Pareja' text={data ? data.dependant_wives : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.dependant_children}>
                                            <HorizontalDataWrapper 
                                                label='Hijos' text={data ? data.dependant_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.dependant_parents}>
                                            <HorizontalDataWrapper 
                                                label='Padres' text={data ? data.dependant_parents : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.dependant_inlaws}>
                                            <HorizontalDataWrapper 
                                                label='Suegros' text={data ? data.dependant_inlaws : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.dependant_other}>
                                            <HorizontalDataWrapper 
                                                label='Otros' text={data ? data.dependant_other : nodata}/>
                                        </ContraintLayout>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='subtitle1' className={classes.subtitle}>Hijos en edad escolar</DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <ContraintLayout constrainst={constrainst.public_elementary_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Primaria pública' text={data ? data.public_elementary_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.private_elementary_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Primaria privada' text={data ? data.private_elementary_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.public_secondary_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Secundaria pública' text={data ? data.public_secondary_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.private_secondary_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Secundaria privada' text={data ? data.private_secondary_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.public_high_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Preparatoria pública' text={data ? data.public_high_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.private_high_school_children}>
                                            <HorizontalDataWrapper 
                                                label='Preparatoria privada' text={data ? data.private_high_school_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.private_university_children}>
                                            <HorizontalDataWrapper 
                                                label='Superior pública' text={data ? data.private_university_children : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout constrainst={constrainst.private_university_children}>
                                            <HorizontalDataWrapper 
                                                label='Superior privada' text={data ? data.private_university_children : nodata}/>
                                        </ContraintLayout>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <ContraintLayout constrainst={constrainst.edit_button} md={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton label={'Editar'} onClick={onEdit}/>
                                </Grid>
                            </Grid>
                        </ContraintLayout>  
                    </Grid>
                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default IncomeContainer