import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import userimage from '../../../../assets/properties.png'
import DisplayText from '../../../../components/Texts/DisplayText';
import RegisterCheckbox from '../../../../components/Inputs/RegisterCheckbox';
import SwitchButton from '../../../../components/Buttons/SwitchButton';
import Vehicle from './components/Vehicle/Vehicle';

const useStyles = makeStyles(theme => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:120,
    height:120,
  }
}))

const FormStepC = props => {

    const classes = useStyles()
    const {vehicles, properties, has_vehicles, onChangeCheckbox, onUpdateCollection, onUpdateProperties} = props

    const onInnerChange = (event) => {
      const id = event.target.id
      const checked = event.target.checked
  
      let temp = [...properties]
      if(checked){
        properties.forEach((item,key) => {
          temp[key].status = false
          if(item.id === id) temp[key].status = true
        })
      }else{
        properties.forEach((item,key) => {
          temp[key].status = false
        })
      }
  
      onUpdateProperties(temp)
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justify='center'><Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                </Grid></Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <DisplayText variant='subtitle1'>Vivienda</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}/>
                            <Grid item xs={6}>
                              <DisplayText align='center' variant='body2'>Solo contar una vivienda (Indica el tipo de vivienda)</DisplayText>
                            </Grid>
                          </Grid>
                        </Grid>
                        {properties.map((item, key)=>{
                          return(
                            <Grid item xs={12} key={key.toString()}>
                              <RegisterCheckbox id={item.id} onChange={onInnerChange}
                              label={item.label} status={item.status}/>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify='flex-end'>
                          <Grid item>
                            <SwitchButton 
                              id='has_vehicles' status={has_vehicles} onChange={onChangeCheckbox}
                              message='¿El beneficiario cuenta con vehículo(s)?'/>
                          </Grid>
                        </Grid>
                    </Grid>
                    {has_vehicles ? (<Grid item xs={12}>
                      <div style={{paddingTop:32}}>
                      <Vehicle vehicles={vehicles} onUpdateCollection={onUpdateCollection}/>

                      </div>
                    </Grid>):null}
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
}

export default FormStepC