import React from 'react'
import {ResponsiveContainer, PieChart, Pie, Cell} from 'recharts'
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const useStyles = makeStyles(theme => ({
    root:{
        height:500,
        [theme.breakpoints.only('md')]: {
            height:400,
        },
        [theme.breakpoints.down('sm')]: {
            height:250,
        },
    }
}))

const CustomPieChart = props => {

    const classes = useStyles()
    const {data} = props

    const COLORS = [...props.colors];

    let isValid = false
    if(data){
        if(data.length){
            data.forEach(item => {
                if(item.value !== 0){
                    isValid = true
                }
            })
        }
    }


    return(
      <div className={classes.root}>
        <ResponsiveContainer>
          <PieChart>
            <Pie 
            data={data} 
            fill="#8884d8" 
            labelLine={false}
            label={renderCustomizedLabel}>
              {
                data.map((entry, index) => <Cell key={index.toString()} fill={isValid ? COLORS[index % COLORS.length] : grey[400]}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
}

export default CustomPieChart