import React from 'react'
import { Grid, TableRow,TableCell, Icon, Table, TableBody } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors';
import DisplayText from '../../../../components/Texts/DisplayText';


const useStyles = makeStyles(theme => ({
    root:{},
    tablecell:{
        borderTop:`2px solid ${grey[200]}`,
        padding:'24px 16px',
        border:0,
        '&:hover':{cursor:'pointer'}
    },
    tablerow:{
        '&:hover':{background:'rgba(0,0,0,0.05)'}
    },
    icon:{fontSize:12,}
}))

const PercentageTable = props => {
    const classes = useStyles()
    const {data, footer, nopercentage} = props

   


    return(
      
      <div className={classes.root}>
        <Table>
          <TableBody>
            {data.map((item,key)=>{

              let percentageNumber = ''
              if(item[3] !== null && item[3] !== undefined){
                const temp = parseFloat(item[3])
                if(!isNaN(temp)){
                  percentageNumber = `${temp.toFixed(1)} ${nopercentage ? '' : '%'}`
                }else{
                  const finalValue = item[3].toString()
                  percentageNumber = finalValue === 'NaN' ? '0.0 %' : finalValue
                }
              }

              return(
                    <TableRow key={key.toString()}>
                        <TableCell>
                            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                                {item[0] ? <Grid item><Icon className={classes.icon} style={{color:item[0]}}>brightness_1</Icon></Grid> : null}
                                <Grid item>
                                    <DisplayText variant='subtitle2'>{item[1]}</DisplayText>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <DisplayText variant='body2'>{item[2] !== null && item[2] !== undefined ? item[2] : ''}</DisplayText>
                        </TableCell> 
                        <TableCell>
                            <DisplayText variant='body2'>{percentageNumber}</DisplayText>
                        </TableCell>  
                    </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {footer}
      </div>
    )

}

export default PercentageTable