import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import LineInputDate from '../../../../../components/Inputs/LineInputDate'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText'
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import {constrainst_basic_information as constrainst} from  '../../constraints'
import { onValidateForm } from '../../../../../shared/utility'
import {formData } from './data'
import SwitchButton from '../../../../../components/Buttons/SwitchButton'
import moment from 'moment'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton'
import {request_update_patient} from '../../requests'

const EditDiscountModal = props => {

    const {initData, patientID, open, onClose, onUpdatePatient} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!initData) return
        let temp = {...form}
        Object.keys(form).forEach( item =>{
            let newValue = initData[item] 
            if(newValue !== null && newValue !== undefined){
                temp[item].value = newValue.toString()
                temp[item].isValid = true
                temp[item].isVisited = true
            }
        })
        setValid(onValidateForm(temp))
        setForm(temp)

    }, [initData, open])


    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        setValid(onValidateForm(temp))
        setForm(temp)
    }

    const onEdit = async() => {
        if(!valid){
            setError('Llena los campos faltantes')
            return
        }
        
        let data2send = {}
        Object.keys(form).forEach(item => {
            if((initData[item] !== form[item].value) && form[item].isValid){
                data2send = {...data2send, [item]: form[item].value}
            }
        })

        setLoading(true)
        try {
            const newPatient = await request_update_patient(patientID, data2send)
            setLoading(false)
            setError(null)
            onUpdatePatient(newPatient)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }
    

    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Cambio de descuento</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <LineInputSelect 
                                data={form.manual_discount_value}
                                onChange={onInputChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton error={error} loading={loading} onSubmit={onEdit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default EditDiscountModal