export const contentData = {
  spanish:{
    nodata:'N/D',
    layout:{
      title:'Logs',
      navigation:[
        {name:'Logs',path:'/logs'}
      ]
    },
    table_header: [
      {id:1, label:'ID'},
      {id:2, label:'Asesor'},
      {id:3, label:'Evento'},
      {id:4, label:'Fecha'},
      {id:5, label:'Hora'},
    ],
    user_event_modal:{
      title:'Detalles',
      content:{
        user:'Asesor',
        log_event:'Evento'
      },
      user:'Usuario modificado'
    },
    patient_event_modal:{
      title:'Detalles',
      content:{
        user:'Asesor',
        log_event:'Evento'
      },
      patient:{
        doctor:'Doctor',
        name:'Paciente',
        gender:'Genero',
        age:'Edad',
        state:'Estado',
        city:'Ciudad'
      },
      benefit:{
        name:'Beneficio',
        discount:'Descuento'
      }
    },
    approval_contact_modal:{
      title:'Detalles',
      content:{
        user:'Asesor',
        log_event:'Evento'
      },
      approvalContact:{
        name:'Nombre',
        email:'Correo',
        creator:'Creador',
        created_at:'Fecha de creación',
        updater:'Actualizado por',
        updated_at:'Fecha de actualización',
      }
    },
    credit_card_modal:{
      title:'Detalles',
      content:{
        user:'Asesor',
        log_event:'Evento'
      },
      creditCard:{
        patient:'Paciente',
        name:'Marca',
        credit_range:'Rango de crédito',
        creator:'Creador',
        created_at:'Fecha de creación',
        updater:'Actualizado por',
        updated_at:'Fecha de actualización',
      }
    },
    car_modal:{
      title:'Detalles',
      content:{
        user:'Asesor',
        log_event:'Evento'
      },
      car:{
        patient:'Paciente',
        name:'Marca',
        car_type:'Tipo',
        car_range:'Rango de costo',
        creator:'Creador',
        created_at:'Fecha de creación',
        updater:'Actualizado por',
        updated_at:'Fecha de actualización',
      } 
    }
  }
}