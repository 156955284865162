import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import StackBarsChart from '../../../components/StackBarsChart'

const useStyles = makeStyles(theme => ({
    root:{padding:32}
}))

const PatientStatsPerState = props => {
    const classes = useStyles()
    const {data} = props

    return(
        <CardWrapper>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Estadísticas generales</DisplayText>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StackBarsChart data={data.chart} />
                    </Grid>
                </Grid>
            </div>
        </CardWrapper>
    )
}

export default PatientStatsPerState