import {mainServer} from '../../../variables/config'

export const request_prebenefit = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.post("/patient/prebenefitlevel",data)
  return response.data.data.benefit_level_id
}

export const request_create_patient = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/patient",data)
  return response.data.data.patient_id
} 

export const request_add_credit_card = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/creditcard",data)
} 

export const request_add_vehicle = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/car",data)
} 