import React from 'react'
import { Dialog, Grow, Icon, IconButton, makeStyles } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow  ref={ref} {...props} timeout={500}/>;
});

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16,
        zIndex:2
    },
}))

const ModalBase = props => {

    const classes = useStyles()
    const {open, onClose, maxWidth} = props

    return(
        <Dialog open={open} fullWidth maxWidth={maxWidth ? maxWidth : 'xs'} classes={{paper:classes.paper}} className={classes.root}
        TransitionComponent={Transition} scroll='body' >
            <div className={classes.container}>
            <div className={classes.buttonContainer}><IconButton onClick={onClose}><Icon >close</Icon></IconButton></div>
                {props.children}
            </div>
        </Dialog>
    )
}

export default ModalBase