import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper'
import CustomPieChart from '../../../components/CustomPieChart'
import PercentageTable from '../../../components/PercentageTable'
import DisplayText from '../../../../../../components/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    root:{padding:32}
}))

const LivingPlacesStats = props => {
    const classes = useStyles()
    const {data} = props

    return(
        <CardWrapper>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>TIPO DE VIVIENDA</DisplayText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PercentageTable data={data.table}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomPieChart data={data.chartTable} colors={data.colors}/>
                    </Grid>
                </Grid>
            </div>
        </CardWrapper>
    )
}

export default LivingPlacesStats