import React, { useEffect, useState } from 'react'
import { Grid, Icon } from '@material-ui/core'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import {request_user} from './requests'
import { nodata } from '../../../../../variables/config'
import moment from 'moment-timezone'

const UserEventModal = props => {

    const {data, initData, open, onClose} = props

    const date_info = initData && initData.created_at ? moment(`${initData.created_at}Z`).tz(moment.tz.guess()) : null
    const date = date_info ? date_info.format('YYYY-MM-DD') : nodata
    const hour = date_info ? date_info.format('HH:mm') : nodata


    const [user, setUser] = useState(null)

    useEffect(() => {
        const onRequestUser = async() => {
            try {
              const response = await request_user(initData.reference_1_id)
              setUser(response)
            } catch (error) {
                setUser(null)
              console.log('Hubo un error')
            }
        }
        
        if(initData && initData.reference_1_id)
            onRequestUser()
    },[initData, open])

    
    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container direction='column' spacing={2}>
                    <Grid item xs>
                        <DisplayText variant='h6'>{data.title}</DisplayText>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction='column' alignItems='flex-end' spacing={2}>
                            <Grid item xs>   
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <DisplayText variant='body1'>{date}</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <Icon>calendar_today</Icon>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs>   
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <DisplayText variant='body1'>{hour}</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <Icon>schedule</Icon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Object.keys(data.content).map((item,key) => {
                        return(
                            <Grid item xs key={key}>
                                <Grid container direction='column'>
                                    <Grid item xs>   
                                        <DisplayText variant='subtitle1'>{data.content[item]}</DisplayText>
                                    </Grid>
                                    <Grid item xs>   
                                        <DisplayText variant='body1'>{initData ? initData[item]: null}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )    
                    })}
                    {
                        user ? 
                            <Grid item xs>
                                <Grid container direction='column'>
                                    <Grid item xs>   
                                        <DisplayText variant='subtitle1'>{data.user}</DisplayText>
                                    </Grid>
                                    <Grid item xs>   
                                        <DisplayText variant='body1'>{user.first_name+' '+user.last_name}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        :null
                    }
                </Grid>
            </div>
        </ModalBase>
    )
}

export default UserEventModal