import { generalCatalogs } from "../../../../../variables/catalogs";

export const formData = {
      doctor:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'doctor',
          type:'text',
          fullWidth: true,
          label:'Doctor',
          placeholder:'Escriba el nombre del doctor...'
        },
        rules:{
          type:'distance',
        }
      },
      trf:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'trf',
          type:'text',
          fullWidth: true,
          label:'TRF',
          placeholder:'TRF0001, TRF0002...'
        },
        rules:{
          type:'distance',
        }
      },
      test_type_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.test_types],
        config:{
          id:'test_type_id',
          type:'select',
          fullWidth: true,
          label:'Tipo de prueba',
          placeholder:'Seleccione una opción...',
          helper:'Selecciona una opcion'
        },
        rules:{
          type:'select',
        }
      },
      manual_test:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'manual_test',
          type:'text',
          fullWidth: true,
          label:'Nombre de la prueba',
          placeholder:'Introduzca un nombre...',
          helper:'Ingrese un valor'
        },
        rules:{
          type:'distance',
        }
      },
      operator_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.operators],
        config:{
          id:'operator_id',
          type:'select',
          fullWidth: true,
          label:'Operador',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
      },
      manual_operator_name:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'manual_operator_name',
          type:'text',
          fullWidth: true,
          label:'Operador',
          placeholder:'Introduzca un nombre...',
          helper:'Ingrese un valor'
        },
        rules:{
          type:'distance',
        }
      },
      manual_operator_contact:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'manual_operator_contact',
          type:'text',
          fullWidth: true,
          label:'Nombre de contacto',
          placeholder:'Introduzca un nombre...',
          helper:'Ingrese un valor'
        },
        rules:{
          type:'distance',
        }
      },
      manual_operator_phone:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'manual_operator_phone',
          type:'number',
          fullWidth: true,
          label:'Teléfono de contacto',
          placeholder:'Introduzca un teléfono...',
          helper:'Teléfono no válido'
        },
        rules:{
          type:'distance',
        }
      },
      manual_operator_email:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'manual_operator_email',
          type:'email',
          fullWidth: true,
          label:'Mail de contacto',
          placeholder:'Introduzca un teléfono...',
          helper:'Correo no valido'
        },
        rules:{
          type:'email',
        }
      },
      test_cost:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'test_cost',
          type:'number',
          float:true,
          fullWidth: true,
          label:'Costo de prueba',
          placeholder:'Ingrese una cantidad...'
        },
        rules:{
          type:'distance',
        }
      },
      pdl:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.boolean_states],
        config:{
          id:'pdl',
          type:'select',
          fullWidth: true,
          label:'PDL 1',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
      },
      test_result:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.boolean_states],
        config:{
          id:'test_result',
          type:'select',
          fullWidth: true,
          label:'Resultado de prueba',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
      },
      patient_status_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.patient_statuses],
        config:{
          id:'patient_status_id',
          type:'select',
          fullWidth: true,
          label:'Estatus del paciente',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
      },
      
}