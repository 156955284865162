import { Grid, makeStyles } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import BasicInformationContainer from './components/BasicInformationContainer'
import ChangePasswordModal from './modals/ChangePasswordModal/ChangePasswordModal'
import PictureModal from './modals/PictureModal/PictureModal'
import { request_me } from './requests'
import * as actionTypes from '../../../store/actions'
import EditAgentModal from './modals/EditAgentModal/EditAgentModal'


const useStyles = makeStyles(theme => ({
  root:{padding:theme.spacing(5)}
}))

const ProfileView = props => {

  const classes = useStyles()
  const {onUpdateUser} = props
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  //////////////// MODALS /////////////////////
  const [infoModal, setInfoModal] = useState(false)
  const [profileModal, setProfileModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)

  useEffect(() => {
    const initModule = async() => {
      setLoading(true)
      try {
        let response = await request_me()
        setUser(response)
      } catch (error) {
        
      }
      setLoading(false)
    }
    if(JSON.parse(localStorage.getItem('data'))) initModule();
  },[])

  const onUpdateUserData = (data) => {
    setUser(data)
    onUpdateUser(data)
  }
    
  return(
    <BasicView
      isloading={loading}
      content={{
        title:'Mi perfil',
        navigation:[
          {name:'Mi perfil',path:'/profile'},
        ],
      }}
    >
      <ChangePasswordModal open={passwordModal} onClose={()=>setPasswordModal(false)}/>
      <EditAgentModal  initData={user} open={infoModal} 
      onClose={()=>setInfoModal(false)} onUpdateUser={onUpdateUserData}/>
      <PictureModal open={profileModal} onUpdateUser={onUpdateUserData}
      onClose={()=>setProfileModal(false)}/>

      <div>
        <CardWrapper>
          <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <BasicInformationContainer data={user} onEditPicture={() => setProfileModal(true)}
                onEdit={() => setInfoModal(true)} onChangePassword={() => setPasswordModal(true)}/>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>
    </BasicView>
    
  )
}

const mapDispatchToProps = dispatch => {
  return{
    onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })

  }
}

export default connect(null, mapDispatchToProps)(ProfileView)