import { generalCatalogs } from "../../../../../variables/catalogs";

export const formData = {
  first_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'first_name',
      type:'text',
      fullWidth: true,
      label:'Nombre(s)',
      placeholder:'Ingrese un nombre...'
    },
    rules:{type:'distance'}
  },
  last_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'last_name',
      type:'text',
      fullWidth: true,
      label:'Apellido(s)',
      placeholder:'Ingrese los apellidos...'
    },
    rules:{type:'distance'}
  },
  user_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[...generalCatalogs.user_types],
    config:{
      id:'user_type_id',
      type:'select',
      fullWidth: true,
      label:'Tipo de usuario',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'select',
    }
  },
  email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'email',
      type:'email',
      fullWidth: true,
      label:'Email',
      placeholder:'Ej. test@fmi.com',
      helper:'Email no válido'
    },
    rules:{type:'email'}
  },  
  mobile:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'mobile',
      type:'number',
      fullWidth: true,
      label:'Celular',
      placeholder:'Ingrese un valor...',
    },
    rules:{type:'distance'}
  },  
  phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'phone',
      type:'number',
      fullWidth: true,
      label:'Teléfono',
      placeholder:'Ingrese un valor...',
    },
    rules:{type:'distance'}
  }, 
  ext:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'ext',
      type:'number',
      fullWidth: true,
      label:'Extensión',
      placeholder:'Ingrese un valor...',
    },
    rules:{type:'distance'}
  }, 
  gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[...generalCatalogs.user_genders],
    config:{
      id:'gender_id',
      type:'select',
      fullWidth: true,
      label:'Género',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'none',
    }
  },
  status:{
    value: 1,
    error: false,
    isVisited: true,
    isRequired: true,
    isValid: true,
    options:[...generalCatalogs.user_statuses],
    config:{
      id:'status',
      type:'select',
      fullWidth: true,
      label:'Estatus',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'select',
    }
  },
  timezone:{
    value: "America/Mexico_City",
    error: false,
    isVisited: true,
    isRequired: true,
    isValid: true,
    options:[...generalCatalogs.timezones],
    config:{
      id:'timezone',
      type:'select',
      fullWidth: true,
      label:'Zona horaria',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'select',
    }
  },
    
}