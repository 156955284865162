import {mainServer} from '../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

/////////////////////////////////////////////////////////////////////////////////
////////        PATIENT'S REQUESTS
/////////////////////////////////////////////////////////////////////////////////

export const request_patient = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
}

export const request_update_patient = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/patient/${id}`, data);
  let response;
  response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
}

export const request_update_patient_status = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/patient/${id}/status`, data);
  let response;
  response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
}


export const request_send_for_approval = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/patient/${id}/sendforapproval`);
}

export const request_calculate_benefit = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/patient/${id}/benefitlevel`);
  let response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
}



/////////////////////////////////////////////////////////////////////////////////
////////        DOCUMENT'S REQUESTS
/////////////////////////////////////////////////////////////////////////////////


export const request_download_letter = async(id, docId) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/patient/${id}/${docId}`, {responseType: 'blob'})
  FileSaver.saveAs(response.data, `${docId}`);
} 

export const request_upload_document = async(id, docId, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patient/${id}/file?name=${docId}`, data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
}

export const request_download_document = async(id, docId) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  response = await mainServer.instance.get(`/patient/${id}/file?name=${docId}`)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, `${docId}`);
} 

export const request_delete_document = async (id, docId) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/patient/${id}/file?name=${docId}`)
  let response = await mainServer.instance.get(`/patient/${id}`);
  return response.data.data.patient
} 

/////////////////////////////////////////////////////////////////////////////////
////////        CREDIT CARD'S REQUESTS
/////////////////////////////////////////////////////////////////////////////////


export const request_credit_cards = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/creditcard/patient/${id}`);
  return response.data.data.credit_cards
}

export const request_add_credit_card = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/creditcard",data)
  let response = await mainServer.instance.get(`/creditcard/patient/${id}`);
  return response.data.data.credit_cards
} 

export const request_edit_credit_card = async (id, idCard, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/creditcard/${idCard}`,data)
  let response = await mainServer.instance.get(`/creditcard/patient/${id}`);
  return response.data.data.credit_cards
} 

export const request_delete_credit_card = async (id, idCard) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/creditcard/${idCard}`)
  let response = await mainServer.instance.get(`/creditcard/patient/${id}`);
  return response.data.data.credit_cards
} 

/////////////////////////////////////////////////////////////////////////////////
////////        VEHICLE'S REQUESTS
/////////////////////////////////////////////////////////////////////////////////

export const request_cars = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/car/patient/${id}`);
  return response.data.data.cars
}


export const request_add_vehicle = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/car",data)
  let response = await mainServer.instance.get(`/car/patient/${id}`);
  return response.data.data.cars
} 

export const request_edit_vehicle = async (id, idCard, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/car/${idCard}`,data)
  let response = await mainServer.instance.get(`/car/patient/${id}`);
  return response.data.data.cars
} 

export const request_delete_vehicle = async (id, idCard) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/car/${idCard}`)
  let response = await mainServer.instance.get(`/car/patient/${id}`);
  return response.data.data.cars
} 












export const request_delete_note = async (id,providerid) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete('/patientnote/'+providerid)
  let response;
  response = await mainServer.instance.get("/patientnote/patient/"+id);
  return response.data.data.patient_notes
}

export const request_add_note = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post('/patientnote', data)
  let response;
  response = await mainServer.instance.get("/patientnote/patient/"+id);
  return response.data.data.patient_notes
}


export const request_delete_provider = async (id,providerid) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete('/patientprovider/'+providerid)
  let response;
  response = await mainServer.instance.get("/patientprovider/patient/"+id);
  return response.data.data.patient_providers
}

export const request_add_provider = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post('/patientprovider', data)
  let response;
  response = await mainServer.instance.get("/patientprovider/patient/"+id);
  return response.data.data.patient_providers
}

export const request_patient_providers = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/patientprovider/patient/"+id);
  return response.data.data.patient_providers
}

export const request_patient_notes = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/patientnote/patient/"+id);
  return response.data.data.patient_notes
}

export const request_notes = async() => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/patientnote/templates");
  return response.data.data.patient_note_templates
}









export const request_patient_insurance = async (idpatient) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}/current`)
  return response.data.data.patient_insurance
}

export const request_hospital = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/hospital/"+id);
  return response.data.data.hospital
}


export const request_change_approval_status = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = '/patient/'+id+'/approvalstatus'
  await mainServer.instance.post(url, data)
  let response;
  response = await mainServer.instance.get("/patient/"+id);
  return response.data.data.patient
}


export const request_approve_product = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = '/patientproduct/'+id+'/approve'
  await mainServer.instance.post(url)
}

export const request_edit_patient = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/patient/"+id, data)
  let response
  response = await mainServer.instance.get("/patient/"+id);
  return response.data.data.patient
}

export const request_products = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/patientproduct/products")
  return response.data.data.products
}

export const request_diagnostics = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(
    "/patientproduct/diagnostics", 
    {params:{
      productid:id
    }}
  )
  return response.data.data.diagnostics
}

///////////////////////////////  BENEFITS  ///////////////////////////////////////////


export const request_recalculate_benefit = async (id) => {  
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = '/patient/'+id+'/benefit'
  await mainServer.instance.post(url)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}



///////////////////////////////  PATIENT PRODUCTS  ///////////////////////////////////////////

export const request_patientproducts = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}

export const request_edit_patient_product = async (id, product_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch('patientproduct/'+product_id, data)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}

export const request_delete_patient_product = async (id, patient_product_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete('patientproduct/'+patient_product_id)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}

export const request_change_benefit = async (id, data, product_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post( `/patientproduct/${product_id}/manualbenefit`, data)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}

export const request_add_patient_product = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post('patientproduct/', data)
  return response.data.data.patient_product_id
}

export const request_recalculate_benefit_by_product = async (id, idproduct) => {  
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = '/patient/'+id+'/benefit/product/'+idproduct
  await mainServer.instance.post(url)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}




export const request_send_approval = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = '/patient/'+id+'/sendforapproval'
  await mainServer.instance.post(url)
}

export const request_providers = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/provider/all")
  return response.data.data.providers
}

export const request_download_file = async(id, path) => {
  const url = '/patient/'+id+'/'+path
  let response = await mainServer.instance.get(url, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}

export const request_download_provider_letter = async(id) => {
  const url = '/cycle/'+id+'/providerletter'
  let response = await mainServer.instance.get(url, {responseType: 'blob'});
  console.log(response)
  FileSaver.saveAs(response.data, 'provider-letter');
}

export const request_hospitals = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospital/all")
  return response.data.data.hospitals
}

////////////////////////// CYCLES //////////////////////////////////

export const request_add_previous_cycle = async (idpatient,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post('/cycle/custom',data)
  let response;
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
}

export const request_edit_previous_cycle = async (idpatient,idcycle,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/cycle/${idcycle}/custom`,data)
  let response;
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
}

export const request_create_cycle = async (idpatient,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post('/cycle',data)
  let response;
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
}

export const request_update_cycle = async(idpatient, idcycle, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  await mainServer.instance.patch(`/cycle/${idcycle}`, data)
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
}

export const request_delete_cycle = async (idpatient,idcycle) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/cycle/${idcycle}`)
  let response;
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
}

export const request_update_all_cycles = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/patient/${id}/updatecycles`)
  let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
  return response.data.data.patient_products
}


export const request_cycles = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/cycle/patientproduct/"+id);
  return response.data.data.cycles
}





////////////////////////// USES //////////////////////////////////

export const request_update_use = async (idpatient, iduse, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = `/use/${iduse}/update`
  await mainServer.instance.post(url, data)
  let response;
  response = await mainServer.instance.get(`/patientproduct/patient/${idpatient}`)
  return response.data.data.patient_products
  
}