import React from 'react'
import { Grid, Icon, IconButton } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import DisplayText from '../Texts/DisplayText'
import { RoundedButton } from './GeneralButtons'

const SubmitModalButton = props => {

    const {error, loading, onSubmit, label, color, onDelete} = props

    return(
        <div style={{paddingTop:16}}>
            <Grid container justify='flex-end' alignItems='center' spacing={1}>
                {onDelete ? (
                    <Grid item >
                        <IconButton size='small' onClick={onDelete}><Icon>delete</Icon></IconButton>
                    </Grid>
                ) : null}
                <Grid item xs/>
                <Grid item>
                    {error ? (<DisplayText style={{color:red[700]}}>
                        {error}
                    </DisplayText>):null}
                </Grid>
                <Grid item>
                    <RoundedButton  loading={loading} label={label ? label : 'Guardar'} onClick={onSubmit}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default SubmitModalButton