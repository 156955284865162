import React, {useState} from 'react'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import {request_delete_credit_card} from '../../requests'

const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}))

const DeleteCreditCardModal = props => {

    const classes = useStyles()
    const {open, onClose, initData, patientID, onUpdateCreditCards} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async() => {
        const {id_credit_card} = initData
        setLoading(true)
        try {
            const creditcards = await request_delete_credit_card(patientID, id_credit_card)
            setLoading(false)
            onUpdateCreditCards(creditcards)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }

    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='h6'  align='center'>¿Estás seguro?</DisplayText>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='body1' align='center'> ¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido</DisplayText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton label='Eliminar' error={error} loading={loading} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default DeleteCreditCardModal