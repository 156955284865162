import { generalCatalogs } from "../../../../../variables/catalogs";

export const formData = {
    name:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'name',
          type:'text',
          fullWidth: true,
          label:'Nombre del beneficiario',
          placeholder:'Ingrese un nombre...'
        },
        rules:{type:'distance'}
      },
      doctor:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'doctor',
          type:'select',
          fullWidth: true,
          label:'Doctor',
          placeholder:'Escriba el nombre del doctor...'
        },
        rules:{
          type:'none',
        }
      },
      age:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'age',
          type:'number',
          fullWidth: true,
          label:'Edad',
          placeholder:'Ingrese la edad...'
        },
        rules:{type:'distance'}
      },
      gender_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.user_genders],
        config:{
          id:'gender_id',
          type:'select',
          fullWidth: true,
          label:'Género',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'none',
        }
      },
      state_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.patient_states],
        config:{
          id:'state_id',
          type:'select',
          fullWidth: true,
          label:'Entidad',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'none',
        }
      },
      city:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'city',
          type:'text',
          fullWidth: true,
          label:'Ciudad',
          placeholder:'Ingrese la ciudad...'
        },
        rules:{type:'distance'}
      },
      suburb:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'suburb',
          type:'text',
          fullWidth: true,
          label:'Colonia',
          placeholder:'Ingrese la colonia...'
        },
        rules:{type:'distance'}
      },
      zip_code:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'zip_code',
          type:'number',
          fullWidth: true,
          label:'C.P.',
          placeholder:'Ingrese el código postal...'
        },
        rules:{type:'distance'}
      },
      phone:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'phone',
          type:'phone',
          fullWidth: true,
          label:'Teléfono',
          placeholder:'Ingrese un teléfono...'
        },
        rules:{type:'distance'}
      },
      interview_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'interview_date',
          type:'date',
          fullWidth: true,
          label:'Fecha de entrevista',
          placeholder:'Ingrese la fecha...'
        },
        rules:{type:'date'}
      },
      address:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address',
          type:'text',
          fullWidth: true,
          label:'Domicilio',
          placeholder:'Ingrese el domicilio...'
        },
        rules:{type:'distance'}
      },
      insurance_carrier:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'insurance_carrier',
          type:'text',
          fullWidth: true,
          label:'Nombre de la aseguradora',
          placeholder:'Ingrese la aseguradora...'
        },
        rules:{type:'distance'}
      },
      policy_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'policy_value',
          type:'number',
          float:true,
          fullWidth: true,
          label:'Monto de póliza',
          placeholder:'Ingrese el monto...'
        },
        rules:{type:'none'}
      },
}