import { Grid, makeStyles } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import ContraintLayout from '../../../components/Layouts/ConstraintLayout'
import BasicInformationContainer from './components/BasicInformationContainer'
import BusinessContainer from './components/BusinessContainer'
import CarsContainer from './components/CarsContainer'
import CreditCardsContainer from './components/CreditCardsContainer'
import DocumentsContainer from './components/DocumentsContainer'
import IncomeContainer from './components/IncomeContainer'
import AddCarModal from './modals/AddCarModal/AddCarModal'
import AddCreditCardModal from './modals/AddCreditCardModal/AddCreditCardModal'
import DeleteCarModal from './modals/DeleteCarModal/DeleteCarModal'
import DeleteCreditCardModal from './modals/DeleteCreditCardModal/DeleteCreditCardModal'
import DeleteDocumentModal from './modals/DeleteDocumentModal/DeleteDocumentModal'
import EditBasicInfoModal from './modals/EditBasicInfoModal/EditBasicInfoModal'
import EditCarModal from './modals/EditCarModal/EditCarModal'
import EditCreditCardModal from './modals/EditCreditCardModal/EditCreditCardModal'
import EditIncomeModal from './modals/EditIncomeModal/EditIncomeModal'
import EditSingleDataModal from './modals/EditSingleDataModal/EditSingleDataModal'
import UploadDocumentModal from './modals/UploadDocumentModal'
import { request_patient, request_credit_cards, request_cars, request_download_document, request_download_letter, request_calculate_benefit } from './requests'
import {constraints_root as constrainst} from  './constraints'
import nouser from '../../../assets/nouser.png'
import { PATIENT_STATUS } from '../../../variables/config'
import EditDiscountModal from './modals/EditDiscountModal/EditDiscountModal'
import LetterModal from './modals/LetterModal/LetterModal'


const useStyles = makeStyles(theme => ({
  root:{padding:theme.spacing(5)},
  image:{
    width:160,
},
}))

const ApplicantView = props => {

  const classes = useStyles()
  const {history, match} = props

  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState(null)
  const [letter, setLetter] = useState(null)
  const [selectedCC, setSelectedCC] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [patientAttribute, setPatientAttibute] = useState([])
  const [creditCards, setCreditCards] = useState([])
  const [cars, setCars] = useState([])

  //////////////// MODALS /////////////////////
  const [infoModal, setInfoModal] = useState(false)
  const [incomeModal, setIncomeModal] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false)
  const [attributeModal, setAttributeModal] = useState(false)
  const [addCreditCardModal, setAddCreditCardModal] = useState(false)
  const [editCreditCardModal, setEditCreditCardModal] = useState(false)
  const [deleteCreditCardModal, setDeleteCreditCardModal] = useState(false)
  const [addCarModal, setAddCarModal] = useState(false)
  const [editCarModal, setEditCarModal] = useState(false)
  const [deleteCarModal, setDeleteCarModal] = useState(false)
  const [discountModal, setDiscountModal] = useState(false)
  const [letterModal, setLetterModal] = useState(false)


  
  useEffect(() => {
    const initModule = async() => {
      setLoading(true)
      const {id} = match.params
      try {
        let response = await request_patient(id)
        setPatient(response)
        response = await request_credit_cards(id)
        setCreditCards(response)
        response = await request_cars(id)
        setCars(response)
      } catch (error) {
        
      }
      setLoading(false)
    }
    if(JSON.parse(localStorage.getItem('data'))) initModule();
  },[])

  const onPreEditCreditCard = (data) => {
    setSelectedCC(data)
    setEditCreditCardModal(true)
  }

  const onPreDeleteCreditCard = (data) => {
    setSelectedCC(data)
    setDeleteCreditCardModal(true)
  }

  const onPreEditCar = (data) => {
    setSelectedCar(data)
    setEditCarModal(true)
  }

  const onPreDeleteCar = (data) => {
    setSelectedCar(data)
    setDeleteCarModal(true)
  }

  const onUploadDocument = (doc) => {
    setSelectedFile(doc)
    setDocumentModal(true)
  }

  const onDeleteDocument = (doc) => {
    setSelectedFile(doc)
    setDeleteDocumentModal(true)
  }
  
  const onDownloadDocument = async(doc) => {
    try {
      const patientID = match.params.id
      await request_download_document(patientID, doc.id)
    } catch (error) {
      console.log('Hubo un error')
    }
  }

  const onDownloadLetter = async(doc) => {
    console.log(doc)
    setLetter(doc)
    setLetterModal(true)
    try {
      //const patientID = match.params.id
      //await request_download_letter(patientID, doc.id)
    } catch (error) {
      console.log('Hubo un error')
    }
  }

  const onEditSingleData = (data) => {
    let data2send = [data]

    if(data.id === 'test_type_id'){
      data2send.push({id:'manual_test', value: patient.manual_test})
    }
    if(data.id === 'manual_operator_name'){
      data2send.push({id:'manual_operator_contact', value: patient.manual_operator_contact})
      data2send.push({id:'manual_operator_phone', value: patient.manual_operator_phone})
      data2send.push({id:'manual_operator_email', value: patient.manual_operator_email})
    }
    setPatientAttibute(data2send)
    setAttributeModal(true)
  }

  const onReturn = () => {
    history.goBack()
  }

  const onUpdatePatient = async(data) => {
    const {id_patient, patient_status_id} = data
    if(patient_status_id === PATIENT_STATUS.INPROCESS){
      const newData = await request_calculate_benefit(id_patient)
      setPatient(newData)
    }else{
      setPatient(data)
    }
  }

  const onUpdateCreditCards = async(data) => {
    const {id_patient, patient_status_id} = patient
    if(patient_status_id === PATIENT_STATUS.INPROCESS){
      const newData = await request_calculate_benefit(id_patient)
      setPatient(newData)
    }
    setCreditCards(data)
  }

  const onUpdateCars = async(data) => {
    const {id_patient, patient_status_id} = patient
    if(patient_status_id === PATIENT_STATUS.INPROCESS){
      const newData = await request_calculate_benefit(id_patient)
      setPatient(newData)
    }
    setCars(data)
  }




  const patientID = match.params.id

    
  return(
    <BasicView
      isloading={loading}
      content={{
        title:'Detalles del beneficiario',
        navigation:[
          {name:'Beneficiarios',path:'/patients'},
          {name:'Detalles',path:'/patients/'}
        ],
      }}
      returnBtn={true}
      onReturn={onReturn}
    >
      <LetterModal patientID={patientID}  initData={patient} open={letterModal} letter={letter}
      onClose={()=>setLetterModal(false)} onUpdatePatient={(data) => setPatient(data)}/>
      <EditBasicInfoModal patientID={patientID}  initData={patient} open={infoModal} 
      onClose={()=>setInfoModal(false)} onUpdatePatient={(data) => setPatient(data)}/>
      <EditIncomeModal patientID={patientID}  initData={patient} open={incomeModal} 
      onClose={()=>setIncomeModal(false)} onUpdatePatient={onUpdatePatient}/>
      <UploadDocumentModal open={documentModal} onClose={()=>setDocumentModal(false)}
      data={selectedFile} patientID={patientID} onUpdatePatient={(data) => setPatient(data)}/>
      <DeleteDocumentModal open={deleteDocumentModal} patientID={patientID} data={selectedFile}
      onUpdatePatient={(data)=>setPatient(data)} onClose={()=>setDeleteDocumentModal(false)} />
      <EditSingleDataModal patientID={patientID} initData={patientAttribute} open={attributeModal}
      onClose={()=>setAttributeModal(false)} onUpdatePatient={(data) => setPatient(data)}/>
      <EditDiscountModal patientID={patientID}  initData={patient} open={discountModal} 
      onClose={()=>setDiscountModal(false)} onUpdatePatient={(data) => setPatient(data)}/>
      {/*/////////////////////////////////////////
      ///     CREDIT CARDS MODALS
      //////////////////////////////////////////*/}
      <AddCreditCardModal open={addCreditCardModal} patientID={patientID} onUpdateCreditCards={onUpdateCreditCards}
      onClose={()=>setAddCreditCardModal(false)} />
      <EditCreditCardModal open={editCreditCardModal} patientID={patientID} initData={selectedCC}
      onUpdateCreditCards={onUpdateCreditCards} onClose={()=>setEditCreditCardModal(false)} />
      <DeleteCreditCardModal open={deleteCreditCardModal} patientID={patientID} initData={selectedCC}
      onUpdateCreditCards={onUpdateCreditCards} onClose={()=>setDeleteCreditCardModal(false)} />
    

      {/*/////////////////////////////////////////
      ///     VEHICLES MODALS
      //////////////////////////////////////////*/}
      <AddCarModal open={addCarModal} patientID={patientID} onUpdateCars={onUpdateCars}
      onClose={()=>setAddCarModal(false)} />
      <EditCarModal open={editCarModal} patientID={patientID} initData={selectedCar}
      onUpdateCars={onUpdateCars} onClose={()=>setEditCarModal(false)} />
      <DeleteCarModal open={deleteCarModal} patientID={patientID} initData={selectedCar}
      onUpdateCars={onUpdateCars} onClose={()=>setDeleteCarModal(false)} />

      <div>
        <CardWrapper>
          <div className={classes.root}>
            <Grid container spacing={4} justify='center'>
              <Grid item>
                    <div><img src={nouser} alt='' className={classes.image}/></div>
                </Grid>
              <ContraintLayout constrainst={constrainst.basicinfo} md={12}>
                <BasicInformationContainer data={patient} 
                onEdit={() => setInfoModal(true)}/>
              </ContraintLayout>
              <ContraintLayout constrainst={constrainst.income} md={12}>
                <IncomeContainer data={patient}
                onEdit={() => setIncomeModal(true)}/>
              </ContraintLayout>
              <ContraintLayout constrainst={constrainst.creditcards} md={12}>
                <CreditCardsContainer data={creditCards} onAddItem={() => setAddCreditCardModal(true)}
                onEditItem={onPreEditCreditCard} onRemoveItem={onPreDeleteCreditCard}/>
              </ContraintLayout>
              <ContraintLayout constrainst={constrainst.cars} md={12}>
                <CarsContainer data={cars} onAddItem={() => setAddCarModal(true)}
                onEditItem={onPreEditCar} onRemoveItem={onPreDeleteCar}/>
              </ContraintLayout>
              <ContraintLayout constrainst={constrainst.documents} md={12}>
                <DocumentsContainer data={patient} onUploadDocument={onUploadDocument} 
                onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}/>
              </ContraintLayout>
      
              <Grid item xs={12}>
                <BusinessContainer data={patient} onEditItem={onEditSingleData}  onEditDiscount={() => setDiscountModal(true)}
                onUpdatePatient={(data)=>setPatient(data)} onDownloadLetter={onDownloadLetter}/>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>
    </BasicView>
    
  )
}

export default ApplicantView