import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import userimage from '../../../../assets/patient.png'
import DisplayText from '../../../../components/Texts/DisplayText'
import LineInputText from '../../../../components/Inputs/LineInputText/LineInputText';
import ContentText from '../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../components/Inputs/LineInputSelect/LineInputSelect';
import {constrainst_formA as constrainst} from  '../constraints'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout';
import SwitchButton from '../../../../components/Buttons/SwitchButton';
import LineInputDate from '../../../../components/Inputs/LineInputDate';


const useStyles = makeStyles(theme => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:160,
    height:160,
  }
}))

const FormStepA = props => {

    const classes = useStyles()
    const { has_insurance, first_time, input_data, onInputChange, onChangeCheckbox} = props

    //console.log(input_data)

    let form = {...input_data};
    //console.log(form)

    let publicInsuranceContent = null
    if(has_insurance){
      publicInsuranceContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayText variant='subtitle1'>Información adicional</DisplayText>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <LineInputText 
                    data={form.insurance_carrier}
                    onChange={onInputChange}
                  />
                </Grid> 
                <Grid item xs={12} md={6}>
                  <LineInputText 
                    data={form.policy_value}
                    onChange={onInputChange}
                  />
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justify='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={8}>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText text='Información básica' variant='bold' medium/>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <ContraintLayout constrainst={constrainst.name}>
                            <LineInputText 
                              data={form.name}
                              onChange={onInputChange}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.doctor_id}>
                            <LineInputText 
                              data={form.doctor}
                              onChange={onInputChange}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.age}>
                            <LineInputText 
                              data={form.age}
                              onChange={onInputChange}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.gender_id}>
                            <LineInputSelect 
                              data={form.gender_id}
                              onChange={onInputChange}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.state_id}>
                            <LineInputSelect 
                              data={form.state_id}
                              onChange={onInputChange}
                            />
                          </ContraintLayout>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={form.city}
                              onChange={onInputChange}
                            />
                          </Grid> 
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={form.suburb}
                              onChange={onInputChange}
                            />
                          </Grid> 
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={form.zip_code}
                              onChange={onInputChange}
                            />
                          </Grid> 
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <ContentText 
                                  text='Información de entrevista' 
                                  variant='bold'
                                  medium
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={6}>
                                    <LineInputDate 
                                      data={form.interview_date}
                                      onChange={onInputChange}
                                    />
                                  </Grid> 
                                  <Grid item xs={12} md={6}>
                                    <LineInputText 
                                      data={form.phone}
                                      onChange={onInputChange}
                                    />
                                  </Grid> 
                                  <Grid item xs={12}>
                                    <LineInputText 
                                      data={form.address}
                                      onChange={onInputChange}
                                    />
                                  </Grid> 
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                          <Grid item>
                            <SwitchButton 
                              id='first_time' status={first_time} onChange={onChangeCheckbox}
                              message='¿Es la primera vez que el paciente ha tomado éste examen?'/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                          <Grid item>
                            <SwitchButton 
                              id='has_insurance' status={has_insurance} onChange={onChangeCheckbox}
                              message='¿Es paciente de aseguradora?'/>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  {/* ------------------PUBLIC INSURANCE ------------------------- */}
                  {publicInsuranceContent}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
}

export default FormStepA