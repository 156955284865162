import { Icon, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    redButton:{
        background:'transparent',
        color:red[700],
        '&:hover':{
            background:red[100],
        }
    },
    greenButton:{
        background:'transparent',
        color:green[700],
        '&:hover':{
            background:green[100],
        }
    }
}))

const CircularButton = props => {

    const classes = useStyles()
    const {icon, color, size, iconSize, onClick} = props

    return(
        <IconButton className={clsx({
            [classes.button]:true,
            [classes.redButton]:color==='red',
            [classes.greenButton]:color==='green',
        })} size={size} onClick={onClick}>
            <Icon fontSize={iconSize} >{icon}</Icon>
        </IconButton>
    )
}

export default CircularButton