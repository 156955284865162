import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {InputAdornment,TextField,Icon} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey} from '@material-ui/core/colors';

const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[900],
      //fontSize:16,
      //fontWeight:'500'
    },
    '& .MuiInput-underline': {
      borderBottomColor: grey[300],
      borderBottomWidth:2,
      '&:hover:not($disabled):after': {
        //its when its hover and input is focused 
      },
      '&:hover:not($disabled):before': {
        borderBottomWidth:2,
        borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
      },
      '&:before': {
        borderBottomWidth:1,
        borderBottomColor: grey[300],// when input is not touched
      },
    }
  }
}))(TextField);

const LineInputText = props => {

  const {onChange, data, onKeyPress, disabled} = props
  const {value, isVisited, isValid, isRequired} = props.data
  const {id, type,label,placeholder,fullWidth,icon, multiline, rows, helper} = props.data.config

  const onInnerChange = event => {
    let response = event.target.value;
    let temp = {...data};
    if(temp.config.type === 'number'){
      response = temp.config.float ? Number(response).toString() : parseInt(response).toString()
    }
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }
  
  const error = isVisited && !isValid;

  let inputaddornment = null;
  if(icon){
    //console.log(icon)
    inputaddornment = 
    <InputAdornment position='start'>
      
      <Icon style={{color:blueGrey[500]}}>
        {icon.name}
      </Icon>
    </InputAdornment>
  }

  return(
    <CssTextField
      label={label}
        placeholder={placeholder}
        fullWidth={fullWidth ? fullWidth : true}
        disabled={disabled}
        error={error}
        value={value !== undefined && value !== null ? value : ''}
        onChange={onInnerChange}
        multiline={multiline}
        rows={rows}
        type={type}
        helperText={error ? helper : null}
        InputProps={{
          startAdornment: inputaddornment
        }}
        onKeyPress={onKeyPress}
        inputProps={{
          spellCheck:"false",
          autoComplete: id === 'password' ? 'new-password' : "off",
          name:"hidden"
        }}
      />
  )
}

export default LineInputText