import { Grid, makeStyles } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import BasicInformationContainer from './components/BasicInformationContainer'
import ChangePasswordModal from './modals/ChangePasswordModal/ChangePasswordModal'
import EditAgentModal from './modals/EditAgentModal/EditAgentModal'
import PictureModal from './modals/PictureModal/PictureModal'
import { request_user } from './requests'


const useStyles = makeStyles(theme => ({
  root:{padding:theme.spacing(5)}
}))

const AgentView = props => {

  const classes = useStyles()
  const {history, match} = props

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  //////////////// MODALS /////////////////////
  const [infoModal, setInfoModal] = useState(false)
  const [profileModal, setProfileModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)



  
  useEffect(() => {
    const initModule = async() => {
      setLoading(true)
      const {id} = match.params
      try {
        let response = await request_user(id)
        setUser(response)
      } catch (error) {
        
      }
      setLoading(false)
    }
    if(JSON.parse(localStorage.getItem('data'))) initModule();
  },[])

  const onReturn = () => {
    history.goBack()
  }

  const userID = match.params.id

    
  return(
    <BasicView
      isloading={loading}
      content={{
        title:'Detalles del agente',
        navigation:[
          {name:'Agentes',path:'/agents'},
          {name:'Detalles',path:`/agents/${userID}`}
        ],
      }}
      returnBtn={true}
      onReturn={onReturn}
    >
      <ChangePasswordModal open={passwordModal}  userID={userID}
      onClose={()=>setPasswordModal(false)}/>
      <EditAgentModal userID={userID}  initData={user} open={infoModal} 
      onClose={()=>setInfoModal(false)} onUpdateUser={(data) => setUser(data)}/>
      <PictureModal open={profileModal} userID={userID} onUpdateUser={(data) => setUser(data)}
      onClose={()=>setProfileModal(false)}/>

      <div>
        <CardWrapper>
          <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <BasicInformationContainer data={user} onEditPicture={() => setProfileModal(true)}
                onEdit={() => setInfoModal(true)} onChangePassword={()=>setPasswordModal(true)}/>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>
    </BasicView>
    
  )
}

export default AgentView