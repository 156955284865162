import moment from 'moment';

export const ruleValidation = (value, rules, value2) => {

  let response = false;

  if(value !== null){
    switch (rules.type) {
      case 'distance':
        const min = rules.min ? rules.min : 1
        const max = rules.max ? rules.max : 255 
        if(value.length > min-1 && value.length < max + 1){
          response = true;
        }
        break;
      case 'numeric':
        const minN = rules.min ? rules.min : -999999999999999999999999999999999
        const maxN = rules.max ? rules.max : 99999999999999999999999999999 
          if(parseFloat(value) > minN-1 && parseFloat(value) < maxN + 1){
            response = true;
          }
          break;
      case 'email':
        const mailformat = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,4}$/
        if (value.match(mailformat)){
          response = true;
        }
        break;
      case 'equals':
        response = value ===  value2;
        break;
      case 'phone_number':
        if(value.length > 9 && value.length < 13){
          response = true
        }
        break;
      case 'select':
        if(value !== '' && value !== null){
          response = true;
        }else{
          response = false;
        }
        break;
      case 'rfc':
        const rfcFormat = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        if(value.match(rfcFormat)){
          response = true;
        };
        break;
      case 'none':
        response = true;
        break;
      case 'date':
        response = moment(value).isValid();
        break;
      case 'time':
        response = moment(value,"HH:mm").isValid();
        break;
      case 'phone':
        const phoneFormat = /^(?:[0-9] ?){6,14}[0-9]$/;
        if(value.match(phoneFormat)) response = true
        break;
      case 'zip':
        const zipFormat = /^\d{3,5}$/;
        if(value.match(zipFormat)) response = true
        break;
        
      default:
    }
  }

  return response;
};

export const isFormValid = (data) => {
  let isValid = true;
  Object.keys(data).forEach((item,key) => {
    if(data[item].isRequired && !data[item].isValid){
      isValid = false;
    }
  })
  return isValid;
}