import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../variables/config'
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../components/Texts/ContentText'
import {constrainst_basicInfo as constrainst} from  '../constraints'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import { generalCatalogs } from '../../../../../variables/catalogs'
import moment from 'moment'
import { numberWithCommas } from '../../../../../shared/utility'

const BasicInfoContainer = props => {

    const {data, has_insurance, first_time} = props

    


    
    let insuranceContent = null
    let policyValueContent = null

    if(data && has_insurance){
        insuranceContent = (
            <ContraintLayout constrainst={constrainst.insurance_carrier}>
                <HorizontalDataWrapper 
                    label='Aseguradora' text={data.insurance_carrier}/>
            </ContraintLayout>
        )
        policyValueContent = (
            <ContraintLayout constrainst={constrainst.policy_value}>
                <HorizontalDataWrapper 
                    label='Monto de póliza' text={`$ ${data.policy_value ? numberWithCommas(data.policy_value) : '0.00'}`}/>
            </ContraintLayout>
        )
    }

    let gender, state, interviewDate

    if(data){
        const findGender = generalCatalogs.user_genders.find(el => el.value === data.gender_id)
        const findState = generalCatalogs.patient_states.find(el => el.value === data.state_id)
        if(findGender) gender = findGender.label
        if(findState) state = findState.label
        if(data.interview_date){
            let date_temp = moment(data.interview_date)
            if(date_temp.isValid()) interviewDate =  date_temp.format('DD MMM YYYY')
        }
    }




    
    
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                        <Grid item>
                            <ContentText text='Información general' medium variant='bold'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Nombre' text={data ? data.name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.doctor}>
                            <HorizontalDataWrapper 
                                label='Doctor' text={data ? data.doctor : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.age}>
                            <HorizontalDataWrapper 
                                label='Edad' text={data ? data.age : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.gender}>
                            <HorizontalDataWrapper 
                                label='Género' text={gender}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.state}>
                            <HorizontalDataWrapper 
                                label='Estado' text={state}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.city}>
                            <HorizontalDataWrapper 
                                label='Ciudad' text={data ? data.city : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.suburb}>
                            <HorizontalDataWrapper 
                                label='Colonia' text={data ? (data.suburb ? data.suburb : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.zip_code}>
                            <HorizontalDataWrapper 
                                label='C.P.' text={data.zip_code ? data.zip_code : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.interviewDate}>
                            <HorizontalDataWrapper 
                                label='Fecha de entrevista' text={interviewDate}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.interviewDate}>
                            <HorizontalDataWrapper 
                                label='Teléfono' text={data ? data.phone : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.address}>
                            <HorizontalDataWrapper 
                                label='Domicilio' text={data ? data.address : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.first_time}>
                            <HorizontalDataWrapper 
                                label='¿Es primera vez?' text={first_time ? 'Sí' : 'No'}/>
                        </ContraintLayout>
                        {insuranceContent}
                        {policyValueContent}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicInfoContainer