export const formData = {
      letter_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'letter_date',
          type:'date',
          fullWidth: true,
          label:'Fecha',
          placeholder:'Ingrese la fecha...'
        },
        rules:{type:'date'}
      },
      letter_sender_1:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'letter_sender_1',
          type:'text',
          fullWidth: true,
          label:'Línea emisor 1',
          placeholder:'Ingrese información...'
        },
        rules:{type:'distance'}
      },
      letter_sender_2:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'letter_sender_2',
          type:'text',
          fullWidth: true,
          label:'Línea emisor 2',
          placeholder:'Ingrese información...'
        },
        rules:{type:'distance'}
      },
}