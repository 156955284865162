import { USER_TYPE, APPROVAL_STATUS } from "../../../variables/config"

export const constrainst_formA = {
    external_id:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
}


export const constrainst_actionbuttons = {
    doctor_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    edit_button:{ // 4
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }
}