import React from 'react';
import { Grid } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames'
import { palette } from '../../../../variables/config';
import DisplayText from '../../../../components/Texts/DisplayText';

const useStyles = makeStyles(theme => ({
    button:{
        paddingTop:8,
        paddingBottom:8,
        background: fade('#FFF',0.5),
        width:150,
        '&:hover':{
          cursor:'pointer'
        }
    },
    firstButton:{
        borderTopLeftRadius:8,
        borderBottomLeftRadius:8,
    },
    lastButton:{
        borderTopRightRadius:8,
        borderBottomRightRadius:8,
    },
    selectedButton:{
        background:theme.palette.primary.main,
        padding:10,
        borderRadius:32,
        boxShadow:'0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    }
}))

const NavigationMenu = props => {

    const classes = useStyles()
    const { onChange, options, selected} = props

    return(
        <div style={{borderRadius:16}}>
            <Grid container alignItems='center'>
            {options.map((item,key)=>{
                
                const isSelected = selected === item.id

                return(
                <Grid item key={key.toString()}>
                    <div 
                    onClick={() => onChange(item.id)}
                    className={cx({
                    [classes.button]:true,
                    [classes.firstButton]:key===0,
                    [classes.lastButton]:key===(options.length-1),
                    [classes.selectedButton]:isSelected
                    })}>
                        <DisplayText variant={isSelected ? 'subtitle1' : 'body1'}
                        align='center' style={{color:isSelected ? 'white' : palette.primary.main }}>
                            {item.label}
                        </DisplayText>
                    </div>
                </Grid> 
                )
            })}
            </Grid>
        </div>
    )
}


export default NavigationMenu