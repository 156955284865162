export const formData = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
      label:'Nombre',
      placeholder:'Ingrese un nombre...'
    },
    rules:{type:'distance'}
  },
  email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'email',
      type:'email',
      fullWidth: true,
      label:'Email',
      placeholder:'Ej. test@fmi.com',
      helper:'Email no válido'
    },
    rules:{type:'email'}
  },  
}