import React, { Component } from 'react';
import PublicRouters from './routes/router.jsx';
import { createBrowserHistory } from "history";
import { MuiThemeProvider } from '@material-ui/core/styles';
import {theme} from  './theme'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer'

const store = createStore(reducer);


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <PublicRouters history={createBrowserHistory()}/>
        </MuiThemeProvider>
      </Provider>
      
    );
  }
}

export default App;
