import React, {useEffect, useState} from 'react'
import {contentData} from './content'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {request_logs} from './requests'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import UserEventModal from './modals/UserEventModal/UserEventModal'
import PatientEventModal from './modals/PatientEventModal/PatientEventModal'
import ApprovalContactModal from './modals/ApprovalContactModal/ApprovalContactModal'
import CreditCardModal from './modals/CreditCardModal/CreditCardModal'
import CarModal from './modals/CarModal/CarModal'

const Logs = props => {
  const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState([])
    const [totalLogs, setTotalLogs] = useState(10)
    const [logSelected, setLogSelected] = useState(null)

    const [userEventModal, setUserEventModal] = useState(false)
    const [patientEventModal, setPatientEventModal] = useState(false)
    const [approvalContactModal, setApprovalContactModal] = useState(false)
    const [creditCardModal, setCreditCardModal] = useState(false)
    const [carModal, setCarModal] = useState(false)
    
    const [filter, setFilter] = useState({
      status:-1,
      log_event_id:-1,
      search_by:'log_event_id',
      query:''
    })
    const [tableFilter, setTableFilter] = useState({
      limit:10,
      offset:0,
      order:1,
      order_by:'id_log',
    })

    useEffect(() => {
      const onRefreshData = async() => {
        const params = onGetParamsFromState()
        const response = await request_logs(params)
        setLogs(response.logs)
        setTotalLogs(response.count)
        onUpdateURLArgs()
      }
      onRefreshData()
      
    }, [filter, tableFilter])


    useEffect(() => {
      const initModule = async() => {
        setLoading(true)
        let params = ''

        if(location.search){
          //Get params from url
          params = onUpdateStateFromURL()
        }else{
          // Get params from initial state
          params = onGetParamsFromState()
          onUpdateURLArgs()
        }
        try {
          const response = await request_logs(params)
          setLogs(response.logs)
          setTotalLogs(response.count)
        } catch (error) {
          
        }
        setLoading(false)

        
      }
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data) initModule();
    },[])

    const onRequestLogs = async() => {
      try {
        let params = onGetParamsFromState()
        const response = await request_logs(params)
        setLogs(response.logs)
        setTotalLogs(response.count)
      } catch (error) {
        console.log('Hubo un error')
      }
    }


    const onSelectedItem = (item) => {
      
      setLogSelected(item)
      const id = item.log_event_id

      if(id > 0 && id < 7)
        setUserEventModal(true)

      if(id > 6 && id < 15)
        setPatientEventModal(true)

      if(id > 14 && id < 18)
        setApprovalContactModal(true)

      if(id > 17 && id < 21)
        setCreditCardModal(true)
      
      if(id > 20 && id < 24)
        setCarModal(true)

    }

    const onChangeFilter = (tag, value) => { // Change generic filter
      let newFilter = onUpdateFilter(tag, value)
      setFilter(newFilter)
    }

    const onUpdateStateFromURL = () => {
      let params = ''
      const args = queryString.parse(location.search)

      const {limit, offset, order, order_by, status, log_event_id , search_by, query} = args
      let newFilter = {...filter}
      let newTableFilter = {...tableFilter}
      let filterArgs = []

      if(limit !== undefined) newTableFilter.limit = parseInt(limit)
      if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
      if(order !== undefined) newTableFilter.order = parseInt(order)
      if(order_by !== undefined) newTableFilter.order_by = order_by


      if(status !== undefined){
        newFilter.status = parseInt(status)
        filterArgs.push({"field":"status","operator":"LIKE","value":status})
      }
      if(log_event_id !== undefined){
        newFilter.log_event_id = parseInt(log_event_id)
        filterArgs.push({"field":"log_event_id","operator":"LIKE","value":log_event_id})
      }

      newFilter.search_by = search_by
      if(query && query !==''){
        newFilter.query = query
        filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})
      }

      params = {...newTableFilter}
      params = `?${queryString.stringify(params)}`
      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `${params}&filter=${filterArgs}`
      }

      setFilter(newFilter)
      setTableFilter(newTableFilter)

      return params

    }

    const onGetParamsFromState = () => {
      let params2send = {...tableFilter}
      params2send.offset = params2send.offset*params2send.limit
      const {status, log_event_id, search_by, query} = filter

      

      let filterArgs = []
      
      if(status !== -1) filterArgs.push({"field":"status","operator":"LIKE","value":status})
      if(log_event_id !== -1) filterArgs.push({"field":"log_event_id","operator":"LIKE","value":log_event_id})
      if(query && query !=='') filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})

      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        return `?${queryString.stringify(params2send)}&filter=${filterArgs}`
      }else{
        return `?${queryString.stringify(params2send)}`
      }
      
    }

    const onUpdateURLArgs = () => {
      let params = {...tableFilter}
      const {status, log_event_id, search_by, query} = filter
      params.offset = params.offset*params.limit
      if(status !== -1) params = {...params, status:status}
      if(log_event_id !== -1) params = {...params, log_event_id:log_event_id}
      params = {...params,
        search_by:search_by,
        query:query
      }
      const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
      window.history.replaceState(null, null, urlwithargs);
    }

    

    const onUpdateFilter = (id,value) => {
      let temp = {...filter}
      temp[id] = value
      return temp
    }

    const content = contentData['spanish']
    
    return(
      <BasicView content={{
          title: content.layout.title,
          navigation: content.layout.navigation,
      }} isloading={loading}>
        <UserEventModal 
          data={content.user_event_modal} 
          initData={logSelected} 
          open={userEventModal} 
          onClose={() => setUserEventModal(false)}
        />
        <PatientEventModal 
          data={content.patient_event_modal} 
          initData={logSelected}
          open={patientEventModal} 
          onClose={() => setPatientEventModal(false)}
        />
        <ApprovalContactModal 
          data={content.approval_contact_modal} 
          initData={logSelected}
          open={approvalContactModal} 
          onClose={() => setApprovalContactModal(false)}
        />
        <CreditCardModal 
          data={content.credit_card_modal} 
          initData={logSelected}
          open={creditCardModal} 
          onClose={() => setCreditCardModal(false)}
        />
        <CarModal 
          data={content.car_modal} 
          initData={logSelected}
          open={carModal} 
          onClose={() => setCarModal(false)}
        />
        <SimpleTable 
          data={logs} 
          filter={filter}
          tableFilter={tableFilter}
          total={totalLogs}
          header={content.table_header} 
          onChangeFilter={onChangeFilter}
          onUpdateTableFilter={(data) => setTableFilter(data)}
          onSelectedItem={onSelectedItem}/>
      </BasicView>
    )
}

export default Logs