import React from 'react'
import {BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts'
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root:{overflowX: 'auto', margin:'auto', paddingTop:32},
    container:{
        width:'100%',
        height:600,
        [theme.breakpoints.down('md')]: {
            width:1200,

          },
    }
}))

const  StackBarsChart = props => {

    const classes = useStyles()
    const {table,bars}  = props.data 
    const {height, width} = props

    return(
        <div className={classes.root}>
        <Grid container justify='center'>
            <Grid item xs={12}>
                <div className={classes.container} style={{height:height, width:width}}>
                    <ResponsiveContainer>
                        <BarChart  data={table}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name" tick={{fontSize:14}} interval={0} angle={90} textAnchor='start' height={150}/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend />
                                {bars.map((item,key)=>{
                                    return(
                                        <Bar dataKey={item.datakey} stackId="a"  fill={item.fill}  key={key.toString()}/>
                                    )
                                })}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                
                
            </Grid>
        </Grid>
            
        </div>
    )
}

export default StackBarsChart