import React, {useState} from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { deepPurple, green, indigo, lightBlue, red, teal } from '@material-ui/core/colors'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import {constrainst_statusbar as constrainst} from  '../constraints'
import { PATIENT_STATUS, USER_TYPE } from '../../../../variables/config'
import { isDocumentSectionCompleted } from '../customFunctions'
import SimpleLoader from '../../../../components/Loaders/SimpleLoader'
import DisplayText from '../../../../components/Texts/DisplayText'
import {request_send_for_approval, request_update_patient_status} from '../requests'
import { getUserType } from '../../../../shared/utility'


const StatusActionBar = props => {

    const {patient, onUpdatePatient} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const userType = getUserType()
    let status
    if(patient){
        status = patient.patient_status_id
    }

    const onChangeStatus = async(nextStatus) => {
        console.log(nextStatus)
        let isValid = true
        let errorText = null
        const {patient_status_id} = patient

        switch (nextStatus) {
            case PATIENT_STATUS.PREAUTHORIZED:
                if(patient_status_id === PATIENT_STATUS.INPROCESS){
                    isValid = isDocumentSectionCompleted(patient)
                    errorText = isValid ? null : 'Faltan subir documentos'
                }
                break;
        
            default:
                break;
        }
        setError(errorText)
        //console.log(isValid)
        if(isValid){
            setLoading(true)
            try {
                if(nextStatus === PATIENT_STATUS.PREAUTHORIZED && patient.patient_status_id === PATIENT_STATUS.INPROCESS) await request_send_for_approval(patient.id_patient)
                const newPatient = await request_update_patient_status(patient.id_patient, {patient_status_id:nextStatus})
                onUpdatePatient(newPatient) 
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError('Hubo un error en la peticion')
                console.log(error.response)
            }

            
        }
    }

    let  authorizedButton = <ContraintLayout  auto status={status} constrainst={constrainst.authorized} >
        <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.AUTHORIZED}/>
    </ContraintLayout>

    if(userType === USER_TYPE.REGISTRANT && status === PATIENT_STATUS.PREAUTHORIZED) authorizedButton = null

    let  preauthorizedButton =  <ContraintLayout  auto status={status} constrainst={constrainst.preauthorized}>
        <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.PREAUTHORIZED}/>
    </ContraintLayout>

    if(userType === USER_TYPE.REGISTRANT && status === PATIENT_STATUS.AUTHORIZED) preauthorizedButton = null

    return(
        <div>
            <div>
               {error ? ( 
                <DisplayText align='center' style={{color:red[700]}}>{error}</DisplayText>
               ) : null} 
            </div>
            <Grid container justify='flex-end' spacing={2} alignItems='center'>
                {loading ? <Grid item> <SimpleLoader /> </Grid> : null}
                <ContraintLayout  auto status={status} constrainst={constrainst.inprocess}>
                    <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.INPROCESS}/>
                </ContraintLayout>
                {preauthorizedButton}
                <ContraintLayout  auto status={status} constrainst={constrainst.no_authorized}>
                    <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.REJECTED}/>
                </ContraintLayout>
                {authorizedButton}
                <ContraintLayout  auto status={status} constrainst={constrainst.acquired}>
                    <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.ACDQUIRED}/>
                </ContraintLayout>
                <ContraintLayout  auto status={status} constrainst={constrainst.reported}>
                    <StatusButton onClick={onChangeStatus} status={PATIENT_STATUS.REPORTED}/>
                </ContraintLayout>
            </Grid>
        </div>
    )
}

export default StatusActionBar

const useStyles4Button = makeStyles(theme => ({
    button:{
        textTransform:'none',
        padding:'8px 40px',
        fontSize:16,
        borderRadius:32,
    },
    inprocess:{
        color:'white',background:lightBlue[700], '&:hover':{background:lightBlue[900]}
    },
    pre_authorized:{
        color:'white',background:teal[700], '&:hover':{background:teal[900]}
    },
    authorized:{
        color:'white',background:green[700], '&:hover':{background:green[900]}
    },
    rejected:{
        color:'white',background:red[700], '&:hover':{background:red[900]}
    },
    acdquired:{
        color:'white',background:indigo[700], '&:hover':{background:indigo[900]}
    },
    no_acdquired:{
        color:'white',background:red[700], '&:hover':{background:red[900]}
    },
    reported:{
        color:'white',background:deepPurple[700], '&:hover':{background:deepPurple[900]}
    },
    no_reported:{
        color:'white',background:red[700], '&:hover':{background:red[900]}
    },
}))

const StatusButton = (props) => {

    const classes = useStyles4Button()
    const {status, onClick} = props

    const statusEl = statusMenu.find(el => el.id === status)

    return(
        <Button onClick={() => onClick(status)} className={cx({
            [classes.button]:true,
            [classes.inprocess]:status === PATIENT_STATUS.INPROCESS,
            [classes.pre_authorized]:status === PATIENT_STATUS.PREAUTHORIZED,
            [classes.authorized]:status === PATIENT_STATUS.AUTHORIZED,
            [classes.rejected]:status === PATIENT_STATUS.REJECTED,
            [classes.acdquired]:status === PATIENT_STATUS.ACDQUIRED,
            [classes.no_acdquired]:status === PATIENT_STATUS.NOADCQUIRED,
            [classes.reported]:status === PATIENT_STATUS.REPORTED,
            [classes.no_reported]:status === PATIENT_STATUS.NOREPORTED,
        })}>
            {statusEl ? statusEl.label : 'No action'}
        </Button>
    )
}

const statusMenu = [
    {id:1, label:'En Proceso', color:lightBlue[700]},
    {id:2, label:'Pre-Autorizado', color:teal[700]},
    {id:3, label:'Autorizado', color:green[700]},
    {id:4, label:'Rechazado', color:red[700]},
    {id:5, label:'Adquirido', color:indigo[700]},
    {id:6, label:'Reportado', color:deepPurple[700]},
]