import React from 'react'
import { Button, fade, makeStyles } from '@material-ui/core'

const useStyes = makeStyles(theme => ({
    button:{
        border:`2px solid ${theme.palette.primary.main}`,
        background:'white',
        padding:'8px 40px',
        borderRadius:32,
        textTransform:'none',
        '&:hover':{
            background: fade(theme.palette.primary.main,0.25)
        }
    }
}))

const OutlinedButton = props => {
    
    const classes = useStyes()
    const {label, onClick} = props


    return(
        <Button className={classes.button} onClick={onClick}>
            {label}            
        </Button>
    )
}

export default OutlinedButton