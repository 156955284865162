import Agents from "../views/Agents/Agents/Agents";
import AgentView from "../views/Agents/AgentView/AgentView";
import Analytics from "../views/Analytics/Analtytics/Analtytics";
import AddApplicant from "../views/Applicants/AddApplicant/AddApplicant";
import Applicants from "../views/Applicants/Applicants/Applicants"
import ApplicantView from "../views/Applicants/ApplicantView/ApplicantView";
import Emails from "../views/Emails/Emails/Emails";
import ProfileView from "../views/Profile/ProfileView/ProfileView";
import Logs from '../views/Logs/Logs/Logs'
import { USER_TYPE } from "../variables/config";

const dashboard = {
    sections:[
        {
            path: "/agents/:id",
            icon:'supervisor_account',
            label:'Agentes',
            component: AgentView,
            sidebar:false,
            exact:true,
            constraints:[USER_TYPE.ADMIN],
        },
        {
            path: "/agents",
            icon:'person',
            label:'Agentes',
            component: Agents,
            sidebar:true,
            constraints:[USER_TYPE.ADMIN],
        },
        {
            path: "/patients/add",
            icon:'supervisor_account',
            label:'Pacientes',
            component: AddApplicant,
            sidebar:false,
            exact:true,
        },
        {
            path: "/patients/:id",
            icon:'supervisor_account',
            label:'Pacientes',
            component: ApplicantView,
            sidebar:false,
            exact:true,
        },
        {
            path: "/patients",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: Applicants,
            sidebar:true,
        },
        {
            path: "/emails",
            icon:'contact_mail',
            label:'Correos',
            component: Emails,
            sidebar:true,
            constraints:[USER_TYPE.ADMIN],
        },
        {
            path: "/analytics",
            icon:'insert_chart',
            label:'Analíticos',
            component: Analytics,
            sidebar:true,
            constraints:[USER_TYPE.ADMIN, USER_TYPE.ROCHE, USER_TYPE.AUDITOR],
        },
        {
            path: "/profile",
            component: ProfileView,
            sidebar:false,
        },
        {
            path: "/logs",
            icon:'schedule',
            label:'Logs',
            component: Logs,
            sidebar:true,
            constraints:[USER_TYPE.ADMIN],
        },
        
        { redirect: true, path: "/", to: "/patients", navbarName: "Redirect" }
    ]
}

export default dashboard;