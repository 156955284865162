import React from 'react'
import SimpleDialog from '../../../../components/Modals/SimpleDialog'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { green } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons'


const useStyles = makeStyles(theme => ({
    icon:{
        color:green[700],
        borderRadius:'50%',
        border:`2px solid ${green[700]}`,
        fontSize:64,
    }
}))

const SuccessModal = (props) => {

    const classes = useStyles()
    const {onFinished} = props

    return(
        <SimpleDialog 
            data={{}} 
            hideSubmitBtn 
            hideCancelBtn
            maxWidth='xs'>
            <div >
                <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
                    <Grid item><Icon className={classes.icon}>done</Icon></Grid>
                    <Grid item>
                        <DisplayText variant='h5'>
                            ¡Acción exitosa!
                        </DisplayText>
                    </Grid>
                    <Grid item>
                        <DisplayText>
                            La propuesta fue enviada para validación
                        </DisplayText>
                    </Grid>
                    <Grid item>
                        <div style={{paddingTop:16}}>
                        <RoundedButton label='Continuar' size='small' onClick={onFinished}/>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </SimpleDialog>
    )
}

export default SuccessModal