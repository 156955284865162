
import React from 'react'
import { TextField, Grid, makeStyles, withStyles, Typography } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import Caption from '../../Texts/Caption'
import Autocomplete from "@material-ui/lab/Autocomplete";
import ContentText from '../../Texts/ContentText'
import DisplayText from '../../Texts/DisplayText';


const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[900],
      //fontSize:14,
      //fontWeight:'500',
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
    },
    '& .MuiInput-underline': {
      borderBottomColor: grey[300],
      borderBottomWidth:2,
      '&:hover:not($disabled):after': {
        //its when its hover and input is focused 
      },
      '&:hover:not($disabled):before': {
        borderBottomWidth:2,
        borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
      },
      '&:before': {
        borderBottomWidth:1,
        borderBottomColor: grey[300],// when input is not touched
      },
    }
  },
}))(TextField);

const SearchInputSelect = props => {

    ///////////////////////////////////
    const {data, onChange } = props //onSearch
    const {isValid, isVisited, value, options, disabled} = data;
    const {label,placeholder, helper} = data.config;

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        temp.value = item.value
        temp.isValid = true
      }else{
        temp.value = null
        temp.isValid = false
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if(data) {
      if(options) selectedOptions = options
      if(value !== null && value !== undefined) selectedValue = options.find(el => el.value === value)      
    }
    

    let inputlabel = null;
    if(label) inputlabel = <Grid item xs={12}><Caption text={label} medium /></Grid>
    return(
      <Autocomplete 
      disabled={disabled}
      value={selectedValue}
      options={selectedOptions}
      getOptionLabel={option => option.label}
      renderInput={params => (<CssTextField {...params} error={!isValid && isVisited} label={label} placeholder={placeholder} 
        helperText={(!isValid && isVisited) ? helper : null}/>)}
      onChange={onInnerChange}
      noOptionsText={<DisplayText>Sin opciones </DisplayText>}
      renderOption={(option, state) => 
        <div>
          <DisplayText variant={state.selected ? 'subtitle1' : 'body1'}>{option.label}</DisplayText>
        </div>
      }/>
    )
}

export default SearchInputSelect

/*
<Grid container >
                {inputlabel}
                <Grid item xs={12}>
                    <Autocomplete 
                      disabled={disabled}
                      
                      value={selectedValue}
                      options={selectedOptions}
                      getOptionLabel={option => option.label}
                      renderInput={params => (<CssTextField {...params} placeholder={placeholder}/>)}
                      onChange={onInnerChange}
                      noOptionsText={<ContentText text={'Sin opciones'}/>}
                      renderOption={(option, state) => <div><ContentText label={label} text={option.label} variant={state.selected ? 'bold' : undefined}/></div>}  />
                </Grid>
                <Grid item>
                  <Typography variant='caption' style={{color:red[700]}}>
                    {isVisited && !isValid ? helper : ''}
                  </Typography>
                </Grid>
            </Grid>
*/