import React from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import DisplayText from '../Texts/DisplayText'
import { deepPurple, green, grey, indigo, lightBlue, red, teal } from '@material-ui/core/colors'

const colorMenu = [
    lightBlue[700], // En  proceso
    teal[700], // Pre-autorizado
    green[700], // Autorizado
    red[700], // Rechazado
    indigo[700], // Adquirido
    red[700], // No adquirido
    deepPurple[700], // Reportado
    red[700], // No reportado
]

const useStyles = makeStyles({
    icon:{
        fontSize:14,
        marginTop:4,
    }
})

const StatusLabel = props => {

    const classes = useStyles()
    const {status, status_id} = props

    

    return(
        <div>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Icon className={classes.icon} style={{color: status_id ? colorMenu[status_id-1] : grey[500]}}>brightness_1</Icon>
                </Grid>
                <Grid item>
                    <DisplayText>{status}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}

export default StatusLabel