import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root:{}
}))

const DisplayText = props => {

    const classes = useStyles()

    return(
        <Typography {...props}>
            {props.children}
        </Typography>
    )
}

export default DisplayText