import React from 'react'
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { generalCatalogs } from '../../../../../../variables/catalogs'
import { nodata } from '../../../../../../variables/config'
import CircularButton from '../../../../../../components/Buttons/CircularButton'
import DisplayText from '../../../../../../components/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:16,
        marginBottom:16,
        border:`1px solid ${grey[300]}`,
        borderRadius:12,
        boxSizing:'border-box',
        paddingRight:8
    },
    cell:{
        borderColor:'white',
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:'white',
        //background:grey[100],
        paddingTop:12,
        paddingBottom:0
    },
    nodata:{
        padding:16
    }
}))

const CreditCardTable = props => {

    const classes = useStyles()
    const  {data, onRemoveItem} = props 


    let contentView = null


    if(data && data.length){
        contentView = (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cellHeader}><Typography variant='subtitle2'>Nombre</Typography></TableCell>
                        <TableCell className={classes.cellHeader}><Typography variant='subtitle2'>Rango</Typography></TableCell>
                        <TableCell className={classes.cellHeader} padding='checkbox' ><Typography variant='subtitle2'>Acciones</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item,key)=>{

                        const creditRange = generalCatalogs.credit_ranges.find(el => el.id === item.credit_range_id)

                        return(
                            <TableRow key={key.toString()}>
                                <TableCell className={classes.cell}><Typography>{item.name}</Typography></TableCell>
                                <TableCell className={classes.cell}><Typography>{creditRange ? creditRange.label : nodata}</Typography></TableCell>
                                <TableCell className={classes.cell} padding='checkbox'>
                                    <CircularButton onClick={() => onRemoveItem(item)}
                                    size='small' icon='remove' iconSize='small' color='red'/> 
                                </TableCell>
                            </TableRow>
                        )

                        
                    })}
                </TableBody>
            </Table>
        )
    }else{
        contentView = (
            <div className={classes.nodata}>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText align='center'>No existe tarjetas registradas</DisplayText>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return(
        <div className={classes.root}>
            {contentView}
        </div>

    )
}

export default CreditCardTable