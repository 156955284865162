import React from 'react'
import { Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import CircularButton from '../../../../components/Buttons/CircularButton'

const searchCatalog = [{id:1, value:'name', label:'Nombre '},{id:2, value:'email', label:'Email'}]

const header = [
    {id:1, label:'ID'},
    {id:2, label:'Nombre'},
    {id:3, label:'Email'},
    {id:4, label:'Acciones', padding:'checkbox'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        paddingRight:8,
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        //'&:hover':{
        //    cursor:'pointer',
        //    background:grey[100]
        //}
    },
    cellHeader:{
        borderColor:grey[200],
        paddingTop:12,
        paddingBottom:8
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onEditItem, onRemoveItem} = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <FilterButton label='Buscar por' onChange={(id) => onChangeFilter('search_by', id)}
                                options={searchCatalog} selected={filter.search_by}/>
                            </Grid>
                            <Grid item><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.padding ? item.padding : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_approval_contact, name, email} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row}>
                                            <TableCell className={classes.cell}><DisplayText>{id_approval_contact}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{name}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText >{email}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} padding='checkbox'>
                                                <Grid container spacing={1} wrap='nowrap' >
                                                    <Grid item>
                                                        <CircularButton onClick={() => onEditItem(item)}
                                                        size='small' icon='edit' iconSize='small' color='green'/> 
                                                    </Grid>
                                                    <Grid item>
                                                        <CircularButton onClick={() => onRemoveItem(item)}
                                                        size='small' icon='delete' iconSize='small' color='red'/> 
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div>
        </div>
    )
}

export default SimpleTable