import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import HorizontalDataWrapper from '../../../../components/Texts/HorizontalDataWrapper'
import { nodata } from '../../../../variables/config'
import {constrainst_basic_information as constrainst} from  '../constraints'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons'
import moment from 'moment'
import { getComponentLogInfo, numberWithCommas } from '../../../../shared/utility'
import InfoButton from '../../../../components/Buttons/InfoButton'


const useStyles = makeStyles(theme => ({
    image:{
        width:160,
    },
    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    }
}))

const BasicInformationContainer = props => {

    const classes = useStyles()
    const {data, onEdit} = props


    let insuranceContent = null
    let policyValueContent = null

    let interviewDate
    let first_time

    if(data && data.has_insurance){
        insuranceContent = (
            <ContraintLayout constrainst={constrainst.insurance_carrier}>
                <HorizontalDataWrapper 
                    label='Aseguradora' text={data.insurance_carrier}/>
            </ContraintLayout>
        )
        policyValueContent = (
            <ContraintLayout constrainst={constrainst.policy_value}>
                <HorizontalDataWrapper 
                    label='Monto de póliza' text={`$ ${data.policy_value ? numberWithCommas(data.policy_value) : '0.00'}`}/>
            </ContraintLayout>
        )
    }

    if(data){
        let datetemp = moment(data.interview_date)
        if(datetemp.isValid) interviewDate = datetemp.format('DD MMM YYYY')
        first_time = data.first_time ? 'Sí' : 'No'
    }

    const infoData = getComponentLogInfo(data)

    return(
        <div>
            <Grid container justify='center' spacing={3}>
                
                <Grid item xs={12}>
                    
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <DisplayText variant='subtitle1'>Información principal</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <InfoButton data={infoData}/>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                            <div className={classes.container}>
                                <Grid container spacing={3}>
                                    <ContraintLayout constrainst={constrainst.name}>
                                        <HorizontalDataWrapper 
                                            label='Nombre' text={data ? data.name : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.doctor}>
                                        <HorizontalDataWrapper 
                                            label='Doctor' text={data ? data.doctor : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.age}>
                                        <HorizontalDataWrapper 
                                            label='Edad' text={data ? data.age : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.gender}>
                                        <HorizontalDataWrapper 
                                            label='Género' text={data ? data.gender : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.state}>
                                        <HorizontalDataWrapper 
                                            label='Estado' text={data ? data.state : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={data ? data.city : nodata}>
                                        <HorizontalDataWrapper 
                                            label='Ciudad' text={data ? data.city : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.suburb}>
                                        <HorizontalDataWrapper 
                                            label='Colonia' text={data ? data.suburb : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.zip_code}>
                                        <HorizontalDataWrapper 
                                            label='C.P.' text={data ? data.zip_code : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.interviewDate}>
                                        <HorizontalDataWrapper 
                                            label='Fecha de entrevista' text={interviewDate}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.phone}>
                                        <HorizontalDataWrapper 
                                            label='Teléfono' text={data ? data.phone : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.address}>
                                        <HorizontalDataWrapper 
                                            label='Domicilio' text={data ? data.address : nodata}/>
                                    </ContraintLayout>
                                    <ContraintLayout constrainst={constrainst.first_time}>
                                        <HorizontalDataWrapper 
                                            label='¿Es primera vez?' text={first_time}/>
                                    </ContraintLayout>
                                    {insuranceContent}
                                    {policyValueContent}
                                    <ContraintLayout constrainst={constrainst.edit_button} md={12}>
                                        <Grid container justify='flex-end'>
                                            <Grid item>
                                                <RoundedButton label={'Editar'} onClick={onEdit}/>
                                            </Grid>
                                        </Grid>
                                    </ContraintLayout>  
                                </Grid>
                            </div>
                            </Grid>
                        </Grid>
                    
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicInformationContainer