import React, {useState} from 'react'
import { IconButton, Icon, Grid } from '@material-ui/core';
import PopperWrapper from '../Poppers/PopperWrapper';
import Caption from '../Texts/Caption';

/*

infoButtonData = [
        {
          title:'Producto aprobado',
          content:[
            {label:'Nombre: ', text: data.approver},
            {label:'Fecha: ', text: data.approved_date},
          ]
        },
        {
          title:'Cambio manual',
          content:[
            {label:'Nombre: ', text: data.manual_changer},
          ]
        }
      ]

*/

const InfoButton = props => {

  const [anchorEl, setAnchorEl] = useState(null)
  const {data} = props
    return(
      <div>
        <PopperWrapper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClickAway={() => setAnchorEl(null)}
        >
          <div style={{padding:8, width:'200px'}}>
            <Grid container spacing={2}>
              {data.map((item,key)=>{
                return(
                  <Grid item xs={12} key={key.toString()}>
                    <Grid container >
                      <Grid item xs={12}>
                        <Caption medium text={item.title} variant='bold'/>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {item.content.map((el,id)=>{
                            return(
                              <Grid item xs={12} key={id.toString()}>
                                <Grid container key={id.toString()} spacing={1} wrap='nowrap'>
                                  <Grid item>
                                    <Caption medium text={el.label}  variant='light'/>
                                  </Grid>
                                  <Grid item>
                                    <Caption medium text={el.text} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                       
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </PopperWrapper>
        <IconButton 
          size='small' 
          color='primary' 
          style={{padding:0}}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Icon>info</Icon>
        </IconButton>
      </div>
    )
 
}

export default InfoButton