import * as actionTypes from './actions'

const initialState = {
    user:null,
    isauth:false,
    language:'spanish'

}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_CHANGE_STATUS:
            return{
                ...state,
                isauth:action.isauth,
            }
        case actionTypes.AUTH_UPDATE_USER:
            return{
                ...state,
                user:action.user,
            }
        default:
            return state;

    }
}

export default reducer;