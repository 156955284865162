import {mainServer} from '../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_me = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/user/me`);
  return response.data.data.user
}

export const request_edit_user = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/user/me`, data);
  let response = await mainServer.instance.get(`/user/me`);
  return response.data.data.user
}

export const request_upload_image = async(file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/user/me/image`, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.upload_url, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/user/me`);
  return response.data.data.user
}

export const request_delete_image = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/user/me/image`)
  let response;
  response = await mainServer.instance.get(`/user/me`);
  return response.data.data.user
}
