import React, {useState} from 'react'
import { Avatar, fade, Grid, Icon, makeStyles } from '@material-ui/core'
import nouser from '../../../../assets/nouser.png'
import { nodata } from '../../../../variables/config'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons'
import VerticalDataWrapper from '../../../../components/Texts/VerticalDataWrapper'
import { getComponentLogInfo, isValidDocument } from '../../../../shared/utility'
import { imgServerS3 } from '../../../../variables/config'
import OutlinedButton from '../../../../components/Buttons/OutlinedButton'
import InfoButton from '../../../../components/Buttons/InfoButton'


const useStyles = makeStyles(theme => ({
    image:{
        width:160,
        height:160,
    },
    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    avatarContainer:{
        position:'relative'
    },
    overlay:{
        position:'absolute',
        top:0,
        left:0,
        background: fade('#000',0.5),
        zIndex:2,
        width:'100%',
        height:'100%',
        borderRadius:'50%',
        '&:hover':{
            cursor:'pointer'
        }
    },
    picture:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    }
}))

const BasicInformationContainer = props => {

    const classes = useStyles()
    const {data, onEdit, onEditPicture, onChangePassword} = props
    const [hover, setHover] = useState(false)

    let selectedImage = nouser

    if(data){
        const isValid = isValidDocument(data.image)
        selectedImage = isValid ? `${imgServerS3}${data.image}` : nouser
    }

    const infoData = getComponentLogInfo(data)


    return(
        <div>
            <Grid container justify='center' spacing={3}>
                <Grid item>
                    <div onClick={onEditPicture} className={classes.avatarContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                        {hover ? (<div className={classes.overlay}>
                            <div className={classes.picture}>
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item>
                                        <Icon style={{color:grey[300]}} fontSize='large'>photo_camera</Icon>
                                    </Grid>
                                    <Grid item>
                                        <DisplayText style={{color:grey[300]}}>Editar</DisplayText>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>) : null}
                        <Avatar src={selectedImage} alt='' className={classes.image}/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <DisplayText variant='subtitle1'>Información general</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <InfoButton data={infoData}/>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                            <div className={classes.container}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Nombre(s)' text={data ? data.first_name : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Apellido(s)' text={data ? data.last_name : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Usuario' text={data ? data.username : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Tipo de usuario' text={data ? data.user_type : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Email' text={data ? data.email : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Celular' text={data ? data.mobile : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Teléfono' text={data ? data.phone : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Extensión' text={data ? data.ext : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Género' text={data ? data.gender : nodata}/>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Zona horaria' text={data ? data.timezone : nodata}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalDataWrapper 
                                            label='Estatus' text={data ? (data.status ? 'Activo' : 'Inactivo') : nodata}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justify='flex-end' spacing={2}>
                                            <Grid item>
                                                <OutlinedButton label='Cambiar de contraseña' onClick={onChangePassword}/>
                                            </Grid>
                                            <Grid item>
                                                <RoundedButton label='Editar' onClick={onEdit}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            </Grid>
                        </Grid>
                    
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicInformationContainer