import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper'
import PercentageTable from '../../../components/PercentageTable'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{padding:32},
    text:{
        padding:'8px 16px',
        color:blueGrey[500]
    }
}))

const DependantStats = props => {
    const classes = useStyles()
    const {data} = props

    const footer = (
        <div style={{paddingTop:8}}>
            <DisplayText className={classes.text}>#n= numeros de encuestados que reportaron la categoria</DisplayText>
            <DisplayText className={classes.text}># = total de dependientes por categoria</DisplayText>
        </div>
    )

    return(
        <CardWrapper>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>DEPENDIENTES ECONÓMICOS</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <PercentageTable data={data.table} nopercentage
                        footer={footer}/>
                    </Grid>
                </Grid>
            </div>
        </CardWrapper>
    )
}

export default DependantStats