import React, {useState, useEffect} from 'react'
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import {constrainst_basic_information as constrainst} from  '../constraints'
import DisplayText from '../../../../components/Texts/DisplayText'
import { blueGrey, grey } from '@material-ui/core/colors'
import { isValidDocument } from '../../../../shared/utility'

const docfiles = [
    {id:'privacy_act', title:'Aviso de privacidad', status:false},
    {id:'payroll_receipt', title:'Recibo de nómina', status:false},
    {id:'rent_payment_receipt', title:'Recibo de último pago de renta', status:false},
    {id:'property_ticket', title:'Boleta predial (en caso de ser casa propia)', status:false},
    {id:'insurance_policy', title:'Póliza actualizada del seguro de Gastos Médicos Mayores', status:false},
]


const useStyles = makeStyles(theme => ({
    image:{
        width:160,
    },
    container:{
        padding:32,
        
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    subtitle:{
        color:blueGrey[500]
    }
}))

const DocumentsContainer = props => {

    const classes = useStyles()
    const {data, onUploadDocument, onDownloadDocument, onDeleteDocument} = props
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(docfiles)))

    useEffect(() => {
        if(!data) return
        let temp = [...files]
        files.forEach((item,key) => {
            const isValid = isValidDocument(data[item.id])
            temp[key].status = isValid
            temp[key].url = data[item.id]
        })
        setFiles(temp)
    }, [data])

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Documentos</DisplayText>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.container}>
                    <Grid container spacing={4}>
                        {files.map((item,key)=>{
                            return(
                                <Grid item xs={12} key={key.toString()}>
                                    <div>
                                        <Grid container spacing={1}  wrap='nowrap' alignItems='center'>
                                            <Grid item xs>
                                                <DisplayText>{item.title}</DisplayText>
                                            </Grid>
                                            <Grid item>
                                                <IconButton color='primary' size='small' onClick={() => onUploadDocument(item)} ><Icon>cloud_upload</Icon> </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton color='primary' size='small' disabled={!item.status} onClick={() => onDownloadDocument(item)} ><Icon>cloud_download</Icon> </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton  size='small'  disabled={!item.status}  onClick={() => onDeleteDocument(item)}><Icon fontSize='small'>delete</Icon></IconButton>
                                            </Grid>
                                            
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DocumentsContainer