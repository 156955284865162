import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from "../../../variables/config"

export const constrainst_basic_information = {
    
}

export const constrainst_benefit = {
    
}

export const constraints_root = {
    basicinfo:{
        byuser:[
            USER_TYPE.REGISTRANT
        ]
    },
    income:{
        byuser:[
            USER_TYPE.REGISTRANT
        ]
    },
    creditcards:{
        byuser:[
            USER_TYPE.REGISTRANT
        ]
    },
    cars:{
        byuser:[
            USER_TYPE.REGISTRANT
        ]
    },
    documents:{
        byuser:[
            USER_TYPE.REGISTRANT
        ]
    },
}

export const constrainst_business = {
    documents:{
        bystatus:[
            PATIENT_STATUS.NOREPORTED,
            PATIENT_STATUS.REPORTED,
            PATIENT_STATUS.AUTHORIZED,
            PATIENT_STATUS.ACDQUIRED,
            PATIENT_STATUS.NOADCQUIRED,
        ],
        byuser:[
            USER_TYPE.REGISTRANT,
        ]
    },
    statusbar:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.REGISTRANT,
        ]
    },
    discount:{
        byuser:[
            USER_TYPE.ADMIN,
        ]
    }

}


export const constrainst_statusbar = {
    inprocess:{
        bystatus:[
            PATIENT_STATUS.PREAUTHORIZED
        ]
    },
    preauthorized:{
        bystatus:[
            PATIENT_STATUS.INPROCESS,
            PATIENT_STATUS.AUTHORIZED,
            PATIENT_STATUS.REJECTED,
        ]
    },
    no_authorized:{
        bystatus:[
            PATIENT_STATUS.PREAUTHORIZED,
        ],
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE
        ]
    },
    authorized:{
        bystatus:[
            PATIENT_STATUS.PREAUTHORIZED,
            PATIENT_STATUS.ACDQUIRED,
            PATIENT_STATUS.NOADCQUIRED,
            PATIENT_STATUS.REJECTED,
        ],
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.REGISTRANT,
        ]
    },
    no_acquired:{
        bystatus:[
            //PATIENT_STATUS.AUTHORIZED,
            -1
        ]
    },
    acquired:{
        bystatus:[
            PATIENT_STATUS.AUTHORIZED,
            PATIENT_STATUS.REPORTED,
            PATIENT_STATUS.NOREPORTED,
            PATIENT_STATUS.NOADCQUIRED
        ]
    },
    no_reported:{
        bystatus:[
            //PATIENT_STATUS.ACDQUIRED,
            -1
        ]
    },
    reported:{
        bystatus:[
            PATIENT_STATUS.ACDQUIRED,
            PATIENT_STATUS.NOREPORTED
        ]
    },
}



export const constrainst_header = {
    header:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ]
    },
    dismissed_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
        ]
    },
    preregister:{
    },
    approve_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
        ]
    },
    disapprove_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO
        ]
    },
    reactive_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO
        ]
    }

}

export const constrainst_basicInfo = {
    id_patient:{
        byuser:[
            USER_TYPE.HOSPITAL,
        ]
    },
    external_id:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.PRA
        ]
    },
    folio:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.PRA
        ]
    },
    name:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    },
    email:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    },
    phone:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    }
}

export const constrainst_hospitalInfo = {  
}

export const constrainst_insurance = {
}

export const constrainst_statuses = {
    inactive_reason:{
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ]
    }
}

export const constrainst_actionbuttons = {
    doctor_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    edit_button:{ // 4
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }
}