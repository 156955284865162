import React from 'react'
import SimpleDialog from '../../../../components/Modals/SimpleDialog'
import { Grid, Icon, Typography, makeStyles } from '@material-ui/core'
import { grey, red, amber } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons'

const useStyles = makeStyles(theme=>({
    container:{
        padding:'16px'
    },
    icon:{
        color:red[700],
        fontSize:54
      },
      title:{
        color:grey[900],
        fontWeight:'500'
      },
      message:{
        color:grey[700],
        fontWeight:'00'
      },
      errorIcon:{
        color:amber[700],
        fontSize:54
      },
      wrapper:{
          paddingTop:12,
          paddingBottom:12
      }
}))



const ErrorProcessModal = (props) => {

    const classes = useStyles()
    const {data, onFinished} = props


    return(
        <SimpleDialog 
            data={{}} 
            hideSubmitBtn 
            hideCancelBtn
            maxWidth='xs'>
            <div className={classes.container}>
                <Grid container spacing={2} direction='column' alignItems='center'>
                    <Grid item>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <Icon className={classes.errorIcon}>announcement</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6' className={classes.title}>
                                {'¡Aviso importante!'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' align='center' className={classes.message}>
                                El paciente se creó de manera existosa, sin embargo se presentaron los siguientes problemas:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={classes.wrapper}>
                                    <Grid container spacing={1}>
                                        {data.map((item,key)=>{
                                            return(
                                                <Grid item xs={12} key={key.toString()}>
                                                    <Grid container spacing={1} wrap='nowrap'>
                                                        <Grid item>
                                                            <div style={{width:110}}>
                                                                <Typography variant='body2' style={{fontWeight:'500'}}>{item.title}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='body2' style={{color:grey[700]}}>{item.message}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                                
                            </Grid>
                            <Grid item>
                                <RoundedButton label='Continuar' size='small' onClick={onFinished}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

        </SimpleDialog>
    )
}

export default ErrorProcessModal