import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CardWrapper from '../../components/Cards/CardWrapper/CardWrapper';
import { isFormValid, onGetErrorMessage } from '../../shared/utility';
import FormView from './components/FormView';
import SuccessView from './components/SuccessView';
import queryString from 'query-string';
import { request_execute_token, request_validate_token } from './requests';

const ResetPassword = ({history}) => {

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isValid, setIsValid] = useState(false)

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'password'){
            temp.passwordConfirmation.value2 = temp[id].value
        }
        setError(null)
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async() => {

        if(!isValid){
            setError('Llene correctamente los datos')
            return
        }

        const {token} = queryString.parse(history.location.search)

        let data2send = {
            password_reset_token:token,
            password: form.password.value
        }
        console.log(data2send)

        setLoading(true)
        try {
            await request_validate_token({password_reset_token:token})
            await request_execute_token(data2send)
            setIsPasswordRecover(true)
            
        } catch (error) {
            setError(onGetErrorMessage(error))
        }
        //await request_reset_password(data2send)
            //setIsPasswordRecover(true)
        setLoading(false)
        
    }

    let contentView = (
        <FormView form={form} onChange={onChange} isValid={isValid} loading={loading} error={error}
        onSubmit={onSubmit}/>
    )

    if(isPasswordRecover){
        contentView = <SuccessView onReturn={()=>history.push('/signin')}/>
    }

    return ( 
        <div className={classes.root}>
            <div className={classes.container}>
                <CardWrapper>
                    <div className={classes.card}>
                        {contentView}
                    </div>
                </CardWrapper>
            </div>
        </div>

     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        width:'100%',
        height:'100vh'
    },
    container:{
        width:600,
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{width:'100%', padding:8}

        
    },
    card:{
        padding:40,
        [theme.breakpoints.down('sm')]:{padding:16}
    }
}))


export default ResetPassword;

const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helper:'Debe contener al menos un caracter'
        },
        rules:{
          type:'distance',
          min:1
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helper:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}