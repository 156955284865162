import {mainServer} from '../../variables/config'

export const request_login = async(data) => {
    mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
    let response;
    response = await mainServer.instance.post("/login",data);
    localStorage.setItem('data',JSON.stringify(response.data.data));
}

export const request_validate_token = async(data) => {
    mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
    await mainServer.instance.post(`/passwordreset/validate`, data)
}

export const request_execute_token = async(data) => {
    mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
    const response = await mainServer.instance.post(`/passwordreset/execute`, data)
    console.log(response.data)
}

