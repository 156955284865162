import React from 'react'
import { Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { getUserType, numberWithCommas, onFormatNumber } from '../../../../shared/utility'
import NumberAvatar from '../../../../components/DisplayData/NumberAvatar'
import StatusLabel from '../../../../components/DisplayData/StatusLabel'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import { generalCatalogs } from '../../../../variables/catalogs'
import ActionBar from './ActionBar'
import { nodata, USER_TYPE } from '../../../../variables/config'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import {constrainst_table as constrainst} from  '../constraints'

const statusCatalog = [{id:-1, value:-1, label:'Todos'}, ...generalCatalogs.patient_statuses ]
const benefitsCatalog = [{id:-1, value:-1, label:'Todos'}, ...generalCatalogs.benefit_levels ]
const responseCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Sí '},{id:0, value:0, label:'No '}]
const searchCatalog = [{id:2, value:'id_patient', label:'ID '},{id:1, value:'trf', label:'TRF '}]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)'
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[200],
        paddingTop:12,
        paddingBottom:8
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, header, onAddRegister, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem} = props

    const userType = getUserType()

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <FilterButton label='Respuesta' onChange={(id) => onChangeFilter('response', id)}
                        options={responseCatalog} selected={filter.response}/>
                    </Grid>
                    <Grid item>
                        <FilterButton label='Nivel' onChange={(id) => onChangeFilter('benefit', id)}
                        options={benefitsCatalog} selected={filter.benefit}/>
                    </Grid>
                    <Grid item>
                        <FilterButton label='Estatus' onChange={(id) => onChangeFilter('status', id)}
                        options={statusCatalog} selected={filter.status}/>
                    </Grid>
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <FilterButton label='Buscar por' onChange={(id) => onChangeFilter('search_by', id)}
                                options={searchCatalog} selected={filter.search_by}/>
                            </Grid>
                            <Grid item><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                            <ContraintLayout constrainst={constrainst.addbutton} auto>
                                <IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton>
                            </ContraintLayout>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table  className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            if(item.id === 6 && userType === USER_TYPE.REGISTRANT) return
                                            return(
                                                <TableCell className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_patient, trf, test_result, benefit_level, patient_status, patient_status_id, test_cost} = item

                                    let cost = nodata
                                    if(test_cost){
                                        cost = `$ ${numberWithCommas(test_cost)}`
                                    }

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(item)}>
                                            <TableCell className={classes.cell}><DisplayText>{onFormatNumber(id_patient,6,'FMI')}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{trf ? trf : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{test_result ? 'Sí' : 'No'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><NumberAvatar >{benefit_level ? benefit_level : '-'}</NumberAvatar></TableCell>
                                            <TableCell className={classes.cell}><StatusLabel status={patient_status} status_id={patient_status_id}/></TableCell>
                                            {userType !== USER_TYPE.REGISTRANT ? <TableCell className={classes.cell}><DisplayText>{cost}</DisplayText></TableCell> : null}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div>
        </div>
    )
}

export default SimpleTable