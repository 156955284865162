import {mainServer} from '../../../variables/config'

export const request_approval_contacts = async (urlArgs) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/approvalcontact/all${urlArgs}`,)
  return response.data.data
}

export const request_add_approval_contact = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post(`/approvalcontact`,data)
}

export const request_edit_approval_contact = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/approvalcontact/${id}`,data)
}

export const request_delete_approval_contact = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/approvalcontact/${id}`)
}

export const request_check_username = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post(`/approvalcontact/check/username`, {username:data})
  return response.data.data
}

export const request_delete_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/approvalcontact/"+id)
  let response
  response = await mainServer.instance.get("/approvalcontact/type/1")
  return response.data.data
}
