import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { RoundedButton } from '../../../components/Buttons/GeneralButtons'
import LineInputText from '../../../components/Inputs/LineInputText/LineInputText'
import DisplayText from '../../../components/Texts/DisplayText'

const FormView = props => {

    const {form, onChange, isValid, loading, error, onSubmit} = props

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText color='primary' variant='h5'>Nueva contraseña</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText color='bluegrey' size='normal'>Por favor ingresa tu nueva contraseña con la que podrás acceder a tu cuenta</DisplayText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <LineInputText data={form.password} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputText data={form.passwordConfirmation} onChange={onChange}/>
                        </Grid>
                        
                        
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <div style={{minHeight:44}}>
                                        <Grid container justify='center'>
                                            {loading ? (
                                                <Grid item> <CircularProgress color='secondary'/> </Grid>
                                            ) : null}
                                            {error ? (
                                                <Grid item>
                                                    <DisplayText color='error' variant='subtitle2' >{error}</DisplayText>
                                                </Grid>
                                            ):null}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end' >
                                <Grid item>
                                <RoundedButton fullWidth color='primary' onClick={onSubmit} label='Continuar'/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            
        </Grid>
    )
}

export default FormView