import React from 'react'
import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import BasicInfoContainer from './components/BasicInfoContainer';
import DetailInfoContainer from './components/DetailInfoContainer';

const useStyles = makeStyles(theme => ({
  root:{
    paddingTop:16,
    marginRight:16,
    marginBottom:32,
  },
  avatar:{
    background:theme.palette.primary.main,
    width:110,
    height:110,
    fontSize:72
  },
}))

const FormStepD = props => {

    const classes = useStyles()
    const {formData, has_insurance, first_time, has_vehicles, vehicles, properties, credit_cards,
      benefit} = props

    const onGetValues = (form) => {
      let data2send = {}
      Object.keys(form).forEach(item => {
          data2send = {...data2send, [item]:form[item].value}
      })
      return data2send
    }

    const form  = onGetValues(formData)

    return(
      <div className={classes.root}>
        <Grid container spacing={8} justify='center'>
          <Grid item xs={12}>
            <BasicInfoContainer data={form} has_insurance={has_insurance} first_time={first_time}/>
          </Grid>
          <Grid item xs={12}>
            <DetailInfoContainer data={form} properties={properties} credit_cards={credit_cards} vehicles={vehicles} has_vehicles={has_vehicles}/>
          </Grid>
          <Grid item>
            <Grid container direction='column' alignItems='center' spacing={1}>
              <Grid item><Avatar className={classes.avatar}>{benefit ? benefit : 0}</Avatar></Grid>
              <Grid item><Typography variant='h6'>Nivel</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )

}

export default FormStepD