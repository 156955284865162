import React from 'react'
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import { generalCatalogs } from '../../../../variables/catalogs'
import ActionBar from './ActionBar'
import moment from 'moment-timezone'
const logEventTypesCatalog = [{id:-1, value:-1, label:'Todos'}, ...generalCatalogs.log_events ]

const header = [
    {id:1, label:'ID'},
    {id:2, label:'Asesor'},
    {id:3, label:'Evento'},
    {id:4, label:'Fecha'},
    {id:5, label:'Hora'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)'
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[200],
        paddingTop:12,
        paddingBottom:8
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem} = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <FilterButton label='Evento' onChange={(id) => onChangeFilter('log_event_id', id)}
                        options={logEventTypesCatalog} selected={filter.log_event_id}/>
                    </Grid>
                    <Grid item><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                </Grid>
            </div>
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_log, user, log_event, created_at} = item
                                    const date_info = moment(`${created_at}Z`).tz(moment.tz.guess())
                                    const date = date_info.format('YYYY-MM-DD')
                                    const hour = date_info.format('HH:mm')

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(item)}>
                                            <TableCell className={classes.cell}><DisplayText>{id_log}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{user}</DisplayText></TableCell>
                                            
                                            <TableCell className={classes.cell}><DisplayText>{log_event}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText >{date}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText >{hour}</DisplayText></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div>
        </div>
    )
}

export default SimpleTable