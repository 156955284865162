import { generalCatalogs } from "../../../../../variables/catalogs";

export const formData = {
      manual_discount_value:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.discounts],
        config:{
          id:'manual_discount_value',
          type:'select',
          fullWidth: true,
          label:'Descuento',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'none',
        }
      }
}