import React, {useEffect, useState} from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import { request_patient_stats, request_patient_stats_per_state } from '../../requests'
import PatientStats from './components/PatientStats'
import { cleanPatientStats, cleanPatientStatsByState, cleanPatientStatsExcel, cleanPatientStatsByStateExcel } from './customFunctions'
import PatientStatsPerState from './components/PatientStatsPerState'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import {s2ab} from '../../../../../shared/utility'


const useStyles = makeStyles(theme => ({
    root:{
    }
}))

const GeneralView = props => {

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [patientStats, setPatientStats] = useState([])
    const [patientStatsPerState, setPatientStatsPerState] = useState([])

    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            try {
                const patient_stats = await request_patient_stats()
                const patient_stats_per_state = await request_patient_stats_per_state()
                setPatientStats(patient_stats)
                setPatientStatsPerState(patient_stats_per_state)
            } catch (error) {
                
            }
            setLoading(false)

        }
        if(JSON.parse(localStorage.getItem('data'))) initModule()
    }, [])

    const onDownloadInformation = async() => {
        console.log('Descargar')
        const _patient_stats = cleanPatientStatsExcel (patientStats[0])
        const _patient_stats_per_state = cleanPatientStatsByStateExcel(patientStatsPerState)

        const sheets = [
            {name:'Beneficiarios', data:_patient_stats},
            {name:'Beneficiarios por Estado', data:_patient_stats_per_state},
        ]

        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Foundation Medicine Analytics",
            Subject: "General Data",
            Author: "Trionix Technologies",
            CreatedDate: new Date()
        };

        for(let i=0; i<sheets.length; i++){
            wb.SheetNames.push(sheets[i].name);
            const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
            wb.Sheets[sheets[i].name] = temp;
        }

        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'analytics.xlsx');
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    ///                 PARSE DATA TO TABLES
    //////////////////////////////////////////////////////////////////////////////////////////////
    const _patient_stats = cleanPatientStats(patientStats[0])
    const _patient_stats_per_state = cleanPatientStatsByState(patientStatsPerState)
    //console.log(_patient_stats_per_state)

    //console.log(_patient_stats)


    let contentView = <Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>
    if(!loading){
        contentView = (
            <Grid container spacing={3} justify='flex-end'>
                <Grid item>
                    <RoundedButton label='Descargar' onClick={onDownloadInformation}/>
                </Grid>
                <Grid item xs={12}>
                    <PatientStats data={_patient_stats}/>
                </Grid>
                <Grid item xs={12}>
                    <PatientStatsPerState data={_patient_stats_per_state}/>
                </Grid>
            </Grid>
        )
    }
    return(
        <div>
            {contentView}
        </div>
    )
}

export default GeneralView