import React from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import DisplayText from '../Texts/DisplayText'
import { green, grey,red } from '@material-ui/core/colors'

const colorMenu = [
    red[700], // No adquirido
    green[700], // Adquirido
]

const useStyles = makeStyles({
    icon:{
        fontSize:14,
        marginTop:4,
    }
})

const UserStatusLabel = props => {

    const classes = useStyles()
    const {status} = props

    

    return(
        <div>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Icon className={classes.icon} style={{color: status !== undefined ? colorMenu[status] : grey[500]}}>brightness_1</Icon>
                </Grid>
                <Grid item>
                    <DisplayText>{status ? 'Activo' : 'Inactivo'}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserStatusLabel