import { generalCatalogs } from "../../../variables/catalogs";

export const formData = {
  ///////////////////   PATIENT INFO    ///////////////////////
	name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
      label:'Nombre del beneficiario',
      placeholder:'Ingrese un nombre...'
    },
    rules:{type:'distance'}
  },
  doctor:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'doctor',
      type:'select',
      fullWidth: true,
      label:'Doctor',
      placeholder:'Escriba el nombre del doctor...'
    },
    rules:{
      type:'none',
    }
  },
  age:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'age',
      type:'number',
      fullWidth: true,
      label:'Edad',
      placeholder:'Ingrese la edad...'
    },
    rules:{type:'distance'}
  },
  gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[...generalCatalogs.user_genders],
    config:{
      id:'gender_id',
      type:'select',
      fullWidth: true,
      label:'Género',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'none',
    }
  },
  state_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[...generalCatalogs.patient_states],
    config:{
      id:'state_id',
      type:'select',
      fullWidth: true,
      label:'Entidad',
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'none',
    }
  },
  city:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'city',
      type:'text',
      fullWidth: true,
      label:'Ciudad',
      placeholder:'Ingrese la ciudad...'
    },
    rules:{type:'distance'}
  },
  suburb:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'suburb',
      type:'text',
      fullWidth: true,
      label:'Colonia',
      placeholder:'Ingrese la colonia...'
    },
    rules:{type:'distance'}
  },
  zip_code:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'zip_code',
      type:'number',
      fullWidth: true,
      label:'C.P.',
      placeholder:'Ingrese el código postal...',
      helper:'C.P. inválido'
    },
    rules:{type:'zip'}
  },
  phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'phone',
      type:'phone',
      fullWidth: true,
      label:'Teléfono',
      placeholder:'Ingrese un teléfono...',
      helper:'Teléfono inválido'
    },
    rules:{type:'phone'}
  },
  interview_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'interview_date',
      type:'date',
      fullWidth: true,
      label:'Fecha de entrevista',
      placeholder:'Ingrese la fecha...'
    },
    rules:{type:'date'}
  },
  address:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'address',
      type:'text',
      fullWidth: true,
      label:'Domicilio',
      placeholder:'Ingrese el domicilio...'
    },
    rules:{type:'distance'}
  },
  insurance_carrier:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'insurance_carrier',
      type:'text',
      fullWidth: true,
      label:'Nombre de la aseguradora',
      placeholder:'Ingrese la aseguradora...'
    },
    rules:{type:'distance'}
  },
  policy_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'policy_value',
      type:'number',
      float:true,
      fullWidth: true,
      label:'Monto de póliza',
      placeholder:'Ingrese el monto...'
    },
    rules:{type:'none'}
  },
  ///////////////////   INCOMES INFO    ///////////////////////
  income:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'income',
      type:'number',
      fullWidth: true,
      float:true,
      label:'Rango de ingresos',
      placeholder:'Introduzca sus ingresos...',
      helper:'El valo no puede exceder el monto de $250,000.00'
    },
    rules:{type:'numeric', max:250000}
  },
  dependant_wives:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_wives',
      type:'number',
      fullWidth: true,
      label:'Cónyuge o pareja',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_children',
      type:'number',
      fullWidth: true,
      label:'Hijos',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_parents:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_parents',
      type:'number',
      fullWidth: true,
      label:'Padres',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_inlaws:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_inlaws',
      type:'number',
      fullWidth: true,
      label:'Suegros',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_other:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_other',
      type:'number',
      fullWidth: true,
      label:'Otros',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  ///////////////////   SCHOOLS INFO    ///////////////////////

  public_elementary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_elementary_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_elementary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_elementary_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_secondary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_secondary_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_secondary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_secondary_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_high_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_high_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_high_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_high_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_university_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_university_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_university_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_university_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },

}