import React from 'react'
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { nodata } from '../../../../variables/config'
import DisplayText from '../../../../components/Texts/DisplayText'
import { blueGrey, grey } from '@material-ui/core/colors'
import { getComponentLogInfo, numberWithCommas } from '../../../../shared/utility'
import { generalCatalogs } from '../../../../variables/catalogs'
import CircularButton from '../../../../components/Buttons/CircularButton'
import InfoButton from '../../../../components/Buttons/InfoButton'


const useStyles = makeStyles(theme => ({
    image:{
        width:160,
    },
    container:{
        padding:32,
        paddingTop:0,
        paddingLeft:0,
        paddingRight:4,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    subtitle:{
        color:blueGrey[500]
    },
    nodata:{
        paddingTop:32
    },
    table:{
        minWidth:500
    },
    tableWrapper:{
        overflowX: 'auto',
    },
}))

const CreditCardsContainer = props => {

    const classes = useStyles()
    const {data, onEditItem, onRemoveItem, onAddItem} = props


    let income_value 
    if(data){
        if(data.income) income_value = `$ ${numberWithCommas(data.income)}`
    }

    let contentView = null


    if(data && data.length){
        contentView = (
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellHeader}><DisplayText variant='subtitle2'>Nombre</DisplayText></TableCell>
                            <TableCell className={classes.cellHeader}><DisplayText variant='subtitle2'>Rango</DisplayText></TableCell>
                            <TableCell className={classes.cellHeader} padding='checkbox' ><DisplayText variant='subtitle2'>Acciones</DisplayText></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item,key)=>{

                            const creditRange = generalCatalogs.credit_ranges.find(el => el.id === item.credit_range_id)
                            const infoData = getComponentLogInfo(item)
                            return(
                                <TableRow key={key.toString()}>
                                    <TableCell className={classes.cell}><DisplayText>{item.name}</DisplayText></TableCell>
                                    <TableCell className={classes.cell}><DisplayText>{creditRange ? creditRange.label : nodata}</DisplayText></TableCell>
                                    <TableCell className={classes.cell} padding='checkbox'>
                                        <Grid container spacing={1} wrap='nowrap' alignItems='center'>
                                            <Grid item>
                                                <InfoButton data={infoData}/>
                                            </Grid>
                                            <Grid item>
                                                <CircularButton onClick={() => onEditItem(item)}
                                                size='small' icon='edit' iconSize='small' color='green'/> 
                                            </Grid>
                                            <Grid item>
                                                <CircularButton onClick={() => onRemoveItem(item)}
                                                size='small' icon='delete' iconSize='small' color='red'/> 
                                            </Grid>
                                        </Grid>
                                    
                                    </TableCell>
                                </TableRow>
                            )

                            
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }else{
        contentView = (
            <div className={classes.nodata}>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText align='center'>No existe tarjetas registradas</DisplayText>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DisplayText variant='subtitle1'>Tarjetas de crédito</DisplayText>
                        </Grid>
                        <Grid item>
                            <CircularButton icon='add' size='small' onClick={onAddItem}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.container}>
                    {contentView}
                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreditCardsContainer