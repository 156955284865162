import { Grid, Switch } from '@material-ui/core'
import React from 'react'
import DisplayText from '../Texts/DisplayText'

const SwitchButton = props => {

    const {id,message, status, onChange, } = props

    const onInnerChange = (event) => {
        onChange(id, event.target.checked)
    }

    return(
        <div>
            <Grid container alignItems='center' spacing={2} wrap='nowrap'>
                <Grid item>
                    <DisplayText align='right' >{message}</DisplayText>
                </Grid>
                <Grid item>
                    <Grid container alignItems='center' wrap='nowrap'>
                        <Grid item>
                            <DisplayText variant='subtitle1'>No</DisplayText>
                        </Grid>
                        <Grid item>
                            <Switch checked={status} onChange={onInnerChange}/>
                        </Grid>
                        <Grid item>
                            <DisplayText variant='subtitle1'>Sí</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SwitchButton