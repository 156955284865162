import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import LineInputDate from '../../../../../components/Inputs/LineInputDate'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { onValidateForm } from '../../../../../shared/utility'
import {formData } from './data'
import moment from 'moment'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton'
import {request_update_patient, request_download_letter} from '../../requests'

const letterTitle = {
    patientletter:'Carta paciente',
    doctorletter:'Carta doctor',
    operatorletter:'Carta proveedor',
}

const LetterModal = props => {

    const {initData, patientID, open, onClose, onUpdatePatient, letter} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!initData) return
        let temp = {...form}
        Object.keys(form).forEach( item =>{
            let newValue = initData[item] 
            if(newValue !== null && newValue !== undefined){
                if(item === 'letter_date'){
                    if(moment(newValue).isValid()){
                        newValue = moment(newValue).format('YYYY-MM-DD')
                    }
                }
                temp[item].value = newValue
                temp[item].isValid = true
                temp[item].isVisited = true
            }
        })
        setError(null)
        setValid(onValidateForm(temp))
        setForm(temp)

    }, [initData, open])


    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        setValid(onValidateForm(temp))
        setForm(temp)
    }

    const onEdit = async() => {
        if(!valid){
            setError('Llena los campos faltantes')
            return
        }
        
        let data2send = {}
        Object.keys(form).forEach(item => {
            data2send = {...data2send, [item]: form[item].value}
        })
        setLoading(true)
        try {
            const newPatient = await request_update_patient(patientID, data2send)
            onUpdatePatient(newPatient)
            await request_download_letter(patientID, letter.id)
            setLoading(false)
            setError(null)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }
    
    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>{letter ? letterTitle[letter.id] : '-'}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <LineInputDate 
                                data={form.letter_date}
                                onChange={onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LineInputText 
                                data={form.letter_sender_1}
                                onChange={onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LineInputText 
                                data={form.letter_sender_2}
                                onChange={onInputChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton label='Descargar' error={error} loading={loading} onSubmit={onEdit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default LetterModal