const styles = theme => ({
  root:{
    padding: theme.spacing.unit*1,
    borderRadius:32,
    '&:hover':{
      cursor:'pointer'
    }
  },
  avatar:{
    margin: 3,
    backgroundColor: 'transparent',
    color: 'white',
    border:`2px solid ${theme.palette.secondary.main}`
  },
  button:{
    padding:"10px 0px",
    borderRadius:'5px',
    "&:hover": {
        backgroundColor:"#EEE"
    }
  },
  text:{
    color:theme.palette.secondary.main
  },
  user:{
    color:'rgba(255,255,255,1)',
  },
 
});

export default styles;
