import React, {useState} from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import userimage from '../../../../assets/building.png'
import LineInputText from '../../../../components/Inputs/LineInputText/LineInputText';
import DisplayText from '../../../../components/Texts/DisplayText';
import NumberAvatar from '../../../../components/DisplayData/NumberAvatar';
import CreditCards from './components/CreditCards/CreditCards';
import AddRegisterModal from './modals/AddRegisterModal';


const useStyles = makeStyles(theme => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:120,
    height:120,
  }
}))

const FormStepB = props => {

    const classes = useStyles()
    const {input_data, credit_cards, onInputChange, onUpdateCollection, onDocumentRemove} = props

    const onGetTotalDependants = (input_data) => {
      const filter = ['dependant_wives', 'dependant_children', 'dependant_parents', 'dependant_inlaws', 'dependant_other']
      let total = 0
      filter.forEach(item => {
        const value = parseInt(input_data[item].value)
        if(value > 0) total += value
      })
      return total
    }
  

    let formdataTemp = {...input_data}
    const totalDependants = onGetTotalDependants(input_data)

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justify='center'>
                  <Grid item>
                    <img src={userimage} alt={''} className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                  {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={6}>
                        <LineInputText 
                          data={formdataTemp.income}
                          onChange={onInputChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------ Dependants ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Dependientes económicos</DisplayText>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                            data={formdataTemp.dependant_wives}
                            onChange={onInputChange}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                            data={formdataTemp.dependant_children}
                            onChange={onInputChange}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                            data={formdataTemp.dependant_parents}
                            onChange={onInputChange}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                            data={formdataTemp.dependant_inlaws}
                            onChange={onInputChange}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                            data={formdataTemp.dependant_other}
                            onChange={onInputChange}/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} justify='center' alignItems='center' direction='column'>
                          <Grid item>
                            <NumberAvatar size='xl'>{totalDependants}</NumberAvatar>
                          </Grid>
                          <Grid item>
                            <DisplayText variant='subtitle1'>Total de dependientes</DisplayText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------ School ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Hijos en edad escolar</DisplayText>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          {/* ------------------ ELEMENTRAY SCHOOL ------------------------- */}
                          <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                              <Grid item xs={12} md={4}>
                                <DisplayText>Primaria</DisplayText>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.public_elementary_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.private_elementary_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* ------------------ MIDDLE SCHOOL ------------------------- */}
                          <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                              <Grid item xs={12} md={4}>
                                <DisplayText>Secundaria</DisplayText>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.public_secondary_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.private_secondary_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* ------------------ HIGH SCHOOL ------------------------- */}
                          <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                              <Grid item xs={12} md={4}>
                                <DisplayText>Preparatoria</DisplayText>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.public_high_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.private_high_school_children}
                                onChange={onInputChange}/>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* ------------------ UNIVERSITY SCHOOL ------------------------- */}
                          <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                              <Grid item xs={12} md={4}>
                                <DisplayText>Universidad</DisplayText>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.public_university_children}
                                onChange={onInputChange}/>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LineInputText 
                                data={formdataTemp.private_university_children}
                                onChange={onInputChange}/>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------ END ------------------------- */}
                  <Grid item xs={12}>
                    <CreditCards credit_cards={credit_cards} onUpdateCollection={onUpdateCollection}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
}

export default FormStepB