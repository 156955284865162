import {mainServer} from '../../../variables/config'

export const request_patient_stats = async (state) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response = await mainServer.instance.get(state ? `/analytic/patientstats?state_id=${state}`:`/analytic/patientstats`)
    return response.data.data.patient_stats
}

export const request_patient_stats_per_state = async () => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response = await mainServer.instance.get(`/analytic/patientstatsperstate`)
    return response.data.data.patient_stats_per_state
}

export const request_living_places_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/livingplacestats?${filter}=${value}` : `/analytic/livingplacestats`
    let response = await mainServer.instance.get(url)
    return response.data.data.living_place_stats
}

export const request_income_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/incomestats?${filter}=${value}` : `/analytic/incomestats`
    let response = await mainServer.instance.get(url)
    return response.data.data.income_stats
}

export const request_children_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/childrenstats?${filter}=${value}` : `/analytic/childrenstats`
    let response = await mainServer.instance.get(url)
    return response.data.data.children_stats
}

export const request_dependant_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/dependantstats?${filter}=${value}` : `/analytic/dependantstats`
    let response = await mainServer.instance.get(url)
    return response.data.data.dependant_stats
}

export const request_credit_card_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/creditcardstats?${filter}=${value}` : `/analytic/creditcardstats`
    let response = await mainServer.instance.get(url)
    return response.data.data.credit_card_stats
}

export const request_car_stats = async (filter, value) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    const url = filter !== '' ? `/analytic/carstats?${filter}=${value}` : `/analytic/carstats`
    let response = await mainServer.instance.get(url)
    return response.data.data.car_stats
}


