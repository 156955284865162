import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Icon } from '@material-ui/core';
import cx from 'classnames';
import { getUserType, isUserAllowed } from '../../../shared/utility';
import { withRouter } from 'react-router';

const styles = (theme) => ({
  root:{
    width:'100%',
    background:theme.palette.primary.main,
    color:'white',
  },
  button:{
    width:'100%',
    boxSizing:'border-box',
    padding:'16px 0px',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover':{
      background:'rgba(255,255,255,0.3)',
      cursor:'pointer'
    }
  },
  buttonSelected:{
    background:'rgba(255,255,255,0.15)',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover':{
      cursor:'pointer',
      background:'rgba(255,255,255,0.15)',
    }
  }
})

class MenuBar extends Component {

  isCompatibleURL = (history, path) => {
    return history.location.pathname.split('/')[1] === path.split('/')[1];
  }

  render(){

    const {classes, history, routes, onClick} = this.props;
    const userType = getUserType()
    return(
      <div className={classes.root}>
        <Grid container direction='column'>
          {routes.map((item,key)=>{
            if(item.sidebar && isUserAllowed(userType, item.constraints)){
              const selected =  this.isCompatibleURL(history,item.path);
              return(
                <Grid item key={key.toString()}>
                  <div 
                    onClick={() => onClick(item.path)}
                    className={cx({
                      [classes.button]:true,
                      [classes.buttonSelected]:selected
                    })}
                  >
                    <Grid container direction='column' alignItems='center'>
                      <Grid item>
                        <Icon>{item.icon}</Icon>
                      </Grid>
                      <Grid item>
                        <Typography variant='caption' style={{color:'white'}}>
                          {item.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            }
            return null
          })}
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(MenuBar) )