import React, { Component } from 'react'
import ContentText from './ContentText';
import { Grid, Icon, IconButton, makeStyles, withStyles } from '@material-ui/core';
import { blueGrey, grey } from '@material-ui/core/colors';
import { nodata } from '../../variables/config';
import DisplayText from './DisplayText';

const useStyles = makeStyles(theme => ({
  root:{
    paddingRight:16
  },
  iconButton:{
    color:blueGrey[300]
  }
}))

const VerticalDataWrapper = props => {

    const classes = useStyles()
    const {id, label, text, icon, iconColor, iconSize, onEdit} = props
  
    let iconContent = null
    if(icon){
      iconContent = (
        <Grid item>
          <Icon fontSize='small' style={{color:iconColor, paddingTop:4, fontSize: iconSize}}>{icon}</Icon>
        </Grid>
      )
    }

    let editContent = null
    if(onEdit){
      editContent = (
        <Grid item>
          <IconButton size='small' onClick={() => onEdit(id)} className={classes.iconButton}><Icon fontSize='small'>edit</Icon></IconButton>
        </Grid>
      )
    }
  
    let labelContent = null
    labelContent=(
      <Grid container wrap='nowrap' alignItems='center' spacing={1}>
        {iconContent}
        <Grid item xs>
          <DisplayText>
            {text !== null && text !== undefined && text !== '' ? text : nodata}
          </DisplayText>
        </Grid>
        {editContent}
      </Grid>
    )
  
    return(
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DisplayText variant='subtitle1'>{label ? label : ''}</DisplayText>
          </Grid>
          <Grid item xs={12}>
            {labelContent}
          </Grid>
        </Grid>
      </div>
      
    )
  
}

export default VerticalDataWrapper