export const formData = {
  income:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'income',
      type:'number',
      fullWidth: true,
      float:true,
      label:'Rango de ingresos',
      placeholder:'Introduzca sus ingresos...',
      helper:'El valo no puede exceder el monto de $250,000.00'
    },
    rules:{type:'numeric', max:250000}
  },
  dependant_wives:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_wives',
      type:'number',
      fullWidth: true,
      label:'Cónyuge o pareja',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_children',
      type:'number',
      fullWidth: true,
      label:'Hijos',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_parents:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_parents',
      type:'number',
      fullWidth: true,
      label:'Padres',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_inlaws:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_inlaws',
      type:'number',
      fullWidth: true,
      label:'Suegros',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  dependant_other:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dependant_other',
      type:'number',
      fullWidth: true,
      label:'Otros',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  ///////////////////   SCHOOLS INFO    ///////////////////////

  public_elementary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_elementary_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_elementary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_elementary_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_secondary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_secondary_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_secondary_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_secondary_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_high_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_high_school_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_high_school_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_high_school_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  public_university_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_university_children',
      type:'number',
      fullWidth: true,
      label:'Pública',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
  private_university_children:{
    value:null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'private_university_children',
      type:'number',
      fullWidth: true,
      label:'Privada',
      placeholder:'Ej. 1,2'
    },
    rules:{type:'none'}
  },
}