import { amber, deepPurple, green, grey, indigo, lightBlue, orange, red, teal } from '@material-ui/core/colors'
import { onTruncateText } from '../../../../../shared/utility'

const PATIENTSTATUS = {
  INPROCESS:'En proceso',
  PREAUTHORIZED:'Pre-autorizados',
  AUTHORIZED:'Autorizados',
  REJECTED:'Rechazados',
  ACDQUIRED:'Adquiridos',
  NOADCQUIRED:'No adquiridos',
  REPORTED:'Reportados',
  NOREPORTED:'No reportados'
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           PATIENT STATS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanPatientStats = (data) => {
    let table = []
    let chartTable = []
    const colors = [lightBlue[700], teal[700],green[700],red[700],
    indigo[700],amber[700],deepPurple[700], orange[700]]
    if(data){
      table = [
        [colors[0],'En proceso',data.in_process,(data.in_process/data.total)*100],
        [colors[1],'Pre-autorizados',data.pre_authorized,(data.pre_authorized/data.total)*100],
        [colors[2],'Autorizados',data.authorized,(data.authorized/data.total)*100],
        [colors[3],'Rechazados',data.rejected,(data.rejected/data.total)*100],
        [colors[4],'Adquiridos',data.acquired,(data.acquired/data.total)*100],
        [colors[6],'Reportados',data.reported,(data.reported/data.total)*100],
        [null,'Totales',data.total,null],
      ]
  
      chartTable = [
        { name: 'En proceso', value: data.in_process },
        { name: 'Pre-autorizados', value: data.pre_authorized },
        { name: 'Autorizados', value: data.authorized },
        { name: 'Rechazados', value: data.rejected },
        { name: 'Adquiridos', value: data.acquired },
        { name: 'Reportados', value: data.reported },
      ]
    }
    return {table:[...table], chartTable: [...chartTable], colors:[...colors]}
}

export const cleanPatientStatsExcel = (data) => {
  let table = [['Nombre','Cantidad','Porcentaje']]
  if(data){
    table = [...table,
      ['En proceso',data.in_process,(data.in_process/data.total)*100],
      ['Pre-autorizados',data.pre_authorized,(data.pre_authorized/data.total)*100],
      ['Autorizados',data.authorized,(data.authorized/data.total)*100],
      ['Rechazados',data.rejected,(data.rejected/data.total)*100],
      ['Adquiridos',data.acquired,(data.acquired/data.total)*100],
      ['Reportados',data.reported,(data.reported/data.total)*100],
      ['Totales',parseInt(data.total),1.0],
    ]
  }
  return table
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           GENERAL STATS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanGeneralStats = (data) => {
  let table = []
  let chartTable = []
  const colors = [lightBlue[700], teal[700],green[700]]
  if(data){
    table = [
      [colors[0],'En proceso',data.in_process,(data.in_process/data.total)*100],
      [colors[1],'Pre-autorizados',data.pre_authorized,(data.pre_authorized/data.total)*100],
      [colors[2],'Autorizados',data.authorized,(data.authorized/data.total)*100],
      [null,'Totales',data.total,null],
    ]

    chartTable = [
      { name: 'En proceso', value: data.in_process },
      { name: 'Pre-autorizados', value: data.pre_authorized },
      { name: 'Autorizados', value: data.authorized },
    ]
  }
  return {table:[...table], chartTable: [...chartTable], colors:[...colors]}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                           PATIENTS STATS BY STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanPatientStatsByState = (data) => {
  let table = [[''],[PATIENTSTATUS.INPROCESS],[PATIENTSTATUS.PREAUTHORIZED],
  [PATIENTSTATUS.AUTHORIZED],[PATIENTSTATUS.REJECTED],[PATIENTSTATUS.ACDQUIRED],
  [PATIENTSTATUS.NOREPORTED], ['Total']]
  let chartTable = []
  let bars = []
  if(data){
    if(data.length){
      data.forEach(item => {
        table[0].push(item.name)
        table[1].push(item.in_process)
        table[2].push(item.pre_authorized)
        table[3].push(item.authorized)
        table[4].push(item.rejected)
        table[5].push(item.acquired)
        table[6].push(item.reported)
        table[7].push(item.total)

        chartTable.push({
          name: onTruncateText(item.name),
          EnProceso:item.in_process,
          PreAutorizados:item.pre_authorized,
          Autorizados:item.authorized,
          Rechazados:item.rejected,
          Adquiridos:item.acquired,
          Reportados:item.reported,
        })
      })
      const colors = [lightBlue[700], teal[700],green[700],red[700],
      indigo[700],deepPurple[700]]
    
      bars = Object.keys(chartTable[0]).map((item,key)=>{
        if(item !== 'name'){
          return {datakey: item, fill:colors[key-1]}
        }
        return
      })
      bars = bars.splice(1)

    }

    
    
  }
  return {table:[...table], chart: {table:[...chartTable], bars:[...bars]}}
}

export const cleanPatientStatsByStateExcel = (data) => {
  let table = [['Estado'],[PATIENTSTATUS.INPROCESS],[PATIENTSTATUS.PREAUTHORIZED],
  [PATIENTSTATUS.AUTHORIZED],[PATIENTSTATUS.REJECTED],[PATIENTSTATUS.ACDQUIRED],
  [PATIENTSTATUS.REPORTED],['Total']]
  
  if(data){
    if(data.length){
      data.forEach((item) => {
        table[0].push(item.name)
        table[1].push(item.in_process)
        table[2].push(item.pre_authorized)
        table[3].push(item.authorized)
        table[4].push(item.rejected)
        table[5].push(item.acquired)
        table[6].push(item.reported)
        table[7].push(parseInt(item.total))
      })
    }
  }
  return table
}
  