import React, {useState, useEffect} from 'react'
import { Grid } from '@material-ui/core'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import NavigationMenu from './components/NavigationMenu'
import GeneralView from './views/GeneralView/GeneralView'
import DetailedView from './views/DetailedView/DetailedView'

const menu = [
    {id:1, label:'General'},
    {id:2, label:'Detallado'}
]

const Analytics = props => {

    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(1)

    useEffect(() => {
        const initModule = async() => {
            
        }
        if(JSON.parse(localStorage.getItem('data'))) initModule()
    }, [])

    let contentView = selected === 1 ? <GeneralView /> : <DetailedView />

    return(
        <BasicView content={{
            title:'Analíticos',
            navigation:[
              {name:'Analíticos',path:'/analytics'}
            ],
        }} isloading={loading} >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <NavigationMenu options={menu} selected={selected} onChange={(id) => setSelected(id)}/>
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
        </BasicView>
    )
}

export default Analytics