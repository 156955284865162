import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import SimpleFile from '../../../../components/Files/SimpleFile/SimpleFile'
import ModalBase from '../../../../components/Modals/ModalBase'
import DisplayText from '../../../../components/Texts/DisplayText'
import SubmitModalButton from '../../../../components/Buttons/SubmitModalButton'
import { getFileExtension } from '../../../../shared/utility'
import { request_upload_document } from '../requests'

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:30
    },
    button:{
        borderRadius:32,
        textTransform:'none'
    }
}))

const UploadDocumentModal = props => {

    const classes = useStyles()
    
    //////////////////////////// MULTIPLE STATES AND PROPS  ///////////////////////////////
    const {data, onClose, open, patientID, onUpdatePatient} = props
    const [file, setFile] = useState(null) 
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if(open) setFile(null)
    },[open])

    //////////////////////////// INTERNAL FUNCTIONS  ///////////////////////////////
    const onDocumentAttached = (id, files) => {
        setFile(files[0])
    }

    const onDocumentRemove = () => {
        setFile(null)
    }

    const onSubmit = async() => {
        if(file){
            const ext = {ext:getFileExtension(file.name)}
            setLoading(true)
            try {
                const newPatient = await request_upload_document(patientID, data.id, file, ext)
                onUpdatePatient(newPatient)
                setLoading(false)
                onClose()
            } catch (error) {
                console.log(error)
                setError('Hubo un error')
                setLoading(false)
            }

        }
    }


    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Subir documento</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleFile 
                            label={data ? data.title : ''}
                            id='generic' file={file}
                            onDocumentAttached={onDocumentAttached}
                            onDocumentRemove={onDocumentRemove}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton label='Cargar' error={error} loading={loading} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default UploadDocumentModal