import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText'
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { onValidateForm } from '../../../../../shared/utility'
import {formData } from './data'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton'
import {request_edit_user, request_check_username} from '../../requests'

const EditAgentModal = props => {

    const {initData, userID, open, onClose, onUpdateUser} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!initData) return
        let temp = {...form}
        Object.keys(form).forEach( item =>{
            let newValue = initData[item] 
            if(newValue !== null && newValue !== undefined){
                temp[item].value = newValue
                temp[item].isValid = true
                temp[item].isVisited = true
            }
        })
        setValid(onValidateForm(temp))
        setForm(temp)

    }, [initData, open])


    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        if(id === 'password') temp.passwordConfirmation.value2 = data.value
        setValid(onValidateForm(temp))
        setForm(temp)
        setError(null)
    }

    const onSubmit = async() => {
        if(!valid){
            setError('Llena los campos faltantes')
            return
        }
        
        let data2send = {}
        Object.keys(form).forEach(item => {
            if(form[item].isValid && item !== 'passwordConfirmation'){
                data2send = {...data2send, [item]: form[item].value}
            }
        })
        console.log(data2send)
        setLoading(true)
        try {
            let response = await request_edit_user(userID ,data2send)
            setLoading(false)
            onUpdateUser(response)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
        }
        setLoading(false)


    }
    

    return(
        <ModalBase open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Editar agente</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.first_name}
                                onChange={onInputChange}/>
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.last_name}
                                onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12} md={6}>   
                                 <LineInputSelect 
                                 data={form.user_type_id}
                                 onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.email}
                                onChange={onInputChange}/>
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.phone}
                                onChange={onInputChange}/>
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.ext}
                                onChange={onInputChange}/>
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <LineInputText 
                                data={form.mobile}
                                onChange={onInputChange}/>
                            </Grid> 
                            <Grid item xs={12} md={6}>   
                                 <LineInputSelect 
                                 data={form.gender_id}
                                 onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12} md={6}>   
                                 <LineInputSelect 
                                 data={form.status}
                                 onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12} md={6}>   
                                 <LineInputSelect 
                                 data={form.timezone}
                                 onChange={onInputChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton label='Guardar' error={error} loading={loading} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default EditAgentModal