import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import {Button, CircularProgress, Fab, fade, Grid, Icon} from '@material-ui/core'
import {grey, red, green} from '@material-ui/core/colors'

const rounded_button_styles = (theme) => ({
  icon:{
    marginRight:'8px'
  },
  button:{
    color:'white',
    textTransform:'none',
    padding:'8px 40px',
    fontSize:16,
    borderRadius:32,
  },
  small_button:{
    padding:'0px 16px',
    ontSize:14,
  },
  secondary_button:{
    background:theme.palette.secondary.main,
    '&:hover':{
      background: fade(theme.palette.secondary.main, 0.85),
    }
  },
  white_button:{
    color:grey[500],
    background:`linear-gradient(135deg, #FFF, ${grey[50]})`,
    '&:hover':{
      background:`linear-gradient(135deg, ${grey[50]}, ${grey[100]})`,
    },
  },
  red_button:{
    background:red[700],
    '&:hover':{
      background:red[900],
    },
  },
  green_button:{
    background:green[700],
    '&:hover':{
      background:green[900],
    },
  },
  grey_button:{
    background:grey[500],
    '&:hover':{
      background:grey[600],
    },
  },
  primary_button:{
    background:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  },
  transparent_button:{
    background:'#FFF',
    '&:hover':{
      background:'#FFF',
    },
    color:grey[700],
    boxShadow:'none',
  },
})

class RoundedButtonW extends Component {
  render(){

    const {classes, icon, label, color, onClick, size, disabled, loading} = this.props;

    let iconContent = null;
    if(icon){
      iconContent = <Icon className={classes.icon}>{icon}</Icon>
    }

    return(
      <div>
        <Button 
          variant="container"
          size={size} 
          disabled={disabled}
          onClick={onClick}
          className={cx({
            [classes.button]:true,
            [classes.secondary_button]:true,
            [classes.primary_button]:color==='primary',
            [classes.white_button]:color==='white',
            [classes.green_button]:color==='green',
            [classes.red_button]:color==='red',
            [classes.grey_button]:color==='grey',
            [classes.transparent_button]:color==='transparent',
          })}>
          {iconContent}
          <div className={cx({
            [classes.small_button]: size==='small' && (icon === null || icon === undefined )
          })}> <Grid container alignItems='center' spacing={1}>
            <Grid item>{label}</Grid>
            {loading ? (            
            <Grid item><CircularProgress style={{color:'white', marginTop:4}} size={24}/></Grid>
            ) : null}
            </Grid>  </div>
        </Button>
      </div>
    )
  }
}

export const RoundedButton = withStyles(rounded_button_styles)(RoundedButtonW)