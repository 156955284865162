export const contentData = {
  spanish:{
    nodata:'N/D',
    layout:{
      title:'Beneficiarios',
      navigation:[
        {name:'Beneficiarios',path:'/applicants'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    table_header: [
      {id:1, label:'ID'},
      {id:2, label:'TRF'},
      {id:3, label:'Respuesta'},
      {id:4, label:'Nivel'},
      {id:5, label:'Estatus'},
      {id:6, label:'Costo de prueba'},
    ],
  }
}