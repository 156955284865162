import React from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import DisplayText from '../../../components/Texts/DisplayText'
import { green } from '@material-ui/core/colors'
import { RoundedButton } from '../../../components/Buttons/GeneralButtons'

const useStyles = makeStyles(theme => ({
    icon:{
        color:green[600],
        fontSize:100,
        border:`3px solid ${green[600]}`,
        borderRadius:'50%'
    }
}))

const SuccessView = props => {

    const { onReturn} = props
    const classes = useStyles()

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText align='center' color='primary' variant='h5'>¡Contraseña generada!</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText align='center' color='bluegrey' size='large'>Ya puede iniciar sesión con su nueva contraseña</DisplayText>
                    </Grid>
                    <Grid  item xs={12}>
                        <Grid container justify='center'>
                            <Grid item>
                                <Icon className={classes.icon}>done</Icon>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4} justify='flex-end'>
                        
                        <Grid item>
                            <RoundedButton fullWidth color='primary' onClick={onReturn} label='Ir a portal'/>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            
        </Grid>
    )
}

export default SuccessView