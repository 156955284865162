import React, {useState} from 'react'
import { Avatar, fade, Grid, Icon, IconButton, makeStyles, Switch } from '@material-ui/core'
import ContraintLayout from '../../../../components/Layouts/ConstraintLayout'
import {constrainst_business as constrainst} from  '../constraints'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey, orange } from '@material-ui/core/colors'
import HorizontalDataWrapper from '../../../../components/Texts/HorizontalDataWrapper'
import { nodata } from '../../../../variables/config'
import StatusActionBar from './StatusActionBar'
import { getComponentLogInfo, getUserType, numberWithCommas, onFormatNumber } from '../../../../shared/utility'
import { USER_TYPE } from '../../../../variables/config'
import { request_update_patient } from '../requests'
import { generalCatalogs } from '../../../../variables/catalogs'
import InfoButton from '../../../../components/Buttons/InfoButton'

const letters = [
    {id:'patientletter', title:'Paciente'},
    {id:'doctorletter', title:'Doctor'},
    {id:'operatorletter', title:'Proveedor'}
]


const useStyles = makeStyles(theme => ({

    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    avatar:{
        background:theme.palette.primary.main,
        width:110,
        height:110,
        fontSize:72
    },
    discount:{
        background:orange[600],
        width:110,
        height:110,
        fontSize:42,
    },
    documents:{
        borderTop:`1px solid ${grey[300]}`,
        paddingTop:32,
        paddingBottom:32
    },
    discountContainer:{position:'relative'},
    discountOverlay:{
        position:'absolute',
        top:0, left:0,
        width:110, height:110,
        background:fade('#000', 0.6),
        color:'white',
        borderRadius:'50%',
        textAlign:'center',
        boxSizing:'border-box',
        fontFamily:theme.typography.fontFamily,
        fontSize:18,
        fontWeight:400,
        paddingTop:'calc(50% - 18px)',
        '&:hover':{cursor:'pointer'}
    }
}))

const BusinessContainer = props => {

    const classes = useStyles()
    const {data, onEditItem, onUpdatePatient, onDownloadLetter, onEditDiscount} = props
    const [hover, setHover] = useState(false)

    const onEdit = (id) => onEditItem({id:id, value:data[id]})

    const onInnerChange = async(e, checked) => {
        const {id_patient} = data
        const data2send = {manual_discount: checked ? 1 : 0}
        const patient = await request_update_patient(id_patient, data2send)
        onUpdatePatient(patient)
    }
    

    let result, pdl
    let status
    let fmiID
    let ismanual = false
    let discount
    let test_type = nodata

    if(data){
        //console.log(data)
        ismanual = data.manual_discount === 1 
        if(ismanual){
            discount = data.manual_discount_value ? `${data.manual_discount_value}%` : '0%'
        }else{
            discount = generalCatalogs.benefit_levels.find(el => el.value === data.benefit_level_id)
            discount = discount ? `${discount.discount}%` : '0%'
        }
        result = data.test_result ? 'Sí' : 'No'
        pdl = data.pdl ? 'Sí' : 'No'
        status = data.patient_status_id
        fmiID = onFormatNumber(data.id_patient,6,'FMI')
        if(data.test_type_id !== 4){
            test_type = data.test_type
        }else{
            test_type = data.manual_test
        }
    }

    const userType = getUserType()   
    const infoData = getComponentLogInfo(data)
 

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DisplayText variant='subtitle1'>Información de estatus</DisplayText>
                        </Grid>
                        <Grid item>
                            <InfoButton data={infoData}/>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.container}>
                    <Grid container spacing={4}>
                        <ContraintLayout constrainst={constrainst.id_patient}>
                            <HorizontalDataWrapper  id='id_patient'
                                label='ID' text={fmiID}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.patient_status}>
                            <HorizontalDataWrapper  id='patient_status_id'
                                label='Estatus' text={data ? data.patient_status : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.trf}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='trf'
                                label='TRF' text={data ? data.trf : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.result}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='test_result'
                                label='Resultado de prueba' text={result}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.test_type}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='test_type_id'
                                label='Tipo de prueba' text={test_type}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.test_cost}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='test_cost'
                                label='Costo de la prueba' text={data ? `$ ${data.test_cost ? numberWithCommas(data.test_cost) : nodata}` : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.doctor}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='doctor'
                                label='Doctor' text={data ? data.doctor : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.doctor}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='manual_operator_name'
                                label='Operador' text={data ? data.manual_operator_name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper onEdit={userType !== USER_TYPE.AUDITOR ?  onEdit : null} id='pdl'
                                label='PDL 1' text={pdl}/>
                        </ContraintLayout>
                        <ContraintLayout status={status} constrainst={constrainst.documents} md={12}>
                            <div className={classes.documents}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle1'>Cartas</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4}>
                                            {letters.map((item,key)=>{
                                                return(
                                                    <Grid item xs={12} md={6} key={key.toString()} >
                                                        <Grid container alignItems='center' spacing={1}>
                                                            <Grid item xs>
                                                                <DisplayText>{item.title}</DisplayText>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid item>
                                                                    <IconButton color='primary' size='small' onClick={() => onDownloadLetter(item)} >
                                                                        <Icon>cloud_download</Icon> 
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            
                        </ContraintLayout>
                        <Grid item xs={12}>
                            <Grid container justify='center' spacing={2}>
                                <Grid item>
                                    <Grid container direction='column' alignItems='center' spacing={1}>
                                        <Grid item><Avatar className={classes.avatar}>{data ? data.benefit_level : 0}</Avatar></Grid>
                                        <Grid item><DisplayText variant='subtitle1'>Nivel</DisplayText></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='column' alignItems='center' spacing={1}>
                                        <Grid item>
                                            <div className={classes.discountContainer} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                                                <Avatar className={classes.discount}>{discount}</Avatar>
                                                {hover && userType === USER_TYPE.ADMIN && ismanual ? <div className={classes.discountOverlay} onClick={onEditDiscount}>Editar</div> : null}
                                            </div>
                                        </Grid>
                                        <Grid item><DisplayText variant='subtitle1'>Descuento</DisplayText></Grid>
                                        <ContraintLayout constrainst={constrainst.discount} auto>
                                            <Grid container alignItems='center'>
                                                <Grid item><Switch checked={ismanual} onChange={onInnerChange} /></Grid>
                                                <Grid item><DisplayText>Modo manual</DisplayText></Grid>
                                            </Grid>
                                        </ContraintLayout>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    
                        <ContraintLayout constrainst={constrainst.statusbar} md={12}>
                            <StatusActionBar patient={data} onUpdatePatient={onUpdatePatient}/>
                        </ContraintLayout>
                    </Grid>
                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default BusinessContainer