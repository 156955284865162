import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import ModalBase from '../../../../../components/Modals/ModalBase';
import DisplayText from '../../../../../components/Texts/DisplayText';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import { generalCatalogs } from '../../../../../variables/catalogs';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';



const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
    },
    buttonContainer:{
        marginTop:32
    }
}))

const AddRegisterModal = props => {

    const classes = useStyles()
    const {open, onClose, onAddItem} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)

    useEffect(() => {
        if(open) setForm(JSON.parse(JSON.stringify(formData)))
    }, [open])



    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        setValid(validationForm(temp))
        setForm(temp)
    }

    const validationForm = (data) => {
        let isValid = true;
        Object.keys(data).forEach((item) => {if(data[item].isRequired && !data[item].isValid) isValid = false})
        return isValid;
    }

    const onSubmit = () => {
        if(valid) onAddItem(form)
    }

    return(
        <ModalBase open={open} onClose={onClose}>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Añadir tarjeta de crédito</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LineInputText
                                data={form.name}
                                onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <LineInputSelect
                                data={form.credit_range_id}
                                onChange={onInputChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttonContainer}>
                            <Grid container justify='flex-end'> 
                                <Grid item>
                                    <RoundedButton label='Agregar' onClick={onSubmit}/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default AddRegisterModal

const formData = {
    name:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'name',
          type:'text',
          fullWidth: true,
          label:'Nombre',
          placeholder:'Ingrese un nombre para identificar la tarjeta...'
        },
        rules:{type:'distance'}
    },
    credit_range_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.credit_ranges ],
        config:{
          id:'credit_range_id',
          type:'select',
          fullWidth: true,
          label:'Rango de crédito',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
    },
}