import React, {useEffect, useState} from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import MenuToolbar from './components/MenuToolbar'
import { request_living_places_stats, request_income_stats,
    request_children_stats, request_dependant_stats, request_credit_card_stats,
    request_car_stats} from '../../requests'
import LivingPlacesStats from './components/LivingPlacesStats'
import {cleanCarsStats, cleanChildrenStats, cleanCreditCardsStats, cleanDependantsStats, cleanIncomeStats, cleanLivingPlacesStats,
    cleanLivingPlacesStatsExcel,cleanIncomeStatsExcel, cleanChildrenStatsExcel, cleanDependantsStatsExcel,
    cleanCreditCardsStatsExcel, cleanCarsStatsExcel} from './customFunction'
import IncomeStats from './components/IncomeStats'
import ChildrenStats from './components/ChildrenStats'
import DependantStats from './components/DependantStats'
import CreditCardsStats from './components/CreditCardsStats'
import CarsStats from './components/CarsStats'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import {s2ab} from '../../../../../shared/utility'

const useStyles = makeStyles(theme => ({
    root:{
    }
}))

const DetailedView = props => {

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState({id:'ALL', label:'Todo',param:''})
    const [option, setOption] = useState(1)
    const [livingPlaces, setLivingPlaces] = useState([])
    const [incomes, setIncomes] = useState([])
    const [children, setChildren] = useState([])
    const [dependants, setDependants] = useState([])
    const [creditCards, setCreditCards] = useState([])
    const [cars, setCars] = useState([])


    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            try {
                const filter = selected.param
                const living_place_stats = await request_living_places_stats(filter, option)
                const income_stats = await request_income_stats(filter, option)
                const children_stats = await request_children_stats(filter, option)
                const dependant_stats = await request_dependant_stats(filter, option)
                const credit_card_stats = await request_credit_card_stats(filter, option)
                const car_stats = await request_car_stats(filter, option)

                setLivingPlaces(living_place_stats)
                setIncomes(income_stats)
                setChildren(children_stats)
                setDependants(dependant_stats)
                setCreditCards(credit_card_stats)
                setCars(car_stats)

            } catch (error) {
                
            }
            setLoading(false)

        }
        if(JSON.parse(localStorage.getItem('data'))) initModule()
    }, [option, selected])

    const onChangeFilter = (data) => {
        setSelected(data)
        setOption(1)
    }

    const onDownloadInformation = async() => {
        console.log('Descargar')
        const _living_place_stats = cleanLivingPlacesStatsExcel(livingPlaces[0])
        const _income_stats = cleanIncomeStatsExcel(incomes[0])
        const _children_stats = cleanChildrenStatsExcel(children[0])
        const _dependant_stats = cleanDependantsStatsExcel(dependants[0])
        const _credit_card_stats = cleanCreditCardsStatsExcel(creditCards[0])
        const _car_stats = cleanCarsStatsExcel(cars[0])

        const sheets = [
            {name:'Tipo de vivienda', data:_living_place_stats},
            {name:'Ingresos', data:_income_stats},
            {name:'Hijos', data:_children_stats},
            {name:'Dependientes', data:_dependant_stats},
            {name:'Tarjetas de crédito', data:_credit_card_stats},
            {name:'Vehículos', data:_car_stats},
        ]

        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Foundation Medicine Analytics",
            Subject: "Detailed Data",
            Author: "Trionix Technologies",
            CreatedDate: new Date()
        };

        for(let i=0; i<sheets.length; i++){
            wb.SheetNames.push(sheets[i].name);
            const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
            wb.Sheets[sheets[i].name] = temp;
        }

        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'analytics.xlsx');
    }

    /////////////////////////////////////////////////////////////////////////////////////////////
    ///                 PARSE DATA TO TABLES
    //////////////////////////////////////////////////////////////////////////////////////////////
    const _living_place_stats = cleanLivingPlacesStats(livingPlaces[0])
    const _income_stats = cleanIncomeStats(incomes[0])
    const _children_stats = cleanChildrenStats(children[0])
    const _dependant_stats = cleanDependantsStats(dependants[0])
    const _credit_card_stats = cleanCreditCardsStats(creditCards[0])
    const _car_stats = cleanCarsStats(cars[0])

    let contentView = <Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>

    if(!loading){
        contentView = (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <LivingPlacesStats data={_living_place_stats} />
                </Grid>
                <Grid item xs={12}>
                    <IncomeStats data={_income_stats} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ChildrenStats data={_children_stats} />
                        </Grid>
                        <Grid item xs={12}>
                            <CreditCardsStats data={_credit_card_stats} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DependantStats data={_dependant_stats} />
                        </Grid>
                        <Grid item xs={12}>
                            <CarsStats data={_car_stats} />
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        )
    }

    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MenuToolbar selected={selected} option={option} onDownload={onDownloadInformation}
                    onChange={onChangeFilter} onChangeOption={(data) => setOption (data)}/>
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailedView