const styles = theme => ({
  popper:{
    zIndex:3000,
    borderRadius:32,
    marginTop:8
  },
  iconUserMenu:{
    color: theme.palette.primary.main,
  },
});

export default styles;
