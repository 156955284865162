import React from 'react'
import { Grid } from '@material-ui/core'
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import {constrainst_hospitalInfo as constrainst} from  '../constraints'
import { numberWithCommas } from '../../../../../shared/utility'

const DetailInfoContainer = props => {

    const {data, credit_cards, vehicles, properties, has_vehicles} = props

    let dependantCount = 0
    let childrenInSchool = 0 
    let vehiclesNumber = 0
    let ccNumber = credit_cards.length
    let incomeData = 0
    if(has_vehicles){
        vehiclesNumber=vehicles.length
    }
    
    let propertyItem = properties.find(el => el.status)

    if(data){
        const {dependant_wives, dependant_children, dependant_parents, dependant_inlaws, dependant_other,
            public_elementary_school_children, private_elementary_school_children,
            public_secondary_school_children, private_secondary_school_children,
            public_high_school_children, private_high_school_children,
            public_university_children, private_university_children,
        } = data
        dependantCount = 
        (dependant_wives ? parseInt(dependant_wives) : 0) + 
        (dependant_children ? parseInt(dependant_children) : 0) + 
        (dependant_parents ? parseInt(dependant_parents) : 0) + 
        (dependant_inlaws ? parseInt(dependant_inlaws) : 0) + 
        (dependant_other ? parseInt(dependant_other)  : 0)

        childrenInSchool = 
        (public_elementary_school_children ? parseInt(public_elementary_school_children) : 0) + 
        (private_elementary_school_children ? parseInt(private_elementary_school_children) : 0) +
        (public_secondary_school_children ? parseInt(public_secondary_school_children) : 0) + 
        (private_secondary_school_children ? parseInt(private_secondary_school_children) : 0) +
        (public_high_school_children ? parseInt(public_high_school_children) : 0) + 
        (private_high_school_children ? parseInt(private_high_school_children) : 0) +
        (public_university_children ? parseInt(public_university_children) : 0) + 
        (private_university_children ? parseInt(private_university_children) : 0) 
        incomeData = `$ ${numberWithCommas(data.income ? data.income : 0.00)}`
        console.log(dependantCount)

        console.log(childrenInSchool)

    }

    
   
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ContentText 
                        text='Ingresos y dependientes' 
                        medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Rango de ingresos' text={incomeData}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.dependantCount}>
                            <HorizontalDataWrapper 
                                label='Total de dependientes' text={dependantCount}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.childrensInSchool}>
                            <HorizontalDataWrapper 
                                label='Hijos en edad escolar' text={childrenInSchool}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.ccNumber}>
                            <HorizontalDataWrapper 
                                label='No. Tarjetas de crédito' text={ccNumber}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.propertyItem}>
                            <HorizontalDataWrapper 
                                label='Tipo de vivienda' text={propertyItem.label}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.vehiclesNumber}>
                            <HorizontalDataWrapper 
                                label='No. de vehículos' text={vehiclesNumber}/>
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailInfoContainer