import { nodata } from "../variables/config";
import  moment from  'moment'
import { ERROR_CODES } from "../variables/errorCodes";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const isUserAllowed = (userLevel, constraints) => {
  let response = true
  if(constraints && constraints.length){
    let userFind = constraints.find(el => parseInt(el) === parseInt(userLevel))
    response = userFind ? true : false
  }
  return response
}

export const onTruncateText = (input) => input.length > 16 ? `${input.substring(0, 16)}...` : input;

export const onFormatNumber = (number, digits, prefix) => {
    let temp = ''
    if(number !== null && number !== undefined){
      temp = number.toString().padStart(digits,'0')
      if(prefix) temp = `${prefix}${temp}`
    }
  
    return temp
}

export const isFormValid = (form) => {
  let response = true
  if(form){
      Object.keys(form).forEach(item => {
          if(form[item].isRequired && !form[item].isValid) response = false
      })
  }
  return response
} 

export const onGetErrorMessage = (error) => {
  let message = 'Hubo un error'
  if(!error) return ''
  if(error.response){
      if(error.response.data){
          if(error.response.data.error && error.response.data.error.code){
              const _error = ERROR_CODES[error.response.data.error.code]
              console.log(error.response.data.error.code)
              if(_error) message = _error
          }
          
      }
  }
  return message
}

export const numberWithCommas = (x)=> {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const isValidDocument = (filename) => {
  return filename && filename !== "0" && filename !== 0 && filename !== ""
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export const onGetIdFormat = (number, digits, preString) => {
    let result = nodata
    if(Number.isInteger(number)) result = onFormatNumber(number, digits)
    if(typeof preString === 'string') result = `${preString}${result}`
    return result
}

export const onGetDateFormat = (date, format) => {
  let result = nodata
  let current_date = moment(date)
  if(current_date.isValid()) result = current_date.format(format ? format : 'DD MMM YYYY')
  return result
}

export const onGetFullName = (firstName, lastName) => {
  return`${firstName ? firstName : ''} ${lastName ? lastName : ''}`
}

export const onValidateForm = (data) => {
  let isValid = true;
  Object.keys(data).forEach((item) => {
    if(data[item].isRequired && !data[item].isValid){
      isValid = false;
    }
  })
  return isValid;
}

export const onGetInvalidForm = (data) => {
  let result = {...data}
  Object.keys(data).forEach((item) => {
    if(data[item].isRequired && !data[item].isValid){
      result[item].isVisited = true
      result[item].isValid = false
    }
  })
  return result;
}

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
}

export const getUserToken = () => {
  let res = null
  let localData = localStorage.getItem('data')
  if(localData && typeof localData === 'string'){
    localData = JSON.parse(localData)
    res = localData
  }
  return res
}

export const getUserType = () => {
  let res = null
  let localData = localStorage.getItem('data')
  if(localData && typeof localData === 'string'){
    localData = JSON.parse(localData)
    res = localData.user_type_id
  }
  return res
}

export const getComponentLogInfo = (data) => {
    let infoButtonData = []
    if(data){
      const _created_at = data.created_at ? moment(`${data.created_at}Z`).tz(moment.tz.guess()).format('DD MMM YY, HH:mm') : nodata
      const _updated_at = data.created_at ? moment(`${data.updated_at}Z`).tz(moment.tz.guess()).format('DD MMM YY, HH:mm') : nodata


      infoButtonData = [
        {
          title:'Detalles de creación',
          content:[
            {label:'Nombre: ', text: data.creator ? data.creator : nodata},
            {label:'Fecha: ', text: _created_at},
          ]
        },
        {
          title:'Detalles de actualización',
          content:[
            {label:'Nombre: ', text: data.updater ? data.updater : nodata},
            {label:'Fecha: ', text: _updated_at},
          ]
        },
      ]
    }
    return infoButtonData
}

export const getPatientProductLogInfo = (data) => {
  let infoButtonData = []
  if(data){
    infoButtonData = [
      {
        title:'Producto aprobado',
        content:[
          {label:'Nombre: ', text: data.approver ? data.approver : nodata},
          {label:'Fecha: ', text: data.approved_date ?  moment(data.approved_date).format("DD/MM/YYYY") : nodata},
        ]
      },
      {
        title:'Cambio manual',
        content:[
          {label:'Nombre: ', text: data.manual_changer ? data.manual_changer : nodata},
        ]
      }
    ]
  }
  return infoButtonData
}

export const getUseLogInfo = (data) => {
  let infoButtonData = []
  if(data){
    //r_gift = data.gift ? true : false
    infoButtonData = [
      {
        title:'Detalles de creación',
        content:[
          {label:'Nombre: ', text: data.creator ? data.creator : nodata},
          {label:'Fecha: ', text: data.created_at ?  moment(data.created_at).format("DD/MM/YYYY") : nodata},
        ]
      },
      {
        title:'Detalles de actualización',
        content:[
          {label:'Nombre: ', text: data.updater ? data.updater : nodata},
          {label:'Fecha: ', text: data.updated_at ?  moment(data.updated_at).format("DD/MM/YYYY") : nodata},
        ]
      },
    ]
  }
  return infoButtonData
}

export const onGetHospitalCatalogs = (data) => {
    let result = []
    if(data && data.length){
        result = data.map(item => {
            return {
                id: item.id_hospital,
                value: item.id_hospital,
                label: item.name
            }
        })
    }
    return result
}


export const getInsuranceEvaluation = (balance, balance_value, policy_amount_id, policy_amounts) => {
  let policy_value_id = null
  let findPolicyAmount
  if(balance){
    findPolicyAmount = policy_amounts.find(el => balance_value >= el.min_value && balance_value <= el.max_value)
    if(findPolicyAmount) policy_value_id = findPolicyAmount.policy_value_id
  }else{
    if(policy_amount_id !== null && policy_amount_id !== undefined && policy_amount_id !== ''){
      findPolicyAmount = policy_amounts.find(el => el.id_policy_amount === policy_amount_id)
      if(findPolicyAmount) policy_value_id = findPolicyAmount.policy_value_id
    }
  }
  return policy_value_id
}

export const isProductValid = (product) => {
  if(product) return  product.product_id !== null && product.product_id !== undefined && 
          product.diagnosis_id !== null && product.diagnosis_id !== undefined && 
          product.expected_uses !== null && product.expected_uses !== undefined 
  return false
}

export const isBenefitPosible = (hospital_id, policy_value_id, nse_id) => {
  return  hospital_id !== null && hospital_id !== undefined && 
          policy_value_id !== null && policy_value_id !== undefined && 
          nse_id !== null && nse_id !== undefined 
}

export const isTreatmentApplicable = (patientproduct, benefitResult) => {
  let result = false
  if(patientproduct && benefitResult){
    const {benefit, uses} = benefitResult
    const {expected_uses} = patientproduct
    if(benefit && uses && expected_uses){
      result = parseInt(expected_uses) >= (benefit + uses)
    }
  }
  return result
}

export const null2undefined = (data) => {
  let res = {...data}
  Object.keys(data).forEach(item => {
    res[item] = res[item] === null ? undefined : res[item]
  })
  return res
}

export const onGetProgress = (patient, product, patient_insurance, documents) => {
  console.log('Evaluar!!!!')
  console.log(patient)
  console.log(product)
  console.log(patient_insurance)
  let missingKeys = []
  const genericKeys = [
    'doctor_id','nse_id','main_hospital_id','zip'
  ]
  const insuranceKeys = ['insurance_carrier_id', 'policy_amount_id', 'policy_value_id']
  const productKey = ['product_id', 'diagnosis_id', 'hospital_id', 'expected_uses']
  const docsKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report']
  const docsInsuranceKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report', 'insurance_policy']
  genericKeys.forEach(item => {
    if(patient[item] === null || patient[item] === undefined) missingKeys.push(item)
  })

  

  productKey.forEach(item => {
    if(product){
      if(product[item] === null || product[item] === undefined){
        missingKeys.push(item)
      }
    }else{
      console.log('No existe producto')
      console.log(item)
      missingKeys.push(item)
    }
  })
  if(patient_insurance){
    insuranceKeys.forEach(item => {
      if(patient_insurance[item] === null || patient_insurance[item] === undefined) missingKeys.push(item)
    })
    docsInsuranceKey.forEach(item => {
      if(documents[item] === null || documents[item] === undefined) missingKeys.push(item)
    })
  }else{
    if(patient.payment_type_id !== 2){
      missingKeys = [...missingKeys, ...insuranceKeys]
    }
    docsKey.forEach(item => {
      if(documents[item] === null || documents[item] === undefined) missingKeys.push(item)
    })
  }

  let result = 0;
  if(patient_insurance){
    let totalItems = genericKeys.length + insuranceKeys.length + productKey.length + docsInsuranceKey.length
    result = ((totalItems - missingKeys.length) / totalItems)*100
  }else{
    let totalItems = genericKeys.length + productKey.length + docsKey.length
    result = ((totalItems - missingKeys.length) / totalItems)*100
  }

  console.log(missingKeys)
  console.log(result)
  return parseInt(result)

}

export const onValidateProfile = (patient, product, patient_insurance, medical_report) => {
  console.log('Evaluar!!!!')
  console.log(patient)
  console.log(product)
  console.log(patient_insurance)
  let missingKeys = []
  const genericKeys = [
    'doctor_id','nse_id','main_hospital_id','zip'
  ]
  const insuranceKeys = ['insurance_carrier_id', 'policy_amount_id', 'policy_value_id', 'validated_insurance_policy']
  const productKey = ['product_id', 'diagnosis_id', 'hospital_id', 'expected_uses']
  const docsKey = ['validated_privacy_act', 'validated_identification', 'validated_address_proof']

  genericKeys.forEach(item => {
    if(patient[item] === null || patient[item] === undefined) missingKeys.push(item)
  })
  productKey.forEach(item => {
    if(product){
      if(product[item] === null || product[item] === undefined){
        missingKeys.push(item)
      }
    }else{
      console.log('No existe producto')
      console.log(item)
      missingKeys.push(item)
    }
  })
  if(patient_insurance){
    insuranceKeys.forEach(item => {
      if(patient_insurance[item] === null || patient_insurance[item] === undefined) missingKeys.push(item)
      if(item === 'validated_insurance_policy' ){
        
        if(patient_insurance.validated_insurance_policy === 0) missingKeys.push(item)
      }
    })
  }else{
    if(patient.payment_type_id !== 2){
      missingKeys = [...missingKeys, ...insuranceKeys]
    }

    
  }
  let benefitKey = 'validated_benefit'
  if(patient.patient_products && patient.patient_products.length){
    let isValidBenefit = true
    patient.patient_products.forEach(item => {
      if(item.product_status_id === 1 && !item.benefit_id){
        isValidBenefit = false
      }
    })
    if(!isValidBenefit) missingKeys.push(benefitKey)

  }else{
    missingKeys.push(benefitKey)
  }

  docsKey.forEach(item => {
    if(patient[item] === null || patient[item] === undefined || patient[item] === '' || patient[item] === '0' || patient[item] === 0) missingKeys.push(item)
  })

  let aux = 'validated_medical_report'
  if(medical_report){
    if(medical_report[aux] === null || medical_report[aux] === undefined || medical_report[aux] === '' || medical_report[aux] === '0' || medical_report[aux] === 0) missingKeys.push(aux)
  }else{
    missingKeys.push(aux)
  }


  console.log(missingKeys)
  let errorMessage = missingKeys.map(item => keyCatalog[item])
  errorMessage = errorMessage.join(',')
  console.log(errorMessage)
  return {
    response_status: missingKeys.length === 0,
    error_message:`Faltan datos por llenar: ${errorMessage}`
  }


}

//const docsKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report']


const keyCatalog = {
  doctor_id:'Doctor',
  nse_id:'Calculo del NSE',
  main_hospital_id:'Hospital o clínica de consulta',
  zip:'Código postal',
  insurance_carrier_id:'Aseguradora',
  policy_amount_id:'Póliza',
  policy_value_id:'Cálculo del valor de póliza',
  product_id:'Producto',
  diagnosis_id:'Diagnóstico',
  hospital_id:'Hospital de tratamiento',
  expected_uses:'Ciclos',
  validated_privacy_act:'Aviso de privacidad',
  validated_identification:'Documento de identifación',
  validated_address_proof:'Comprobante de domicilio',
  medical_report:'Reporte médico',
  validated_insurance_policy:'Póliza de seguros',
  validated_medical_report:'Reporte médico',
  validated_benefit:'Calcular beneficio'

}

export const getPatientProduct = (patientproducts, type) => {
  let result = null
  if(patientproducts && patientproducts.length){
    if(type === 'primary') result = patientproducts.find(el => el.product_status_id === 1 && el.secondary === 0)
    if(type === 'secondary') result = patientproducts.find(el => el.product_status_id === 1  && el.secondary === 1)
  }
  return result
}

export const getHistoryFromProducts = (patientproducts) => {
  let result = []
  if(patientproducts && patientproducts.length){
    result = patientproducts.filter(item => item.product_status_id !== 1)
  }
  return result
}