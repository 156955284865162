import { blueGrey, orange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: { main: '#f4511e' }, // Purple and green play nicely together. #091635
        secondary: { main: '#f4511e' }, // This is just green.A700 as hex. #00acc1
        custom: {main: '#640178'} //2196F3
    },
    typography: {
        useNextVariants: true,
        fontFamily:[
        'Poppins',
        ].join(','),
    },
    overrides:{
        MuiTypography:{
            h4:{
                color: blueGrey[700],
            },
            h5:{
                color: blueGrey[700],
            },
            h6:{
                color: blueGrey[700],
            },
            subtitle1:{
                color: blueGrey[900],
                fontWeight:500
            },
            subtitle2:{
                color: blueGrey[900],
            },
            body1:{
                color: blueGrey[700],
            },
            body2:{
                color: blueGrey[700],
            },
            caption:{
                color: blueGrey[700],
            },
        }
    }

});