import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from "../../../../variables/config"

export const constrainst_patientproduct = {
    
}

export const constrainst_benefit = {
    
}



export const constrainst_header = {
    header:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ]
    },
    dismissed_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
        ]
    },
    preregister:{
    },
    approve_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
        ]
    },
    disapprove_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO
        ]
    },
    reactive_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO
        ]
    }

}

export const constrainst_basicInfo = {
    id_patient:{
        byuser:[
            USER_TYPE.HOSPITAL,
        ]
    },
    external_id:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.PRA
        ]
    },
    folio:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.PRA
        ]
    },
    name:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    },
    email:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    },
    phone:{
        byuser:[
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ]
    }
}

export const constrainst_hospitalInfo = {  
}

export const constrainst_insurance = {
}

export const constrainst_statuses = {
    inactive_reason:{
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ]
    }
}

export const constrainst_actionbuttons = {
    doctor_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    edit_button:{ // 4
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }
}