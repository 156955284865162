import React, {useEffect, useState} from 'react'
import {contentData} from './content'
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {request_approval_contacts} from './requests'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import AddEmailModal from './modals/AddEmailModal/AddEmailModal'
import EditEmailModal from './modals/EditEmailModal/EditEmailModal'
import DeleteEmailModal from './modals/DeleteEmailModal/DeleteEmailModal'


const Emails = props => {
  const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [contacts, setContacts] = useState([])
    const [totalContacts, setTotalContacts] = useState(10)
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedContact, setSelectedContact] = useState(false)
    const [filter, setFilter] = useState({
      search_by:'name',
      query:''
    })
    const [tableFilter, setTableFilter] = useState({
      limit:10,
      offset:0,
      order:0,
      order_by:'id_approval_contact',
    })

    useEffect(() => {
      const onRefreshData = async() => {
        const params = onGetParamsFromState()
        const response = await request_approval_contacts(params)
        setContacts(response.approval_contacts)
        setTotalContacts(response.count)
        onUpdateURLArgs()
      }
      onRefreshData()
      
    }, [filter, tableFilter])


    useEffect(() => {
      const initModule = async() => {
        setLoading(true)
        let params = ''

        if(location.search){
          //Get params from url
          params = onUpdateStateFromURL()
        }else{
          // Get params from initial state
          params = onGetParamsFromState()
          onUpdateURLArgs()
        }
        try {
          const response = await request_approval_contacts(params)
          setContacts(response.approval_contacts)
          setTotalContacts(response.count)
        } catch (error) {
          
        }
        setLoading(false)

        
      }
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data) initModule();
    },[])

    const onRequetsContacts = async() => {
      try {
        let params = onGetParamsFromState()
        const response = await request_approval_contacts(params)
        setContacts(response.approval_contacts)
        setTotalContacts(response.count)
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
      } catch (error) {
        console.log('Hubo un error')
      }
    }

    const onChangeFilter = (tag, value) => { // Change generic filter
      let newFilter = onUpdateFilter(tag, value)
      setFilter(newFilter)
    }

    const onUpdateStateFromURL = () => {
      let params = ''
      const args = queryString.parse(location.search)
      const {limit, offset, order, order_by, search_by, query} = args
      let newFilter = {...filter}
      let newTableFilter = {...tableFilter}
      let filterArgs = []

      if(limit !== undefined) newTableFilter.limit = parseInt(limit)
      if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
      if(order !== undefined) newTableFilter.order = parseInt(order)
      if(order_by !== undefined) newTableFilter.order_by = order_by

      newFilter.search_by = search_by
      if(query && query !==''){
        newFilter.query = query
        filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})
      }

      params = {...newTableFilter}
      params = `?${queryString.stringify(params)}`
      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `${params}&filter=${filterArgs}`
      }

      setFilter(newFilter)
      setTableFilter(newTableFilter)

      return params

    }

    const onGetParamsFromState = () => {
      let params2send = {...tableFilter}
      params2send.offset = params2send.offset*params2send.limit
      const { search_by, query} = filter

      

      let filterArgs = []
      
      if(query && query !=='') filterArgs.push({"field":search_by,"operator":"LIKE","value":`%${query}%`})

      if(filterArgs.length){
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        return `?${queryString.stringify(params2send)}&filter=${filterArgs}`
      }else{
        return `?${queryString.stringify(params2send)}`
      }
      
    }

    const onUpdateURLArgs = () => {
      let params = {...tableFilter}
      const {search_by, query} = filter
      params.offset = params.offset*params.limit
      params = {...params,
        search_by:search_by,
        query:query
      }
      const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
      window.history.replaceState(null, null, urlwithargs);
    }

    

    const onUpdateFilter = (id,value) => {
      let temp = {...filter}
      temp[id] = value
      return temp
    }

    const onEditContact = (data) => {
      setSelectedContact(data)
      setEditModal(true)
    }
    
    const onDeleteContact = (data) => {
      setSelectedContact(data)
      setDeleteModal(true)
    }

    const content = contentData['spanish']
    //console.log(contacts)
    
    return(
      <BasicView content={{
          title:'Correos de aprobación',
          navigation:[
            {name:'Correos de aprobación',path:'/emails'}
          ],
      }} isloading={loading}>
        <AddEmailModal open={addModal} onClose={() => setAddModal(false)}
        onRequetsContacts={onRequetsContacts}/>
        <EditEmailModal initData={selectedContact} open={editModal} onClose={() => setEditModal(false)}
        onRequetsContacts={onRequetsContacts}/>
        <DeleteEmailModal initData={selectedContact} open={deleteModal} onClose={() => setDeleteModal(false)}
        onRequetsContacts={onRequetsContacts}/>
        <SimpleTable 
          data={contacts} 
          filter={filter}
          tableFilter={tableFilter}
          total={totalContacts}
          header={content.table_header} 
          onAddRegister={() => setAddModal(true)}
          onChangeFilter={onChangeFilter}
          onUpdateTableFilter={(data) => setTableFilter(data)}
          onEditItem={onEditContact}
          onRemoveItem={onDeleteContact}/>
      </BasicView>
    )
}

export default Emails