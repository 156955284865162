import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import LineInputDate from '../../../../../components/Inputs/LineInputDate'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText'
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import ModalBase from '../../../../../components/Modals/ModalBase'
import DisplayText from '../../../../../components/Texts/DisplayText'
import {constrainst_basic_information as constrainst} from  '../../constraints'
import { onValidateForm } from '../../../../../shared/utility'
import {formData } from './data'
import SwitchButton from '../../../../../components/Buttons/SwitchButton'
import moment from 'moment'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton'
import {request_update_patient} from '../../requests'

const EditIncomeModal = props => {

    const {initData, patientID, open, onClose, onUpdatePatient} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [hasInsurance, setHasInsurace] = useState(false)
    const [firstTime, setFirstTime] = useState(false)

    useEffect(() => {
        if(!initData) return
        let temp = {...form}
        Object.keys(form).forEach( item =>{
            let newValue = initData[item] 
            if(newValue !== null && newValue !== undefined){
                if(item === 'interview_date'){
                    if(moment(newValue).isValid()){
                        newValue = moment(newValue).format('YYYY-MM-DD')
                    }
                }
                temp[item].value = newValue
                temp[item].isValid = true
                temp[item].isVisited = true
            }
        })
        if(initData.first_time) setFirstTime(true)
        if(initData.has_insurance) setHasInsurace(true)
        setValid(onValidateForm(temp))
        setForm(temp)

    }, [initData, open])


    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        setValid(onValidateForm(temp))
        setForm(temp)
    }

    const onEdit = async() => {
        if(!valid){
            setError('Llena los campos faltantes')
            return
        }
        
        let data2send = {}
        Object.keys(form).forEach(item => {
            if((initData[item] !== form[item].value) && form[item].isValid){
                data2send = {...data2send, [item]: form[item].value}
            }
        })
        setLoading(true)
        try {
            const newPatient = await request_update_patient(patientID, data2send)
            setLoading(false)
            onUpdatePatient(newPatient)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }


    return(
        <ModalBase open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Ingresos y dependientes</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <LineInputText 
                                data={form.income}
                                onChange={onInputChange}/>
                            </Grid>
                            {/* ------------------ Dependants ------------------------- */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle1'>Dependientes económicos</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <LineInputText 
                                                data={form.dependant_wives}
                                                onChange={onInputChange}/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LineInputText 
                                                data={form.dependant_children}
                                                onChange={onInputChange}/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LineInputText 
                                                data={form.dependant_parents}
                                                onChange={onInputChange}/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LineInputText 
                                                data={form.dependant_inlaws}
                                                onChange={onInputChange}/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LineInputText 
                                                data={form.dependant_other}
                                                onChange={onInputChange}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* ------------------ School ------------------------- */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle1'>Hijos en edad escolar</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            {/* ------------------ ELEMENTRAY SCHOOL ------------------------- */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={12} md={4}>
                                                    <DisplayText>Primaria</DisplayText>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.public_elementary_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.private_elementary_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* ------------------ MIDDLE SCHOOL ------------------------- */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={12} md={4}>
                                                    <DisplayText>Secundaria</DisplayText>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.public_secondary_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.private_secondary_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* ------------------ HIGH SCHOOL ------------------------- */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={12} md={4}>
                                                    <DisplayText>Preparatoria</DisplayText>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.public_high_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.private_high_school_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* ------------------ UNIVERSITY SCHOOL ------------------------- */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={12} md={4}>
                                                    <DisplayText>Universidad</DisplayText>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.public_university_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <LineInputText 
                                                    data={form.private_university_children}
                                                    onChange={onInputChange}/>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton error={error} loading={loading} onSubmit={onEdit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default EditIncomeModal