import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import ModalBase from '../../../../../components/Modals/ModalBase';
import DisplayText from '../../../../../components/Texts/DisplayText';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import { generalCatalogs } from '../../../../../variables/catalogs';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import { onValidateForm } from '../../../../../shared/utility';
import {request_add_vehicle} from '../../requests'
import SubmitModalButton from '../../../../../components/Buttons/SubmitModalButton';

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
    },
    buttonContainer:{
        marginTop:32
    }
}))

const AddCarModal = props => {

    const classes = useStyles()
    const {open, onClose, onUpdateCars, patientID} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(open) setForm(JSON.parse(JSON.stringify(formData)))
    }, [open])



    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {...form};
        temp[id] = {...data};
        setValid(onValidateForm(temp))
        setForm(temp)
    }

    const validationForm = (data) => {
        let isValid = true;
        Object.keys(data).forEach((item) => {if(data[item].isRequired && !data[item].isValid) isValid = false})
        return isValid;
    }

    const onSubmit = async() => {
        if(!valid){
            setError('Llena los campos faltantes')
            return
        }
        
        let data2send = {patient_id: patientID}
        Object.keys(form).forEach(item => {
            if((form[item].isValid)) data2send = {...data2send, [item]: form[item].value}
        })
        setLoading(true)
        try {
            const cars = await request_add_vehicle(patientID, data2send)
            setLoading(false)
            onUpdateCars(cars)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return(
        <ModalBase open={open} onClose={onClose}>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Añadir vehículo</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LineInputText
                                data={form.name}
                                onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <LineInputSelect
                                data={form.car_type_id}
                                onChange={onInputChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <LineInputSelect
                                data={form.car_range_id}
                                onChange={onInputChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitModalButton label='Agregar' error={error} loading={loading} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    )
}

export default AddCarModal

const formData = {
    name:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'name',
          type:'text',
          fullWidth: true,
          label:'Nombre',
          placeholder:'Ingrese un nombre para identificar el vehículo...'
        },
        rules:{type:'distance'}
    },
    car_type_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.car_types ],
        config:{
          id:'car_type_id',
          type:'select',
          fullWidth: true,
          label:'Tipo',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
    },
    car_range_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...generalCatalogs.car_ranges ],
        config:{
          id:'car_range_id',
          type:'select',
          fullWidth: true,
          label:'Rango',
          placeholder:'Seleccione una opción...'
        },
        rules:{
          type:'select',
        }
    },
}