import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import { Switch, Route, Redirect } from "react-router-dom"
import SideBAr from '../../components/SideBar/SideBar.jsx'
import TopBar from '../../components/TopBar/TopBar.jsx'
import routes from '../../routes/dashboard'
import cx from  'classnames'


import {request_reauth, request_get_user_info, request_get_catalogs} from './request'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import moment from 'moment'


class Dashboard extends Component{
 
  state={
    isDrawerOpen: true,
    isreauth:false,
    section: "HOME",
    user: null,
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data){
      this.initModule()
    }
    
  }

  initModule = async() => {
    try {
      this.setState({isloading:true})
      let response
      response = await request_get_user_info()
      await request_get_catalogs()
      this.setState({user:{...response}})
      this.props.onUpdateUser(response)
      this.setState({isloading:false})
    } catch (error) {
      this.setState({isloading:false})
    }
  }

  mouseMoveEvent = async(e) => {
    const {isreauth} = this.state;
    const {history} = this.props
    try {
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data ){
        if(!isreauth){
          const {history} = this.props;
          const serverTime = data.exp
          const localTime = moment().unix()
          if(localTime < serverTime){ // valid token
            if((localTime + 15*60) > serverTime){ // expiration date soon 
              this.setState({isreauth:true})
              await request_reauth()
              this.setState({isreauth:false})
            }
          }else{
            localStorage.setItem('preurl',history.location.pathname);
            console.log('Token expirado')
            localStorage.clear();
            history.push("/signin");
          }
        }
      }else{
        localStorage.setItem('preurl',history.location.pathname);
        localStorage.clear();
        history.push("/signin");
      }
    } catch (error) {
    }
  }

  onChangeView = (url) => {
    const {history, width} = this.props;

    //console.log(url)
    if(url !== '/patients'){
      localStorage.removeItem('patients_config')
    }

    if(width === 'xs' || width === 'sm'){
      this.isDrawerCloset()
    }

    history.push(url)
    
  }

  isDrawerCloset = () => {
    const temp = this.state.isDrawerOpen;
    this.setState({isDrawerOpen: !temp})
  } 

  isDrawerOpen = () => {
    const temp = this.state.isDrawerOpen
    this.setState({isDrawerOpen:!temp})
  }
  handlerExit = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }
  handlerHome = () => {
    this.props.history.push("/");
    this.setState({section: "HOME"})
  }

  onChangeParentUser = (data) => {
    this.setState({user:{...data}})
  }

  render(){

    const { classes } = this.props;
    const {isDrawerOpen, section} = this.state;


    return(

      <div className={classes.root} onMouseMove={this.mouseMoveEvent}>
      <TopBar 
        open={isDrawerOpen} 
        isdraweropen={this.isDrawerOpen} 
        onClose={this.isDrawerCloset} 
        closet={this.handlerExit} 
        section={section}
      />
      <SideBAr 
        routes={routes.sections}
        onChange={this.onChangeView}
        open={isDrawerOpen} 
        onClose={this.isDrawerCloset}
      />
      <main ref={(ref)=> this.mainRef = ref} className={cx({
        [classes.content]:true,
        [classes.contentOpen]:isDrawerOpen
      })}>
        <Switch>
          {routes.sections.map((route,idx) => {
            if(route.redirect)
              return <Redirect to={route.to} key={idx.toString()}/>
            return <Route 
              path={route.path} 
              exact={route.exact}
              component={route.component}
              key={idx.toString()}
            />
          })
          }
        </Switch>
      </main>
    </div>
    );
  }
};

const mapDispatchToProps = dispatch => {
  return{
    onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })

  }
}

export default connect(null, mapDispatchToProps) (withStyles(styles)(Dashboard))