import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import cx from 'classnames'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { generalCatalogs } from '../../../../../../variables/catalogs'
import FilterButton from './FilterButton'
import CircularButton from '../../../../../../components/Buttons/CircularButton'

const menu = [
    {id:'ALL', label:'Todo',param:''},
    {id:'STATE', label:'Estado',param:'state_id'},
    {id:'TEST', label:'Estudio',param:'test_type_id'},
    {id:'GENDER', label:'Género',param:'gender_id'},
]

const options = {
    ALL:[],
    STATE:[...generalCatalogs.patient_states],
    TEST:[...generalCatalogs.test_types],
    GENDER:[...generalCatalogs.user_genders]
}

const useStyles = makeStyles(theme => ({
    button:{
        border:`1px solid transparent`,
        color:blueGrey[900],
        padding:'8px 40px',
        borderRadius:32,
    },
    selectedButton:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        color:theme.palette.primary.main,
        fontWeight:500,
        '&:hover':{
            background:'white',
        }
    }
}))

const MenuToolbar = props => {

    const {selected, onChange, option, onChangeOption, onDownload} = props

    const classes = useStyles()
    return(
        <div>
            <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle2' style={{color:blueGrey[500]}}>Buscar por</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}> 
                                {menu.map((item,key)=>{
                                    return(
                                        <Grid item key={key.toString()}>
                                            <Button className={cx({
                                                [classes.button]:true,
                                                [classes.selectedButton]:selected.id===item.id,
                                            })} onClick={() => onChange(item)} >{item.label}</Button>
                                        </Grid> 
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FilterButton label='Seccione una opción' selected={option} options={options[selected.id]} onChange={onChangeOption}/>
                </Grid>
                <Grid item>
                    <CircularButton icon='cloud_download' onClick={onDownload}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default MenuToolbar